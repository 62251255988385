import { Http } from '@samknows/utils';
import AuthService from '@/common/services/Auth';
import SkConfig from '@/common/config';
import { PromiseResponse, AuthTokenPayload } from '@/common/global.interfaces';

import { processPreset } from './utils';
import { Preset, PresetData } from './types';

export const getPresets = async (
  includeDeleted = false
): Promise<PromiseResponse<Preset[]>> => {
  const { organisation_owner_id, owner_id } =
    AuthService.getTokenPayload() as AuthTokenPayload;

  const organisationOwnerIdString = organisation_owner_id
    ? `,${organisation_owner_id}`
    : '';

  const ownerIds = `${owner_id}${organisationOwnerIdString}`;
  const url = `${SkConfig.api.analytics}/presets?ownerIds=${ownerIds}&includeDeleted=${includeDeleted}`;

  const resJson = await Http.request<PromiseResponse<Preset[]>>(url);

  // Transform the response presets in to shape the frontend expects
  const processedPresets = resJson.data ? resJson.data.map(processPreset) : [];

  return {
    ...resJson,
    data: processedPresets
  } as PromiseResponse<Preset[]>;
};

export const getPreset = async (
  presetId: string
): Promise<PromiseResponse<Preset>> => {
  const resJson = await Http.request<PromiseResponse<Preset>>(
    `${SkConfig.api.analytics}/presets/${presetId}`
  );
  const processedPreset = processPreset(resJson.data);
  return {
    ...resJson,
    data: processedPreset
  };
};

export const savePreset = async (
  presetName: string,
  isPresetPublic: boolean,
  presetData: PresetData,
  presetTags: string[] = []
): Promise<{ location: string }> => {
  const payload: AuthTokenPayload = AuthService.getTokenPayload();
  const owner = isPresetPublic
    ? payload.organisation_owner_id
    : payload.owner_id;

  const res = await Http.request<Response>(
    `${SkConfig.api.analytics}/presets`,
    {
      method: 'POST',
      body: JSON.stringify({
        name: presetName,
        owner,
        data: presetData,
        created_by: payload.user_id,
        tags: presetTags
      }),
      getFullResponse: true
    }
  );

  return { location: res.headers.get('Location') };
};

export const updatePreset = async (
  presetId: number,
  preset: Preset
): Promise<PromiseResponse<Preset>> => {
  return Http.request(`${SkConfig.api.analytics}/presets/${presetId}`, {
    method: 'PUT',
    body: JSON.stringify(preset)
  });
};
