import * as measurementUtils from '@/common/helpers/measurements-utilities';

import { getPanelMetadata } from '@/common/services/metaData';
import { getAvailableUnitMetrics } from '@/features/unit-tests-view/services/UnitAnalytics';

import AuthService from '../../services/Auth';
import { GRANT_SECTION } from '@/features/account/constants';
import Vue from 'vue';
import { getPresets } from '@/common/services/presets';
import { prefStorage } from '@samknows/utils';
import splitSort from '../../helpers/split-sort';
import viewTypes from '@/common/helpers/view-types';

export const namespaced = true;

export const state = {
  formData: [],
  presets: [],
  metadata: {},
  showZeroAxis: prefStorage.get('show-zero-axis'),
  showZeroWhite: prefStorage.get('show-zero-white'),
  hideInsignificant: prefStorage.get('hide-insignificant', true),
  chartTypesAvailable: [],
  helpId: undefined,
  tooltipsDisabled: false,
  chartStatus: 'initial',
  isFormDataChangedAfterChartCreation: false,
  data: [],
  failedMetricData: [],
  consolidatedData: [],
  metricsRequestedCount: 0
};

/* eslint-disable no-shadow */
export const mutations = {
  setMetricsRequestedCount(state, n) {
    state.metricsRequestedCount = n;
  },
  setData(state, data) {
    state.data = data;
  },
  setSuccessfulMetric(state, datum) {
    const newData = [...state.data, datum];
    const sortedNewData = newData.sort((a, b) => a.order - b.order);

    state.data = sortedNewData;
    state.consolidatedData = measurementUtils.consolidateResponses(
      state.data,
      state.formData
    );
  },
  setFailedMetric(state, failedMetric) {
    state.failedMetricData = [...state.failedMetricData, failedMetric];
  },
  setChartStatus(state, pageStatus) {
    if (pageStatus === 'initial') {
      state.chartStatus = 'initial';
    }
    if (
      pageStatus === 'loading' ||
      pageStatus === 'loading-slow' ||
      pageStatus === 'reloading'
    ) {
      state.chartStatus = 'loading';
    }
    if (
      !(
        pageStatus === 'initial' ||
        pageStatus === 'loading' ||
        pageStatus === 'loading-slow'
      )
    ) {
      state.chartStatus = 'loaded';
      state.isFormDataChangedAfterChartCreation = false;
    }
  },
  setFormDataChangeStatus(state) {
    state.isFormDataChangedAfterChartCreation = true;
  },
  setFormData(state, formDataArray) {
    if (!Array.isArray(formDataArray)) {
      formDataArray = [formDataArray];
    }

    state.formData = formDataArray.map((formData) =>
      Object.assign({}, formData)
    );
  },
  setPresets(state, presets) {
    state.presets = presets;
  },
  editPreset(state, payload) {
    const index = state.presets.findIndex((item) => item.id === payload.id);
    if (index !== -1) {
      Vue.set(state.presets, index, payload);
    }
  },
  setMetadata(state, metadata) {
    state.metadata = metadata;
  },
  setShowZeroAxis(state, showZeroAxis) {
    state.showZeroAxis = showZeroAxis;

    prefStorage.set('show-zero-axis', showZeroAxis, true);
  },
  setHideInsignificant(state, hideInsignificant) {
    state.hideInsignificant = hideInsignificant;

    prefStorage.set('hide-insignificant', hideInsignificant, true);
  },
  setShowZeroWhite(state, showZeroWhite) {
    state.showZeroWhite = showZeroWhite;

    prefStorage.set('show-zero-white', showZeroWhite, true);
  },
  setChartTypes(state, chartTypes) {
    state.chartTypesAvailable = chartTypes;
  },
  setHelpId(state, helpId) {
    state.helpId = helpId;
  },
  setTooltipsDisabled(state, tooltipsDisabled) {
    state.tooltipsDisabled = tooltipsDisabled;
  },
  resetData(state) {
    state.data = [];
    state.consolidatedData = [];
    state.failedMetricData = [];
    state.metricsRequestedCount = 0;
  }
};

export const actions = {
  updatePresets({ commit, state }, force = false) {
    if (!force && state.presets.length) {
      return Promise.resolve();
    }

    return getPresets(true).then((data) => {
      if (!prefStorage.get('accessibles')) {
        return;
      }
      const panels = prefStorage
        .get('accessibles')
        .apollo.map(({ pid }) => pid);
      const hasMultiplePermissions = AuthService.hasPermissions(
        GRANT_SECTION.TEST_MEASUREMENTS,
        'advanced_analytics'
      );

      const presets = data.data
        ? data.data.filter((preset) => {
            if (preset.data.length > 1 && !hasMultiplePermissions) {
              return false;
            }

            return preset.data.every(({ panel }) => panels.includes(panel));
          })
        : [];

      commit('setPresets', presets);
    });
  },
  getUnitMetadata({ commit }, unitId) {
    return getAvailableUnitMetrics(unitId).then(({ data }) => {
      // @todo: get this from the backend
      data.metrics.forEach((metric) => {
        if (typeof metric.can_split === 'undefined') {
          metric.can_split = metric.canSplit || true;
        }
      });
      commit('setMetadata', data);
    });
  },
  updateMetadata({ commit, state, dispatch }, panelId) {
    if (viewTypes.isConsumerView) {
      return dispatch('getUnitMetadata', viewTypes.activeView.data.unitId);
    }

    if (!panelId) {
      panelId = prefStorage.get('panel').pid;
    }

    if (state.metadata.panel === panelId) {
      return Promise.resolve();
    }

    return getPanelMetadata(panelId).then((data) => {
      const metadata = data.data;

      if (!prefStorage.get('grants')) {
        return;
      }

      const analyticsGrants = prefStorage.get('grants').Apollo.analytics;

      metadata.filterables
        .sort((a, b) => {
          if (a.customDefined !== b.customDefined) {
            return a.customDefined ? 1 : -1;
          }

          return a.id < b.id ? -1 : 1;
        })
        .forEach((filterable) => {
          if (filterable.values && filterable.values.length) {
            if (filterable.id === 'package') {
              filterable.values = splitSort(filterable.values, 'identifier');
            } else if (!['day', 'date', 'hour'].includes(filterable.id)) {
              filterable.values.sort((a, b) =>
                a.identifier < b.identifier ? -1 : 1
              );
            }

            if (filterable.id === 'isp') {
              const ispGrants = analyticsGrants
                .filter(({ panel, isp }) => panel === panelId && isp)
                .map(({ isp }) => isp);

              if (ispGrants.length) {
                // @todo: remove Number() when API fixed
                filterable.values = filterable.values.filter(({ id }) =>
                  ispGrants.includes(Number(id))
                );
              }
            }

            if (filterable.id === 'country') {
              const countryGrants = analyticsGrants
                .filter(({ panel, country }) => panel === panelId && country)
                .map(({ country }) => country);

              if (countryGrants.length) {
                // @todo: remove Number() when API fixed
                filterable.values = filterable.values.filter(({ id }) =>
                  countryGrants.includes(Number(id))
                );
              }
            }
          }
        });

      function extractMetricFactory(array) {
        return (metricId) => {
          const index = array.findIndex(({ id }) => id === metricId);
          return array.splice(index, 1)[0];
        };
      }

      // This reorders the filterables so that these sections are at the top
      const first = ['isp', 'package', 'unit', 'mac'].map(
        extractMetricFactory(metadata.filterables)
      );
      const second = ['date', 'day', 'hour'].map(
        extractMetricFactory(metadata.filterables)
      );

      metadata.filterables.unshift(
        ...first.filter((f) => f),
        { id: 'dropdown-separator' },
        ...second.filter((f) => f),
        { id: 'dropdown-separator' }
      );

      // Insert another divider between first custom defined and previous
      const firstCustom = metadata.filterables.findIndex(
        ({ customDefined }) => customDefined
      );
      if (firstCustom !== -1) {
        metadata.filterables.splice(firstCustom, 0, {
          id: 'dropdown-separator'
        });
      }

      metadata.splittables.sort((a, b) => (a.id < b.id ? -1 : 1));

      const firstSplittables = ['isp', 'package', 'unit', 'mac'].map(
        extractMetricFactory(metadata.splittables)
      );
      metadata.splittables.unshift(...firstSplittables, {
        id: 'dropdown-separator'
      });

      const metricGrants = analyticsGrants
        .filter(({ panel, metric }) => panel === panelId && metric)
        .map(({ metric }) => metric);

      if (metricGrants.length) {
        metadata.metrics = metadata.metrics.filter(({ key }) =>
          metricGrants.includes(key)
        );
      }

      metadata.panel = panelId;

      commit('setMetadata', metadata);
    });
  }
};

export const getters = {
  allRequestsHaveReturned(state, getters) {
    return (
      getters.failedMetricsCount + getters.successfulMetricsCount ===
      state.metricsRequestedCount
    );
  },
  failedMetricsCount(state) {
    return state.failedMetricData.length;
  },
  successfulMetricsCount(state) {
    return state.data.length;
  }
};
