<template>
  <transition name="fade">
    <DocumentCover :open="isVisible" :cover-zindex="-1" @hide="$emit('close')">
      <div class="drop__wrapper" :style="wrapperStyles" @click="$emit('close')">
        <div
          class="drop__pointer-under"
          :class="`drop__pointer-under--${pointerOrientation}`"
          :style="pointerStyles"
        ></div>
        <div
          class="drop__pointer-over"
          :class="`drop__pointer-over--${pointerOrientation}`"
          :style="pointerStyles"
        ></div>
        <slot></slot>
      </div>
    </DocumentCover>
  </transition>
</template>

<script>
import { DocumentCover } from '@samknows/design-system';

export default {
  components: { DocumentCover },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    verticalOffset: {
      type: Object,
      required: true
    },
    pxWrapperRight: {
      type: String,
      required: true
    },
    pxPointerPosition: {
      type: String,
      required: true
    },
    pointerOrientation: {
      type: String,
      required: true
    }
  },
  computed: {
    wrapperStyles() {
      const styles = {};
      styles[this.verticalOffset.direction] = this.verticalOffset.value;
      if (document.body.dir === 'rtl') {
        styles.left = this.pxWrapperRight;
      } else {
        styles.right = this.pxWrapperRight;
      }
      return styles;
    },
    pointerStyles() {
      if (
        this.pointerOrientation === 'top' ||
        this.pointerOrientation === 'bottom'
      ) {
        if (document.body.dir === 'rtl') {
          return { left: this.pxPointerPosition };
        }
        return { right: this.pxPointerPosition };
      }

      return {
        top: this.pxPointerPosition
      };
    }
  },
  component: {
    DocumentCover
  }
};
</script>

<style lang="scss" scoped>
$pointer-height: 12px;

$border-transparent: $pointer-height solid transparent;
$border-dark-gray: $pointer-height solid color(lines);
// Arrow triangle background
$border-white: $pointer-height solid color(base-background);

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.drop__wrapper {
  position: absolute;

  box-shadow: elevation('lg');
  border: 1px solid color(lines);
  border-radius: rounding('1x');
  background-color: color(base-background);
  z-index: 2000;
}

/*
  POINTER UNDER STYLES
  ====================
  */

.drop__pointer-under {
  position: absolute;
}

.drop__pointer-under--top {
  top: -$pointer-height - 1;
  border-right: $border-transparent;
  border-bottom: $border-dark-gray;
  border-left: $border-transparent;
}

.drop__pointer-under--right {
  right: -$pointer-height - 1;
  border-bottom: $border-transparent;
  border-left: $border-dark-gray;
  border-top: $border-transparent;
}

.drop__pointer-under--bottom {
  bottom: -$pointer-height - 1;
  border-left: $border-transparent;
  border-top: $border-dark-gray;
  border-right: $border-transparent;
}

.drop__pointer-under--left {
  left: -$pointer-height - 1;
  border-top: $border-transparent;
  border-right: $border-dark-gray;
  border-bottom: $border-transparent;
}

/*
  POINTER OVER STYLES
  ====================
  */

.drop__pointer-over {
  position: absolute;
}

.drop__pointer-over--top {
  top: -$pointer-height + 1;
  border-right: $border-transparent;
  border-bottom: $border-white;
  border-left: $border-transparent;
}

.drop__pointer-over--right {
  right: -$pointer-height + 1;
  border-bottom: $border-transparent;
  border-left: $border-white;
  border-top: $border-transparent;
}

.drop__pointer-over--bottom {
  bottom: -$pointer-height + 1;
  border-left: $border-transparent;
  border-top: $border-white;
  border-right: $border-transparent;
}

.drop__pointer-over--left {
  left: -$pointer-height + 1;
  border-top: $border-transparent;
  border-right: $border-white;
  border-bottom: $border-transparent;
}
</style>
