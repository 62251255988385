<template>
  <a
    v-if="link.link.includes('//')"
    class="nav__link"
    :href="link.link"
    target="_blank"
    :data-cy="`nav-link-${link.id}`"
  >
    <span class="link__icon" :class="link.icon" aria-hidden="true"></span>
    <div class="link__text">
      <span class="link__text-head">{{ link.name }}</span>
      <span class="link__text-sub">{{ link.description }}</span>
    </div>
  </a>

  <router-link
    v-else
    :to="link.link"
    class="nav__link"
    :data-cy="`nav-link-${link.id}`"
  >
    <span class="link__icon" :class="link.icon" aria-hidden="true"></span>
    <div class="link__text">
      <span class="link__text-head">{{ link.name }}</span>
      <span class="link__text-sub">{{ link.description }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'NavLink',

  props: {
    link: {
      type: Object,
      required: true
    }
  }
};
</script>
