
import { defineComponent } from 'vue';
import ResetPasswordService from '@/common/services/ResetPassword';
import { whitelabel } from '@samknows/utils';
import {
  BaseAlert,
  PasswordInput,
  BaseButton,
  BaseSpinner
} from '@samknows/design-system';

export default defineComponent({
  metaInfo() {
    return {
      title: this.$t('pages.passwordReset.title')
    };
  },
  components: {
    PasswordInput,
    BaseAlert,
    BaseButton,
    BaseSpinner
  },
  props: {
    isPasswordExpired: {
      type: Boolean,
      default: false
    },
    token: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    },
    passwordExpiryTime: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      isLoading: true,
      isLoadingButton: false,
      isValidToken: false,
      inputType: 'password',
      password: '',
      passwordRepeat: '',
      hideImage: whitelabel('hideLoginImage'),
      showErrors: false,
      isPasswordValid: false,
      error: '',
      resetSuccess: false
    };
  },
  computed: {
    matchPassword(): boolean {
      return this.password === this.passwordRepeat;
    }
  },
  mounted() {
    this.isValidResetToken(this.userId, this.token);
  },
  methods: {
    async isValidResetToken(userId: string, token: string) {
      try {
        await ResetPasswordService.isValidResetToken(userId, token);
        this.isValidToken = true;
      } catch {
        this.isValidToken = false;
      }
      this.isLoading = false;
    },

    async resetPassword(userId: string, token: string, password: string) {
      this.error = '';
      this.showErrors = false;
      if (!this.isPasswordValid) {
        this.showErrors = true;
        return;
      }
      this.isLoadingButton = true;

      try {
        await ResetPasswordService.resetPassword(userId, token, password);
        this.resetSuccess = true;
      } catch (response) {
        if (response instanceof Response) {
          const error = await response.json();
          if (
            error.code === 'VALIDATION_FAIL' &&
            error.errors.password.includes('PASSWORD_UNCHANGED')
          ) {
            this.error = this.$t('error.PASSWORD_UNCHANGED');
          }
        }
      }
      this.isLoadingButton = false;
    }
  }
});
