import {
  convertToVideoQuality,
  getUniquePanels,
  isVideoQuality
} from '@/common/helpers/chart-util';
import viewTypes from '@/common/helpers/view-types';
import AuthService, { Accessibles } from '@/common/services/Auth';
import store from '@/common/store';
import { GRANT_SECTION } from '@/features/account/constants';
import { fillInMissingDates } from '@/features/unit-tests-view/helpers/UnitTests.helpers';
import { dateParse, prefStorage } from '@samknows/utils';
import deepEqual from 'fast-deep-equal';
import cloneDeep from 'lodash.clonedeep';
import Vue from 'vue';
import {
  AnalyticsDataList,
  AnalyticsFormData,
  CHART_TYPE,
  HighChartYAxisMetricUnit,
  MetricKeys,
  MeasurementBuilderFormData
} from '../global.interfaces';
import splitSort from './split-sort';

export function subtitleText(
  chartMetaArray: MeasurementBuilderFormData[]
): string {
  if (chartMetaArray.length === 2) {
    const title = Vue.t(`metrics.${chartMetaArray[1].metrics[0].key}.name`);
    const metricName = Vue.t('components.molecules.BaseGraph.andSomething', {
      something: title
    });

    if (chartMetaArray[1].plotValue !== 'metricValue') {
      const plotValue = Vue.t(`common.columns.${chartMetaArray[1].plotValue}`);
      return `${metricName} - ${plotValue}`;
    }

    return metricName;
  }

  if (chartMetaArray.length > 2) {
    return Vue.t('components.molecules.BaseGraph.andXOtherSeries', {
      x: chartMetaArray.length - 1
    });
  }

  return '';
}

// Note: this function mutates data as well as returning axes
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add input and output types
export function generateYAxes(data: any): any[] {
  return data.reduce((axes, series) => {
    // If there are multiple metrics we only need to look at the first one -
    // they will share an axis anyway
    const metric = series.formData.metrics[0];

    const yLabelKey = metric
      ? `metrics.${metric.key}.label`
      : 'common.labelValue';
    let yLabel = Vue.t(yLabelKey);
    if (yLabel === yLabelKey) {
      yLabel = Vue.t('common.labelValue');
    }

    const plotValue = series.formData.plotValue || 'metricValue';
    const isCount = plotValue.endsWith('Count');

    // If the "Value to plot" option, configured per series by the user, is not
    // the default value of 'metricValue' ("Mean") which appears on an axis as
    // ${value} (measurement)` [e.g. "Speed (Mbps)"]:
    //   - If the"sampleCount" nor "agentCount", the yAxis label is whatever
    //     has been retrieved from our translation files. (e.g. "Sample Count")
    //   - Otherwise, take the translated metric key and append the plotValue
    //     label to it [e.g. "Speed - Standard Deviation (Mpbs)"]
    if (plotValue !== 'metricValue') {
      const label = Vue.t(`common.columns.${plotValue}`);

      if (isCount) {
        yLabel = label;
      } else {
        yLabel = yLabel.replace(/ *(\(|$)/, ` - ${label} $1`);
      }
    }

    let axisIndex = axes.findIndex(
      (s: { yLabel: string; metric: { unit: string } }) =>
        s.yLabel === yLabel && (isCount || s.metric.unit === metric.unit)
    );

    if (axisIndex === -1) {
      const axis = {
        metric,
        yLabel,
        unit: isCount ? '' : metric.unit
      };

      axisIndex = axes.push(axis) - 1;
    }

    series.yAxis = axisIndex;
    return axes;
  }, []);
}

/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add input and output types
export function generateMultiMetricChartData(data: any): {
  series: any[];
  axes: any[];
} {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  const axes = [];
  let series = cloneDeep(data);

  series = series.map((item) => {
    // If there are multiple metrics we only need to look at the first one -
    // they will share an axis anyway
    const metric = item.formData.metrics[0];
    const yLabelKey = metric
      ? `metrics.${metric.key}.label`
      : 'common.labelValue';
    let yLabel = Vue.t(yLabelKey);
    if (yLabel === yLabelKey) {
      yLabel = Vue.t('common.labelValue');
    }

    const plotValue = item.formData.plotValue || 'metricValue';
    const isCount = plotValue.endsWith('Count');

    // If the "Value to plot" option, configured per series by the user, is not
    // the default value of 'metricValue' ("Mean") which appears on an axis as
    // ${value} (measurement)` [e.g. "Speed (Mbps)"]:
    //   - If the"sampleCount" nor "agentCount", the yAxis label is whatever
    //     has been retrieved from our translation files. (e.g. "Sample Count")
    //   - Otherwise, take the translated metric key and append the plotValue
    //     label to it [e.g. "Speed - Standard Deviation (Mpbs)"]
    if (plotValue !== 'metricValue') {
      const label = Vue.t(`common.columns.${plotValue}`);

      if (isCount) {
        yLabel = label;
      } else {
        yLabel = yLabel.replace(/ *(\(|$)/, ` - ${label} $1`);
      }
    }

    let axisIndex = axes.findIndex(
      (s: HighChartYAxisMetricUnit) =>
        s.yLabel === yLabel && (isCount || s.metric.unit === metric.unit)
    );

    if (axisIndex === -1) {
      const axis = {
        metric,
        yLabel,
        unit: isCount ? '' : metric.unit
      };

      axisIndex = axes.push(axis) - 1;
    }

    item.yAxis = axisIndex;

    return item;
  });

  return {
    series,
    axes
  };
}

/**
 * This is the title used when dealing with a series from the form data - so
 * apollo form and CSV downloading.
 */
export function titleForSeriesSet(
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add types for series
  series: any,
  formDataArray: AnalyticsFormData[]
): string {
  const title = [];

  const panels = getUniquePanels(formDataArray);
  const accessibles: Accessibles = prefStorage.get('accessibles');
  const panel = accessibles.apollo.find(({ pid }) => pid === series.panel);

  if (panels.length > 1) {
    title.push(panel.front_name);
  }

  if (series.metrics.length && series.metrics[0]) {
    const key = series.metrics[0].key || series.metrics[0];
    title.push(Vue.t(`metrics.${key}.name`));
  } else {
    title.push(Vue.t('components.molecules.EnterpriseForm.seriesNoMetrics'));
  }

  const hasMultipleTypes = formDataArray.some(
    ({ chartType }) => chartType !== series.chartType
  );

  if (hasMultipleTypes) {
    title.push(Vue.t(`common.chartTypes.${series.chartType}`));
  }

  if (series.plotValue !== 'metricValue') {
    title.push(Vue.t(`common.columns.${series.plotValue}`));
  }

  return title.join(' - ');
}

/**
 * This is the title when dealing with a series from response data.
 */
export function titleForSeries(
  dataset: AnalyticsDataList,
  meta: MeasurementBuilderFormData,
  metaArray: MeasurementBuilderFormData[]
): string {
  let name = [];

  if (metaArray && metaArray.length > 1) {
    const panels = getUniquePanels(metaArray);

    if (panels.length > 1) {
      const accessibles: Accessibles = prefStorage.get('accessibles');
      const panel = accessibles.apollo.find(({ pid }) => pid === meta.panel);
      name.push(panel.front_name);
    }

    name.push(Vue.t(`metrics.${meta.metrics[0].key}.name`));

    const index = metaArray.findIndex((item) => {
      return deepEqual(meta, item);
    });

    name[0] = `#${index + 1}: ${name[0]}`;
  }

  if (dataset.splitGroup) {
    name.push(...dataset.splitGroup.map((group) => group.splitLabel));
  }

  if (
    meta.plotValue !== 'metricValue' &&
    !['column', 'column_stacked'].includes(meta.chartType)
  ) {
    name.push(Vue.t(`common.columns.${meta.plotValue}`));
  }

  name = name.filter((part) => part);

  if (name.length) {
    let joinedName = name.join(' - ');
    if (!dataset.metricData?.length) {
      joinedName = `${joinedName} (No Data)`;
    }

    return joinedName;
  }

  return Vue.t('common.other');
}

export function highchartsChartType(chartType: string): string {
  const highchartsChartTypes: Record<string, string> = {
    raw: 'scatter',
    aggregate: 'line',
    boxplot: 'boxplot',
    column_stacked: 'column',
    cdf: 'spline'
  };
  return highchartsChartTypes[chartType] || chartType;
}

export function chartTypeIsMap(chartType: CHART_TYPE): boolean {
  return [
    CHART_TYPE.MAP,
    CHART_TYPE.RAW_MOBILE,
    CHART_TYPE.GEO_HEATMAP
  ].includes(chartType);
}

/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add input and output types
function metricDataToHighcharts(
  metricData: any[] = [],
  meta: any,
  metricKey: MetricKeys
): any {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  if (meta.chartType === 'cdf') {
    const point = metricData[0];
    const pointData = [];

    const pointWithoutPercentiles = Object.assign({}, point);
    delete pointWithoutPercentiles.percentiles;

    // Show cumulative CDF graph - turns the CDF graph backwards -  shape should be diagonal down
    if (meta.tailDistribution) {
      for (let i = 1; i <= 100; i++) {
        if (point.percentiles[101 - i] !== point.percentiles[101 - i - 1]) {
          pointData.push({
            x: point.percentiles[101 - i],
            y: i,
            data: pointWithoutPercentiles
          });
        }
      }
      // Extra point added to flatten the line at the start
      pointData.push({ x: 0, y: 100, data: {} });
    }
    // Show normal CDF graph - shape should be diagonal up
    else {
      for (let i = 100; i >= 1; i--) {
        if (point.percentiles[i] !== point.percentiles[i + 1]) {
          pointData.push({
            x: point.percentiles[i],
            y: i,
            data: pointWithoutPercentiles
          });
        }
      }
      // Extra point added to flatten the line at the start
      pointData.push({ x: 0, y: 0, data: {} });
    }

    return { data: pointData };
  }

  // TODO: Do not use the store here as it can create unexpected behavior
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] store.state should have typings for the sub-states
  const hideInsignificant = (store.state as any).analytics.hideInsignificant;

  if (
    viewTypes.isEnterpriseView &&
    hideInsignificant &&
    !meta.timeAggregation.includes('_of_') &&
    metricData.length > 10
  ) {
    const lastPointSignificant = (assumedSignificant = -3): boolean => {
      const extent = metricData.reduce(
        ([minValue, maxValue], curr) => [
          Math.min(minValue, curr[meta.plotValue]),
          Math.max(maxValue, curr[meta.plotValue])
        ],
        [Number.MAX_SAFE_INTEGER, -Number.MAX_SAFE_INTEGER]
      );

      const finalVal = metricData[metricData.length - 1][meta.plotValue];

      const lastSamples = metricData[metricData.length - 1].sampleCount;
      const significantSamples =
        metricData[metricData.length + assumedSignificant].sampleCount;

      if (lastSamples < significantSamples / 10) {
        return false;
      }

      if (extent.includes(finalVal) && lastSamples < significantSamples / 2) {
        return false;
      }

      return true;
    };

    if (!lastPointSignificant()) {
      metricData.splice(-1, 1);

      if (!lastPointSignificant(-2)) {
        metricData.splice(-1, 1);
      }
    }
  }

  const isVideoQualityCached = isVideoQuality(meta.metrics[0].key);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add types for item and return types
  const getYValue = (item: any): any => {
    let value;
    if (meta.plotValue === 'iqr') {
      value = item.perc_75 - item.perc_25;
    } else if (metricKey === MetricKeys.ACTIVE_AGENT_COUNT) {
      value = item.agentCount;
    } else {
      value = item[meta.plotValue || 'metricValue'];
    }

    return isVideoQualityCached &&
      !(meta.plotValue && meta.plotValue.endsWith('Count'))
      ? convertToVideoQuality(value, meta.metrics[0].key)
      : value;
  };

  if (['column', 'column_stacked'].includes(meta.chartType)) {
    return {
      data: metricData.map((item) => ({
        y: getYValue(item),
        data: item
      }))
    };
  }

  if (meta.chartType === 'raw_mobile') {
    return {
      data: metricData.map((item) =>
        Object.assign({}, item, {
          dtime: dateParse(item.dtime)
        })
      )
    };
  }

  const isXofY = meta.timeAggregation.includes('_of_');
  const seriesData = metricData.map((item) => ({
    x: isXofY ? item.aggregatedNumber : dateParse(item.dtime),
    y: getYValue(item),
    data: item
  }));

  return {
    data: seriesData
  };
}
/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add input and output types
export function dataToSeries(
  data: any,
  formData: MeasurementBuilderFormData[],
  showMissingDays?: boolean,
  isLandingDashboard?: boolean
): any {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  const firstMeta = data[0].formData;
  const firstChartType = firstMeta.chartType;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add input and output types
  const getSortValue = (item: any, sortBy: any): any =>
    sortBy === 'iqr' ? item.perc_75 - item.perc_25 : item[sortBy];

  if (firstChartType === 'boxplot') {
    let seriesData = data.map((dataset) => {
      const meta = dataset.formData;
      const d = dataset.metricData[0];

      const name =
        dataset.splitGroup && dataset.splitGroup.length
          ? dataset.splitGroup[0].splitLabel
          : Vue.t(`metrics.${meta.metrics[0].key}.name`);

      return {
        low: d.perc_1,
        q1: d.perc_25,
        median: d.median,
        q3: d.perc_75,
        high: d.perc_99,
        name,
        data: d
      };
    });

    if (firstMeta.sortBy === 'name' || !firstMeta.sortBy) {
      seriesData = splitSort(seriesData, 'name');
    } else {
      const sortBy = data[0].formData.sortBy;
      seriesData = seriesData.sort((a, b) =>
        getSortValue(a.data, sortBy) < getSortValue(b.data, sortBy) ? -1 : 1
      );
    }

    return [
      {
        showInLegend: 0,
        data: seriesData,
        formData: data[0].formData,
        series: data[0].series,
        yAxis: data[0].yAxis
      }
    ];
  }

  const unsortedSeries = data.map((series) => {
    const meta = series.formData;

    const startFrom = ['column', 'column_stacked'].includes(meta.chartType)
      ? 1
      : 0;

    let showInLegend;
    // TODO: This was an easy way to make sure the landing dashboard displayed
    // a legend where appropriate, in time for alpha release. This should be
    // reconsidered/refactored where appropriate, post alpha.
    if (isLandingDashboard) {
      showInLegend =
        meta.splits.filter((split: unknown) => split).length > startFrom ||
        (!!formData && formData.length > 1) ||
        series.splitGroup.length > 0;
    } else {
      showInLegend =
        meta.splits.filter((split: unknown) => split).length > startFrom ||
        (!!formData && formData.length > 1);
    }

    const canViewPoint = AuthService.hasPermissions(
      GRANT_SECTION.TEST_MEASUREMENTS,
      'advanced_analytics',
      meta.panel
    );

    const timePeriod = meta?.timeAggregation;
    const fromToDates = meta?.date;
    const isHourly = timePeriod === 'hourly';
    let metricDataRaw = series.metricData;
    const metricKey = series.metricMetadata?.metricKey;

    if (!isHourly && showMissingDays) {
      metricDataRaw = fillInMissingDates(series.metricData, fromToDates);
    }

    const singleSeries = {
      type: highchartsChartType(meta.chartType),
      showInLegend: viewTypes.isEnterpriseView ? showInLegend : meta.splitData,
      turboThreshold: 0,
      boostThreshold: 1000,
      canViewPoint,
      ...series,
      yAxis: series.yAxis,
      metricData: undefined,
      ...metricDataToHighcharts(metricDataRaw, meta, metricKey)
    };

    if (singleSeries.data.length === 1) {
      singleSeries.marker = { enabled: true };
    }

    if (meta.chartType === 'raw') {
      singleSeries.type = 'line';
      singleSeries.lineWidth = 0;
      singleSeries.states = {
        hover: {
          lineWidthPlus: 0
        }
      };
      singleSeries.marker = { enabled: true, radius: 2 };
      singleSeries.findNearestPointBy = 'xy';
    }

    return singleSeries;
  });

  if (['column', 'column_stacked'].includes(firstChartType)) {
    // Unlike other chart types, bar charts get the name from the data points,
    // so we need to add them here.
    let sortedSeries;

    const splitCount = firstMeta.splits.filter(
      (split: unknown) => split
    ).length;

    if (
      firstMeta.sortBy === 'name' ||
      !firstMeta.sortBy ||
      splitCount !== 1 ||
      firstChartType !== 'column'
    ) {
      sortedSeries = splitSort(unsortedSeries, 'name');
    } else {
      const sortBy = data[0].formData.sortBy;
      sortedSeries = unsortedSeries.sort((a, b) =>
        getSortValue(a.data[0].data, sortBy) <
        getSortValue(b.data[0].data, sortBy)
          ? -1
          : 1
      );
    }

    return sortedSeries
      .map((series) => {
        const meta = series.formData;

        series.data[0].originalName = series.name;
        const splitName = series.name.split(' - ');
        series.name = splitName[splitName.length - 1];

        if (splitCount === 1 && meta.chartType === 'column') {
          series.data[0].name = splitName.join(' - ');
        } else {
          series.data[0].name = splitName.slice(0, -1).join(' - ');
        }
        return series;
      })
      .reduce((series, dataset) => {
        let previousSeries;
        if (splitCount === 1 && dataset.formData.chartType === 'column') {
          previousSeries = series[0];
        } else {
          previousSeries = series.find(({ name }) => name === dataset.name);
        }

        if (previousSeries) {
          previousSeries.data.push(...dataset.data);
        } else {
          series.push(dataset);
        }

        return series;
      }, [])
      .map((series, i: number) => Object.assign(series, { series: i }));
  }

  const multiMetric = unsortedSeries.some((series) => series.series > 0);
  return (
    splitSort(unsortedSeries, 'name')
      // This reducer basically defines what the colours should be - when a
      // chart has multiple formDatas, each series has its own colour, otherwise
      // each chart series has its own colour
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add types for series
      .reduce((seriesSet, series: any, i: number) => {
        const previousSeries = i ? seriesSet[seriesSet.length - 1] : undefined;
        const previousSeriesNum = previousSeries ? previousSeries.series : -1;

        let seriesNum = previousSeriesNum;
        if (
          !multiMetric ||
          (multiMetric && series.series > previousSeriesNum)
        ) {
          seriesNum++;
        }
        series.series = seriesNum;

        return seriesSet.concat(series);
      }, [])
  );
}
