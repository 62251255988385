import { Http } from '@samknows/utils';
import config from '@/common/config';
import { PromiseResponse } from '@/common/global.interfaces';

export interface ConsumerConfig {
  unitId: number;
  hasRestrictedMetrics: boolean;
}

export async function getRestrictedMetricsConfigXenia(
  unitId: number
): Promise<ConsumerConfig> {
  const response = await Http.get<
    PromiseResponse<{ consumerConfig: ConsumerConfig }>
  >(config.api.unitAnalytics, `${unitId}/consumer-config`);

  return response.data.consumerConfig;
}

export async function hasRestrictedMetrics(unitId: number): Promise<boolean> {
  try {
    const response = await Http.get<
      PromiseResponse<{ hasRestrictedMetrics: boolean }>
    >(
      config.api.unitAnalytics,
      `${unitId}/consumer-config/has-restricted-metrics`
    );
    const { data } = response;
    return data.hasRestrictedMetrics;
  } catch (error) {
    if (error instanceof Response && error.status === 404) {
      return false;
    }
    throw error;
  }
}

export async function setRestrictedMetricsConfigXenia(
  unitId: number,
  hasRestrictedMetrics: boolean
): Promise<void> {
  const requestBody = { hasRestrictedMetrics };

  await Http.request<Response>(
    `${config.api.unitAnalytics}/${unitId}/consumer-config`,
    {
      method: 'POST',
      body: JSON.stringify(requestBody)
    }
  );
}
