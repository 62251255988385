
import { Portal } from '@linusborg/vue-simple-portal';
import { BaseModal, SvgIcon } from '@samknows/design-system';
import { EnumBarcodeFormat } from 'dynamsoft-barcode-reader';
import { CameraEnhancer, CameraView } from 'dynamsoft-camera-enhancer';
import { CaptureVisionRouter } from 'dynamsoft-capture-vision-router';
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import AuthService from '@/common/services/Auth';
import { GRANT_SECTION } from '@/features/account/constants';
import { setupDBR } from '@/features/unit-search/components/barcodeScanner/dynamsoftBarcodeReader';

export default defineComponent({
  components: {
    Portal,
    SvgIcon,
    BaseModal
  },
  props: {
    isBlack: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    enum BarcodeScannerStatus {
      NEW = 'new',
      SCANNING = 'scanning',
      ERROR = 'error'
    }

    const status = ref(BarcodeScannerStatus.NEW);
    const enhancerUIContainer = ref(null);
    const isMobile = ref(window.innerWidth < 768);
    const hasPermission = ref(
      AuthService.hasPanelPermissions(
        GRANT_SECTION.TEST_MEASUREMENTS,
        'barcode_scanner'
      )
    );
    const showBarcodeScanner = computed(
      () => isMobile.value && hasPermission.value
    );
    const icon = computed(() =>
      props.isBlack
        ? '/img/icons/icon-barcode-black.svg'
        : '/img/icons/icon-barcode-white.svg'
    );
    const isScanning = computed(
      () => status.value === BarcodeScannerStatus.SCANNING
    );
    const isError = computed(() => status.value === BarcodeScannerStatus.ERROR);

    const router = ref(null);
    const cameraView = ref(null);
    const cameraEnhancer = ref(null);

    async function setupScannerLiveStream() {
      cameraView.value = await CameraView.createInstance(
        enhancerUIContainer.value
      );
      cameraEnhancer.value = await CameraEnhancer.createInstance(
        cameraView.value
      );

      // Restrict scanning area to the center of the screen so the user can focus on MAC address barcode
      cameraEnhancer.value.setScanRegion({
        x: 20,
        y: 45,
        width: 60,
        height: 15,
        isMeasuredInPercentage: true
      });

      router.value = await CaptureVisionRouter.createInstance();
      router.value.setInput(cameraEnhancer);
    }

    async function filterAllowedBarcodes() {
      const settings = await router.value.getSimplifiedSettings(
        'ReadSingleBarcode'
      );
      settings.barcodeSettings.barcodeFormatIds = EnumBarcodeFormat.BF_CODE_128;
      await router.value.updateSettings('ReadSingleBarcode', settings);
    }

    function handleReadResults() {
      router.value.addResultReceiver({
        onDecodedBarcodesReceived: (result) => {
          if (result.barcodeResultItems.length > 0) {
            for (const item of result.barcodeResultItems) {
              emit('scan', item.text);
              closeScanner();
            }
          }
        }
      });
    }

    async function openScanner() {
      status.value = BarcodeScannerStatus.SCANNING;
      try {
        await setupScannerLiveStream();
        await filterAllowedBarcodes();
        handleReadResults();

        await cameraEnhancer.value.open();
        await router.value.startCapturing('ReadSingleBarcode');
      } catch (error) {
        if (error instanceof Error) {
          if (error.message.includes('network connection error')) {
            console.error(
              'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.'
            );
          } else {
            console.error(error);
          }
        }

        status.value = BarcodeScannerStatus.ERROR;
      }
    }

    function closeScanner() {
      try {
        router.value?.dispose();
        cameraEnhancer.value?.dispose();
        cameraView?.value?.dispose();
      } catch (error) {
        console.error(error);
      }

      status.value = BarcodeScannerStatus.NEW;
    }

    onBeforeMount(() => {
      // Only load the library when the user can use it
      if (showBarcodeScanner.value) {
        setupDBR();
      }
    });

    return {
      status,
      showBarcodeScanner,
      enhancerUIContainer,
      icon,
      isScanning,
      isError,
      openScanner,
      closeScanner
    };
  }
});
