import config from '@/common/config';
import { Http } from '@samknows/utils';

function wrappedResponse(url: string, opts: RequestInit): Promise<unknown> {
  return new Promise((resolve, reject) => {
    const passwordResetReq = Http.request<Response>(url, {
      ...opts,
      getFullResponse: true
    });

    passwordResetReq
      .then((res) => res.json())
      .then((data) => {
        if (!data || typeof data.code === 'undefined') {
          reject('INVALID_BODY');
          return;
        }
        if (data.code !== 'OK') {
          reject(data);
          return;
        }
        resolve(undefined);
      })
      .catch((error) => reject(error));
  });
}

export default {
  requestPasswordReset(email: string) {
    return wrappedResponse(`${config.api.auth}/sendPasswordResetEmail`, {
      method: 'POST',
      body: JSON.stringify({
        email
      })
    });
  },

  isValidResetToken(userId: string, token: string) {
    return wrappedResponse(`${config.api.auth}/isPasswordResetTokenValid`, {
      method: 'POST',
      body: JSON.stringify({
        user_id: userId,
        token
      })
    });
  },

  resetPassword(userId: string, token: string, password: string) {
    return wrappedResponse(`${config.api.auth}/resetPassword`, {
      method: 'POST',
      body: JSON.stringify({
        user_id: userId,
        token,
        password
      })
    });
  }
};
