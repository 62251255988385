// No type checking needed - do not add any other logic than HotJar code
// Use another service for other logic so it can benefit from TypeScript
export function initiateHotJar() {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 328739, hjsv: 5 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
}
