import { Http } from '@samknows/utils';
import config from '@/common/config';
import Vue from 'vue';
import { PanelMetadata } from '@/common/global.interfaces';

interface QueryStringSearch {
  searchKey: string;
  searchValue: string;
}

interface PanelMetadataPartial {
  id: string;
  identifier: string;
}

interface MetaDataAPIResponse {
  code: string;
  message: string;
  data: PanelMetadata[];
}

export const getQueryStringSearch = (
  route: Record<string, string>
): QueryStringSearch | null => {
  const searchKeys = ['id', 'email', 'mac'];

  if (!route || typeof route === 'undefined') {
    return null;
  }

  // get list of keys from query string
  const queryKeys = Object.keys(route);

  // the querystring could contain keys we're not interested in (e.g. continue=units/search) so we need to filter down to only what we need
  const validKeys = queryKeys.filter((queryKey) =>
    searchKeys.includes(queryKey)
  );

  if (validKeys.length < 1) {
    return null;
  }

  // we can only search 1 thing so get the first valid key and corresponding value (the search term)
  const searchKey = validKeys[0];
  const searchValue = route[searchKey];

  return {
    searchKey,
    searchValue
  };
};

export const getSkNames = (): PanelMetadataPartial[] => {
  return [
    {
      id: 'id',
      identifier: Vue.t('common.tableColumns.agent_id')
    },
    {
      id: 'mac',
      identifier: Vue.t('common.tableColumns.mac')
    },
    {
      id: 'email',
      identifier: Vue.t('common.tableColumns.email')
    },
    {
      id: 'first_name',
      identifier: Vue.t('common.tableColumns.first_name')
    },
    {
      id: 'last_name',
      identifier: Vue.t('common.tableColumns.last_name')
    }
  ];
};

export const getMetadataNames = async (
  panelId: number
): Promise<PanelMetadata[] | PanelMetadataPartial[]> => {
  let apiRes: MetaDataAPIResponse | null = null;
  try {
    apiRes = await Http.get(config.api.zeus, `/panels/${panelId}/metadata`);
  } catch (error) {
    console.error(error);
  }

  const skNames = getSkNames();
  const metaNames = apiRes?.data || [];
  return [...metaNames, ...skNames];
};
