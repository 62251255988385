import dayjs from 'dayjs';

export enum TIME_MEASUREMENTS {
  MILLISECOND = 'millisecond',
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year'
}

export function relativeDateToDate(relativeDate = ''): string {
  const dateFormat = 'YYYY-MM-DD';

  if (relativeDate === 'now') {
    return dayjs().format(dateFormat);
  }
  const isRelativeDateRexeg = /-(\d+)\s([a-zA-Z]+)/;

  const relativeDateMatches = isRelativeDateRexeg.exec(relativeDate);
  if (relativeDateMatches) {
    const amount: number = +relativeDateMatches[1];
    const period = relativeDateMatches[2] as dayjs.ManipulateType;
    return dayjs().subtract(amount, period).format(dateFormat);
  }

  return relativeDate;
}

// Wrapper around dayjs diff for days - no unit test added as it would be testing dayjs which is not our responsibility
export function getDateTimeDifference(
  firstDate: dayjs.Dayjs,
  secondDate: dayjs.Dayjs,
  measurement?: TIME_MEASUREMENTS
): number {
  const timeMeasurement = measurement || TIME_MEASUREMENTS.DAY;
  return firstDate.diff(dayjs(secondDate), timeMeasurement);
}

export function dayMonthYearString(date: Date): string {
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
}

export function formatDate(date: dayjs.ConfigType, format = 'lll'): string {
  return dayjs(date).format(format);
}

export const durationToDate = (
  from: string | number,
  to: string | number
): number => {
  const start = dayjs(from);
  const end = dayjs(to);
  return dayjs.duration(end.diff(start)).asMilliseconds();
};

export const dateToday = (format?: string): string => {
  const formatString = format || 'YYYY-MM-DD';
  return dayjs().format(formatString);
};

export const getDate = (dateString?: string): dayjs.Dayjs => {
  if (dateString) {
    return dayjs(dateString);
  }

  return dayjs();
};

export const getToday = (): dayjs.Dayjs => dayjs();
