import { MutationTree } from 'vuex';
export const namespaced = true;

interface LanguageSwitcherState {
  visible: boolean;
}

export const state = {
  visible: false
};

export const mutations: MutationTree<LanguageSwitcherState> = {
  SET_VISIBILITY(state: LanguageSwitcherState, visibility: boolean) {
    state.visible = visibility;
  }
};
