import SkConfig from '@/common/config';
import { PromiseResponse } from '@/common/global.interfaces';
import { Http } from '@samknows/utils';
import { ANOMALY_STATE, ANOMALY_STATE_UI } from '../constants';
import { Anomaly, Collection } from '../types';

const API_ENDPOINT = SkConfig.api.anomalyDetection;
let getCollectionsController: AbortController = undefined;

interface CollectionPagination {
  currentPage?: number;
  perPage?: number;
}

// Get list of all collections for the anomaly detection inbox
export const getCollections = async (
  state: string,
  panelId: number,
  searchText = '',
  pagination?: CollectionPagination
): Promise<PromiseResponse<Collection[]>> => {
  // Set controller on first call so we can cancel future calls
  if (!getCollectionsController) {
    getCollectionsController = new AbortController();
  }
  // Cancel previous call if one is still pending
  getCollectionsController.abort();
  getCollectionsController = new AbortController();

  // Default to active state
  let params: Record<string, string> = {
    'state[0]': ANOMALY_STATE.NEW,
    'state[1]': ANOMALY_STATE.ONGOING
  };

  if (state === ANOMALY_STATE_UI.CLOSED) {
    params = {
      'state[]': ANOMALY_STATE.CLOSED
    };
  }

  if (searchText) {
    params.search = searchText;
  }

  params = {
    ...params,
    per_page: `${pagination?.perPage}`,
    page: `${pagination?.currentPage}`,
    sorts: '-first_seen'
  };

  return Http.get(API_ENDPOINT, `/panels/${panelId}/collections`, params, {
    signal: getCollectionsController.signal
  });
};

// Get one collection by id
export const getCollection = async (
  collectionId: number | string
): Promise<PromiseResponse<Collection>> => {
  return Http.get(API_ENDPOINT, `/collections/${collectionId}`);
};

// Get single anomaly from collection by collection id and anomaly id
export const getAnomaly = async (
  collectionId: number | string,
  anomalyId: number
): Promise<PromiseResponse<Anomaly>> => {
  return Http.get(
    API_ENDPOINT,
    `/collections/${collectionId}/anomalies/${anomalyId}?include=trend`
  );
};
