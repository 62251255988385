import Vue from 'vue';
import { Http } from '@samknows/utils';
import SkConfig from '@/common/config';
import { PromiseResponse } from '@/common/global.interfaces';
import AuthService from '@/common/services/Auth';
import { heapTrack } from '@/common/services/heapTracking';

import {
  BasicErrorInputs,
  MeqyasSignupPayload,
  SignupMetadata,
  SignupPayload
} from '@/features/volunteer-signup/types';

export function getMetaData(): Promise<PromiseResponse<SignupMetadata>> {
  return Http.request(`${SkConfig.api.zeus}/signups/metadata`, {
    useAuth: false
  });
}

export function createSignup(data: SignupPayload): Promise<unknown> {
  return Http.request(`${process.env.VUE_APP_ZEUS_API}/signups`, {
    method: 'POST',
    body: JSON.stringify(data),
    useAuth: false
  });
}

export function viewSignup(): Promise<unknown> {
  const userId = AuthService.getTokenPayload().user_id;

  return Http.request(`${SkConfig.api.zeus}/users/${userId}/signup`);
}

export function modifySignup(
  signupId: number,
  data: SignupPayload
): Promise<unknown> {
  const userId = AuthService.getTokenPayload().user_id;

  return Http.request(
    `${SkConfig.api.zeus}/users/${userId}/signup/${signupId}`,
    {
      method: 'PUT',
      body: JSON.stringify(data)
    }
  );
}

export function meqyasSubmit(data: MeqyasSignupPayload): Promise<unknown> {
  return Http.request(`${process.env.VUE_APP_MEQYAS_SIGNUPS_API}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    useAuth: false
  });
}

export async function handleErrors(
  error: Response,
  errorInputs: BasicErrorInputs,
  errorsList: Record<string, string>
): Promise<{
  showGenericError: boolean;
}> {
  const { errors } = await error.json();
  const errorMessages = [];
  let showGenericError = false;

  if (!errors) {
    showGenericError = true;
    heapTrack('SignupSubmitError', {
      type: 'global',
      apiStatusCode: error.status
    });
    return { showGenericError };
  }

  for (const [fieldName, errorCode] of Object.entries(errors)) {
    let errorString = Vue.t(`SignupForm.errorsMessages.${errorCode}`);
    const errorHasLink =
      fieldName === 'isp_other' || fieldName === 'package_other';

    if (errorHasLink) {
      errorString = `SignupForm.errorsMessages.${errorCode}`;
    }
    Vue.set(errorsList, fieldName, errorString);
    errorMessages.push(fieldName);
  }

  const actionableErrorFields = Object.keys(errorInputs);
  const hasActionableErrors = errorMessages.some((error) =>
    actionableErrorFields.includes(error)
  );

  // Map errors to a readable string that can sent to Heap
  const errorsString = Object.entries(errors)
    .map((item) => item.join(': '))
    ?.join(', ');

  let input: HTMLInputElement;
  if (hasActionableErrors) {
    // Focus on first actionable error
    input = errorInputs[errorMessages[0]]?.value?.$el;
    input?.focus();

    heapTrack('SignupSubmitError', {
      type: 'field',
      errors: errorsString,
      apiStatusCode: error.status
    });
  } else {
    heapTrack('SignupSubmitError', {
      type: 'global',
      errors: errorsString,
      apiStatusCode: error.status
    });
    showGenericError = true;
  }
  return {
    showGenericError
  };
}

export function scrollToTop(): void {
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
}
