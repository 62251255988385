import {
  AnalyticsChartData,
  AnalyticsMetricMetadata,
  HighchartsData,
  MetricKeys,
  MeasurementFormDataBase,
  PromiseResponse
} from '@/common/global.interfaces';
import { TT_TASKS } from '@/features/instant-test/TriggerTest.const';
import { RealSpeedTestResults } from './services/RealSpeedResults';

export enum DISCONNECTION_ERRORS {
  NO_DATA = 'NO_DATA',
  DEVICE_CANT_RUN_TEST = 'DEVICE_CANT_RUN_TEST',
  ERROR = 'ERROR'
}

export enum REAL_SPEED_SOURCE {
  WEB_APP = 'web_app',
  IFRAME = 'web_iframe',
  IOS = 'ios_app',
  ANDROID = 'android_app'
}

export enum INSTANT_TEST_SOURCE {
  INSTANT_TEST = 'instant_test',
  INSTANT_TEST_API = 'instant_test_api'
}

export interface DisconnectionsDataSources {
  ttEnabled: boolean;
  udpTestScheduled: boolean;
  udpResultsAvailable: boolean;
}

export interface ChartData {
  data: HighchartsDisconnectionData[];
  type: string;
  error: DISCONNECTION_ERRORS;
  dataSources: DisconnectionsDataSources;
  dataUsage?: TotalBytesProcessed;
}

export interface DisconnectionMetricData {
  dtime: string;
  endDtime: string | null;
  metricValue: number | null;
  source: string;
  unit?: number;
}

export interface HighchartsDisconnectionData
  extends Omit<HighchartsData, 'data'> {
  data: DisconnectionMetricData;
  isOngoingDisconnection?: boolean;
}

export interface DisconnectionsResults {
  data: ChartData[];
}

export interface ScheduleTestAverage {
  metricData?: {
    metricValue: number;
  }[];
  metricMetadata?: AnalyticsMetricMetadata;
}

export type ScheduleTestAveragesResponse = Record<
  string,
  ScheduleTestAverage[]
>;

export interface TestMetadata {
  currentPage: number;
  maxPerPage: number;
  rowsCurrentPage: number;
  totalPages: number;
  totalResultsCount: number;
}

interface TriggerTestGenericResults {
  agent_id: string;
  target: string;
  dtime: string;
  triggering_agent_id: string;
  test_source: string;
  testing_user_email: string;
  testing_user_group: string;
  status?: string;
  data?: Record<string, unknown>;
}

export interface TriggerTestHttpGetPost extends TriggerTestGenericResults {
  bytes_sec: number;
  bytes_sec_interval: number;
}

export interface TriggerTestUdpGetPost {
  action_id: number;
  data: TriggerTestUdpGetPostData;
  dtime: string;
  ingested: true;
  status: string;
  task: string;
  test_id: string;
  unit_id: number;
}

interface TriggerTestUdpGetPostData {
  bytes_sec: number;
  dtime: string;
  failures: number;
  local_datetime: string;
  measured_speed: number;
  packet_size: number;
  percentage_complete: number;
  server_hostname: string;
  server_ip: string;
  successes: number;
  target_speed: number;
  test_type: string;
  transmission_bytes: number;
  transmission_duration: number;
  utc_datetime: string;
}

export interface TriggerTestJitter extends TriggerTestGenericResults {
  up_jitter: number;
  jitter_up: number;
  down_jitter: number;
  jitter_down: number;
  latency: number;
  tx_downstream: number;
  packets_down_sent: number;
  rx_downstream: number;
  packets_down_recv: number;
  tx_upstream: number;
  packets_up_sent: number;
  rx_upstream: number;
  packets_up_recv: number;
}

export interface TriggerTests {
  http_get_mt?: TriggerTestHttpGetPost;
  http_post_mt?: TriggerTestHttpGetPost;
  udp_jitter?: TriggerTestJitter;
  udp_accelerated_dl?: TriggerTestUdpGetPost;
  udp_accelerated_ul?: TriggerTestUdpGetPost;
}

export interface TriggerTestResult {
  test_id: string;
  testing_user_email: string;
  tests: TriggerTests;
}

export interface TriggerTestAllResults {
  meta: TestMetadata;
  results: TriggerTestResult[];
}

export interface InstantTestWidgetResult {
  source: string | null;
  tasks: TriggerTests;
  test_id: string;
  testingUserEmail: string;
  testRunOnExternalApp: boolean;
}

export interface InstantTestWidgetAllResults {
  meta: {
    totalResultsCount: number;
  };
  results: InstantTestWidgetResult[];
}

export interface InstantTestWidgetApiResult {
  downloadBytesSec: number;
  dtime: string;
  jitter: number;
  latency: number;
  packetLoss: number;
  testId: string;
  uploadBytesSec: number;
  testSource: INSTANT_TEST_SOURCE;
  sourceApplication: string | null;
}

export interface InstantTestWidgetApiData {
  results: InstantTestWidgetApiResult[];
}

export interface AggregatedDailyData {
  dtime: string;
  metricValue: number;
}

export interface MetricResultsData {
  aggregatedDaily?: AggregatedDailyData[];
  aggregatedHourly?: AggregatedDailyData[];
  metricMetadata: {
    metricKey: string;
    metricUnit: string;
  };
  total?: {
    metricValue: number;
  };
  dataUsage: TotalBytesProcessed;
}

export interface DailyScheduledTestsByMetric extends MetricResultsData {
  formData: MeasurementFormDataBase;
}

export interface YAxes {
  metric: {
    key: MetricKeys;
  };
  unit: string;
  yAxis: number;
  yLabel: string;
}

export interface ChartInputDataItem extends AnalyticsChartData {
  aggregatedDaily?: AggregatedDailyData[];
  aggregatedHourly?: AggregatedDailyData[];
  metricData: unknown;
  total?: {
    metricValue: number;
  };
}

export interface ChartInputData {
  data: ChartInputDataItem[];
  status: string;
  yAxes: YAxes[];
}

export interface ScheduledTestsResultsForDay {
  time: string;
  metricValue: number;
  target: string;
}

interface OldSkippedTest {
  direction: null;
  dtime: string;
  metricValue: null;
  threshold: null;
  type: string;
  unit_id: number;
}

export interface UnitAnalyticsSkippedTest {
  dtime: string;
  type: string;
}

interface SkippedTotalCount {
  CPU: number;
  NET: number;
}

export interface OldSkippedTestsResults {
  meta: TestMetadata;
  results: OldSkippedTest[];
}

export interface UnitAnalyticsSkippedTestsResults {
  results: UnitAnalyticsSkippedTest[];
  dataUsage: TotalBytesProcessed;
  totalCountByType: SkippedTotalCount;
}

export interface SkippedTestsThresholds {
  id: number;
  name: string;
  owner: string;
  thresholdBytesSecIn: number;
  thresholdBytesSecOut: number;
  thresholdCpuPerc: number;
}

export interface RealSpeedData {
  currentPage: number;
  historicalResults: RealSpeedAPIResult[]; // TODO this might not be the correct type
  pagination: TestMetadata; // TODO this might not be the correct type
  columns: { label: string; key: string }[];
  totalDisplayedResults: number;
  promise: Promise<PromiseResponse<RealSpeedTestResults>>;
  maxPerPage: number;
  apiResults: null | RealSpeedAPIResult[];
  pageRefreshed: boolean;
}

export interface DeviceLimits {
  deviceDownloadLimitBottomRange: number;
  deviceDownloadLimitTopRange: number;
  isDeviceDownloadLimitHit: boolean;
}

export interface RealSpeedAPIResultTest {
  test: string;
  dtime: string;
  bytesSec: number;
  webBytesSec: number;
  latency: number;
  webLatency: number;
  jitter: number;
  webJitter: number;
  packetLoss: number;
  webMetadata: {
    'device_environment.timezone_offset': number;
    'device_environment.user_agent': string;
    'device_environment.device_full_name'?: string;
    'device_environment.device_manufacturer'?: string;
    'device_environment.device_model'?: string;
    'device_environment.device_name'?: string;
    version: string;
  };
  deviceLimits?: DeviceLimits;
  sourceApplication?: string;
}

export interface RealSpeedAPIResult {
  testId: number;
  tests: RealSpeedAPIResultTest[];
}

export interface RealSpeedResult {
  testId: number;
  tests: Record<string, RealSpeedAPIResultTest>;
}

export interface RealSpeedParsedData {
  metric: string;
  isFirstRow?: boolean;
  dtime: string;
  routerSpeed: string | number;
  deviceSpeed: string | number;
  source: string | null;
  device: string | null;
  limit: string | DeviceLimitResult | null;
}

export interface RealSpeedResultWithMetric extends RealSpeedAPIResultTest {
  metric: string;
  isFirstRow?: boolean;
}

export interface DeviceLimitResult {
  title: string;
  body: string;
  range: string;
  isHit: boolean;
}
export interface DataUsageEvent {
  endpoint: string;
  dataUsed: number;
  metric?: string;
  timePeriod?: string;
}

export interface DeviceLimitTest {
  deviceModel: string;
  deviceName: string;
  limitLower: number;
  limitUpper: number;
  tests: number;
  testsLimitReached: number;
}

export interface DeviceLimitsResults {
  dataUsage: TotalBytesProcessed;
  results: DeviceLimitTest[];
}

interface TotalBytesProcessed {
  totalBytesProcessed: number;
}

export interface UnitMetric {
  group: string;
  key: MetricKeys;
  roundingPrecision: number;
  type: string;
  unit: string;
}

export interface AvailableUnitMetrics {
  metrics: UnitMetric[];
}

export interface InstantTestAvailableTests {
  key: string;
  tt_task?: typeof TT_TASKS;
  label: string;
}
