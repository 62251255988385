<template>
  <div id="app" class="wrapper" @drop="handleDrop">
    <DeprecationBanner
      v-if="isDeprecationBannerEnabled"
      ref="banner"
      data-cy="deprecation-banner"
      :dismissable="true"
      title="common.depricationBanner.title"
      description="common.depricationBanner.description"
    />
    <component
      :is="showSideBarImprovements ? 'NavBarRedesign' : 'NavBar'"
      :show-navbar-search="isConstantCare"
      :authed="authed"
    />
    <LanguageSwitcher />
    <WrapperLayout :has-sidebar="hasSidebar">
      <router-view />
    </WrapperLayout>
    <UpdatesModal
      v-if="isUpdatesModalVisible"
      :is-modal-visible="isUpdatesModalVisible"
    />
    <BaseToast v-model="showToast" data-cy="global-feedback-message">
      <p>{{ toast.content }}</p>
    </BaseToast>
    <GlobalEvents target="window" @resize="computeDeprecationBannerHeight" />
  </div>
</template>

<script>
import { BaseToast } from '@samknows/design-system';
import { EventBus, FeatureFlag } from '@samknows/utils';

import AuthService from '@/common/services/Auth';
import NavBar from '@/common/components/NavBar.vue';
import NavBarRedesign from '@/common/components/NavBarRedesign.vue';
import LanguageSwitcher from '@/common/components/LanguageSwitcher.vue';
import UpdatesModal from '@/common/components/UpdatesModal.vue';
import WrapperLayout from '@/common/components/WrapperLayout.vue';
import { FEATURE_FLAGS } from '@/common/global.interfaces';
import DeprecationBanner from './common/components/DeprecationBanner.vue';

export default {
  metaInfo() {
    const whiteLabelData = window.whitelabelData;
    const whiteLabelledURL =
      whiteLabelData &&
      whiteLabelData.whitelabelled_url &&
      whiteLabelData.whitelabelled_url.includes('meqyas');

    const keyTitle = whiteLabelledURL
      ? 'defaultAppMeqyasTitle'
      : 'defaultAppTitle';
    const defaultAppTitle = this.$t(`common.${keyTitle}`);

    return {
      titleTemplate: `%s - ${defaultAppTitle}`
    };
  },

  components: {
    NavBar,
    LanguageSwitcher,
    UpdatesModal,
    WrapperLayout,
    BaseToast,
    NavBarRedesign,
    DeprecationBanner
  },

  data() {
    return {
      isDeprecationBannerEnabled: FeatureFlag.isEnabled(
        FEATURE_FLAGS.SHOW_DEPRECATION_BANNER
      ),
      toast: {
        content: ''
      },
      showToast: false,
      navbarSearchFlag: false,
      authed: false
    };
  },

  computed: {
    isUpdatesModalVisible() {
      return this.$store.state.featureUpdates.isModalVisible;
    },
    hasSidebar() {
      return this.$route.meta.hasSideBar;
    },
    isConstantCare() {
      return this.$route?.meta?.userArea === 'constant-care';
    },
    showSideBarImprovements() {
      return this.$store.state.menus.isSideBarImprovementsEnabled;
    }
  },

  watch: {
    $route() {
      this.authed = AuthService.userAuthed();
    }
  },

  mounted() {
    EventBus.$on('toast:show', (toast) => {
      this.toast = toast;
      this.showToast = true;
    });

    this.computeDeprecationBannerHeight();
  },

  created() {
    this.$store.commit(
      'menus/isSideBarImprovementsEnabled',
      FeatureFlag.isEnabled(FEATURE_FLAGS.SIDEBAR_IMPROVEMENTS)
    );

    this.authed = AuthService.userAuthed();
    const inCypress = !!window.Cypress;
    if (inCypress) {
      document.body.classList.add('in-cypress');
    }
  },

  methods: {
    handleDrop(e) {
      /* eslint-disable no-empty */
      try {
        const data = JSON.parse(e.dataTransfer.getData('text'));

        if (data.data || data.component) {
          e.preventDefault();
        }
      } catch (err) {}
    },
    computeDeprecationBannerHeight() {
      const bannerHeight = this.$refs.banner?.$el.offsetHeight ?? 0;
      this.$store.commit('menus/deprecationBannerHeight', bannerHeight);
    }
  }
};
</script>
