
import Vue, { defineComponent } from 'vue';
import config from '@/common/config';
import { prefStorage } from '@samknows/utils';
import { persistLocale } from '@/common/helpers/language-switcher';
import { SvgIcon, BaseButton, BaseModal } from '@samknows/design-system';

export default defineComponent({
  name: 'LanguageSwitcher',

  components: {
    SvgIcon,
    BaseButton,
    BaseModal
  },

  data: () => ({
    locales: config.locales
  }),
  computed: {
    activeLocale(): string {
      return Vue.config.lang;
    },
    visible: {
      get(): boolean {
        return this.$store.state.languageSwitcher.visible;
      },
      set(value: boolean) {
        this.$store.commit('languageSwitcher/SET_VISIBILITY', value);
      }
    }
  },
  methods: {
    hideLanguageSwitcher() {
      this.$store.commit('languageSwitcher/SET_VISIBILITY', false);
    },
    changeLocale(locale: string) {
      Vue.config.lang = locale;
      prefStorage.set('locale', locale);

      const zdLocale = config.locales.find(
        (localeItem) => localeItem.code === locale && !!localeItem.zdCode
      );
      const zdLocaleCode = zdLocale ? zdLocale.zdCode : 'en-gb';
      prefStorage.set('locale-hc', zdLocaleCode);

      persistLocale(locale, this.$route.path).then(() => {
        window.location.reload();
      });
    }
  }
});
