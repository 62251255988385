import { Http } from '@samknows/utils';
const zendeskSKApiUrl = 'https://samknows.zendesk.com/api/v2';
const helpCenterApiUrl = `${zendeskSKApiUrl}/help_center`;

const ZendeskAPI = {
  locale: 'en-gb',

  getArticle(id) {
    return Http.request(
      `${helpCenterApiUrl}/${this.locale}/articles/${id}.json`,
      { useAuth: false }
    );
  },

  getArticles(perPage = 100) {
    const requestUrl = `${helpCenterApiUrl}/${this.locale}/articles.json?per_page=${perPage}`;

    return Http.request(requestUrl, { useAuth: false });
  },

  getArticlesForCategory(categoryId, perPage = 100) {
    return Http.request(
      `${helpCenterApiUrl}/${this.locale}/categories/${categoryId}/articles.json?per_page=${perPage}`,
      { useAuth: false }
    );
  },

  getArticlesForSection(sectionId, perPage = 100) {
    return Http.request(
      `${helpCenterApiUrl}/${this.locale}/sections/${sectionId}/articles.json?per_page=${perPage}`,
      { useAuth: false }
    );
  },

  getSection(id) {
    return Http.request(
      `${helpCenterApiUrl}/${this.locale}/sections/${id}.json`,
      { useAuth: false }
    );
  },

  getSectionsForCategory(id) {
    return Http.request(
      `${helpCenterApiUrl}/${this.locale}/categories/${id}/sections.json`,
      { useAuth: false }
    );
  },

  getCategory(id) {
    return Http.request(
      `${helpCenterApiUrl}/${this.locale}/categories/${id}.json`,
      { useAuth: false }
    );
  },

  search(query, page = 1, categoryId, perPage = 10) {
    return Http.request(
      `${helpCenterApiUrl}/articles/search.json?query=${query}&page=${page}&per_page=${perPage}&locale=${this.locale}&category=${categoryId}`,
      { useAuth: false }
    );
  },

  newRequest(data) {
    const name = `${data.firstName} ${data.lastName}`;

    const formData = {
      request: {
        requester: { name, email: data.email },
        subject: data.subject,
        comment: {
          body: data.message
        },
        priority: 'low',
        tags: data.tags
      }
    };
    return Http.request(`${zendeskSKApiUrl}/requests.json`, {
      useAuth: false,
      method: 'POST',
      body: JSON.stringify(formData),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    });
  }
};

export default ZendeskAPI;
