
import { defineComponent, ref, computed } from 'vue';

import { formConfig } from '../../volunteer-form.config';
import { createFaqsLink } from '../../helpers/volunteer.helper';
import { ORGANISATIONS } from '../../constants';

export default defineComponent({
  name: 'BrandedSignupSection',
  props: {
    owner: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const formConfigForOwner = ref(formConfig[props.owner] || {});
    const showBrandedContent = [
      ORGANISATIONS.ACCC,
      ORGANISATIONS.COM_COM,
      ORGANISATIONS.CST,
      ORGANISATIONS.TRA
    ].includes(props.owner as ORGANISATIONS);
    const isMeqyas = props.owner === ORGANISATIONS.CST;
    const studyLogo = computed(() => {
      return formConfigForOwner.value?.branding?.logo;
    });
    const studyName = computed(() => {
      return formConfigForOwner.value?.studyName;
    });
    const studyReportsLink = computed(() => {
      return formConfigForOwner.value?.branding?.reportsLink;
    });
    const studyImage = computed(() => {
      return formConfigForOwner.value?.branding?.image;
    });
    const studyCountry = computed(() => {
      return formConfigForOwner.value?.country;
    });
    const studyFaqsLink = computed((): string => {
      return createFaqsLink(props.owner);
    });

    return {
      showBrandedContent,
      isMeqyas,
      studyLogo,
      studyName,
      studyReportsLink,
      studyImage,
      studyCountry,
      studyFaqsLink
    };
  }
});
