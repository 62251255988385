<template>
  <main class="redirect u-margin-top-12x">
    <div class="heading-container">
      <h1>
        {{ $t('pages.redirect.header') }}
      </h1>
      <p>{{ $t('pages.redirect.description') }}</p>
    </div>

    <ul class="access-selector">
      <li v-if="accessibles.apollo.length" class="access-selector__item">
        <a class="access-selector__link" @click.prevent="goToDataSubs()">
          {{ $t('common.labelDataSubs') }}
          <BaseSpinner
            v-if="spinnerId === 'data-subs'"
            class="access-selector__spinner"
          />
        </a>
      </li>
      <li
        v-for="unit in accessibles.units"
        :key="unit.unitId"
        class="access-selector__item"
      >
        <a class="access-selector__link" @click.prevent="goToWhitebox(unit)">
          {{ $t('pages.redirect.whiteboxNum', { id: unit.unitId }) }}
          <BaseSpinner
            v-if="spinnerId === unit.unitId"
            class="access-selector__spinner"
          />
        </a>
      </li>
    </ul>

    <label class="is-checkbox">
      <input v-model="remember" type="checkbox" />
      {{ $t('pages.redirect.labelRememberSelection') }}
    </label>
  </main>
</template>

<script>
import { BaseSpinner } from '@samknows/design-system';
import { prefStorage } from '@samknows/utils';
import AuthService from '@/common/services/Auth';
import { VIEW_TYPE } from '@/common/helpers/view-types';

export default {
  components: {
    BaseSpinner
  },
  metaInfo() {
    return {
      title: this.$t('pages.redirect.title')
    };
  },
  data() {
    return {
      spinnerId: 0,
      accessibles: prefStorage.get('accessibles'),
      remember: false
    };
  },
  watch: {
    remember() {
      if (!this.remember) {
        prefStorage.set('remember', undefined, true);
      }
    }
  },
  mounted() {
    if (location.hash) {
      location.hash = '';
    }

    this.remember = !!prefStorage.get('remember');
  },
  methods: {
    goToWhitebox(whitebox) {
      this.spinnerId = whitebox.unitId;
      this.goToView('units', whitebox);
    },
    goToDataSubs() {
      this.spinnerId = 'data-subs';

      if (!prefStorage.get('panel')) {
        const sortedPanels = prefStorage
          .get('accessibles')
          .apollo.sort((a, b) =>
            a.front_name.toLowerCase() > b.front_name.toLowerCase() ? 1 : -1
          );

        this.$store.commit('setPanel', sortedPanels[0]);
      }

      this.goToView(VIEW_TYPE.ENTERPRISE, {});
    },
    goToView(type, data) {
      const continueTo = this.$route.query.continue;
      AuthService.goToView(type, data, this.remember, continueTo);
    }
  }
};
</script>

<style lang="scss" scoped>
.redirect {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 600px;
}

.access-selector {
  // Reset list
  padding: 0;
  list-style: none;

  // Design box
  border: 1px solid color(lines);
  border-radius: rounding('1x');
  margin: 1.5em 0;

  &__item:not(:first-child) {
    border-top: 1px solid color(lines);
  }

  &__link {
    display: block;

    padding: 8px;
    color: color(primary-text);
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    &:first-child {
      border-radius: rounding('1x') rounding('1x') 0 0;
    }

    &:last-child {
      border-radius: 0 0 rounding('1x') rounding('1x');
    }
  }
  &__spinner {
    display: inline-block;
  }
}
</style>
