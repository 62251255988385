var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DropdownBubble',{attrs:{"is-visible":_vm.display,"vertical-offset":_vm.verticalOffset,"px-wrapper-right":_vm.pxWrapperRight,"pointer-orientation":_vm.pointerOrientation,"px-pointer-position":_vm.pxPointerPosition},on:{"close":function($event){return _vm.$emit('close')}}},[_c('ul',{staticClass:"account-switcher"},[(_vm.accessibles)?[(_vm.accessibles.apollo.length && _vm.activeView)?_c('li',{staticClass:"account-switcher__item whitebox-item"},[_c('button',{staticClass:"unstyled-button account-switcher__link",class:{
            'account-switcher__link--active': _vm.isEnterpriseView
          },on:{"click":function($event){$event.stopPropagation();return _vm.switchToDataSubs()}}},[_c('span',{staticClass:"sk-icon-data-source",attrs:{"aria-hidden":"true"}}),_vm._v("\n          "+_vm._s(_vm.$t('common.labelDataSubs'))+"\n          "),_c('SvgIcon',{staticClass:"spinner-icon u-margin-left-2x",class:{ 'show-icon': _vm.spinnerId === 'data-subs' },attrs:{"name":"spinner"}})],1)]):_vm._e(),_vm._v(" "),_vm._l((_vm.accessibles.units),function(unit){return _c('li',{key:unit.unitId,staticClass:"account-switcher__item whitebox-item"},[_c('button',{staticClass:"unstyled-button account-switcher__link",class:{
            'account-switcher__link--active':
              unit.unitId == _vm.activeView.data.unitId
          },on:{"click":function($event){$event.stopPropagation();return _vm.switchToWhitebox(unit)}}},[_c('span',{staticClass:"sk-icon-whitebox",attrs:{"aria-hidden":"true"}}),_vm._v("\n          "+_vm._s(_vm.$t('pages.redirect.whiteboxNum', { id: unit.unitId }))+"\n          "),_c('SvgIcon',{staticClass:"spinner-icon u-margin-left-2x",class:{ 'show-icon': _vm.spinnerId === unit.unitId },attrs:{"name":"spinner"}})],1)])})]:_vm._e(),_vm._v(" "),(_vm.activeView && _vm.activeView.type !== 'signup')?_c('hr',{staticClass:"separator"}):_vm._e(),_vm._v(" "),_c('li',{staticClass:"account-switcher__item"},[_c('button',{staticClass:"unstyled-button account-switcher__link",class:{
          'account-switcher__link--updates': _vm.unreadUpdates && _vm.notify
        },on:{"click":function($event){return _vm.$store.commit('featureUpdates/TOGGLE_IS_MODAL_VISIBLE')}}},[_c('span',{staticClass:"sk-icon-bell1",attrs:{"aria-hidden":"true"}}),_vm._v("\n        "+_vm._s(_vm.$t('components.ecosystems.UpdatesModal.header'))+"\n      ")])]),_vm._v(" "),_c('li',{staticClass:"account-switcher__item"},[_c('router-link',{staticClass:"account-switcher__link",attrs:{"to":"/settings/personal-details"}},[_c('span',{staticClass:"sk-icon-knowledge-base",attrs:{"aria-hidden":"true"}}),_vm._v("\n        "+_vm._s(_vm.$t('navigation.personalDetails'))+"\n      ")])],1),_vm._v(" "),_c('li',{staticClass:"account-switcher__item"},[(_vm.isConsumerView && _vm.activeView.data.unitId)?_c('router-link',{staticClass:"account-switcher__link",attrs:{"data-cy":"account-switcher-broadband-details","to":{ name: 'BroadbandDetails' }}},[_c('span',{staticClass:"sk-icon-meany",attrs:{"aria-hidden":"true"}}),_vm._v("\n        "+_vm._s(_vm.$t('navigation.broadbandDetails'))+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('li',{staticClass:"account-switcher__item"},[_c('router-link',{staticClass:"account-switcher__link",attrs:{"to":"/settings/contact"}},[_c('span',{staticClass:"sk-icon-faqs",attrs:{"aria-hidden":"true"}}),_vm._v("\n        "+_vm._s(_vm.$t('navigation.contactUs'))+"\n      ")])],1),_vm._v(" "),_c('li',{staticClass:"account-switcher__item",attrs:{"data-cy":"lang-switcher-link"}},[_c('button',{staticClass:"unstyled-button account-switcher__link",on:{"click":_vm.selectLanguageClicked}},[_c('span',{staticClass:"sk-icon-comment",attrs:{"aria-hidden":"true"}}),_vm._v("\n        "+_vm._s(_vm.$t('navigation.selectLanguage'))+"\n      ")])]),_vm._v(" "),_c('li',{staticClass:"account-switcher__item"},[_c('router-link',{staticClass:"account-switcher__link",attrs:{"to":"/logout"}},[_c('span',{staticClass:"sk-icon-right2",attrs:{"aria-hidden":"true"}}),_vm._v("\n        "+_vm._s(_vm.$t('common.linkLogout'))+"\n      ")])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }