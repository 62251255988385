import AuthService, { AccessiblePanel } from '@/common/services/Auth';
import { prefStorage } from '@samknows/utils';
import viewTypes from '@/common/helpers/view-types';
import { GRANT_SECTION } from '../account/constants';

const PageEnterpriseApisView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/apis/pages/ApisView.vue'
  );

export default [
  {
    path: '/apis',
    component: PageEnterpriseApisView,
    meta: {
      guard: () => {
        if (
          !prefStorage.get<AccessiblePanel>('panel') ||
          !viewTypes.isEnterpriseView
        ) {
          return false;
        }

        return (
          viewTypes.isEnterpriseView &&
          (AuthService.hasPermissions(
            GRANT_SECTION.TEST_MEASUREMENTS,
            'data_api'
          ) ||
            AuthService.hasPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'metadata_api_manage'
            ) ||
            AuthService.hasPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'cpe_api_manage'
            ))
        );
      }
    }
  }
];
