import AuthService from '@/common/services/Auth';
import { RouteConfig } from 'vue-router';
import viewTypes from '@/common/helpers/view-types';
import store from '@/common/store';
import { GRANT_SECTION } from '@/features/account/constants';

const AnomalyInbox = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "anomaly-detection"*/ '@/features/anomaly-detection/pages/AnomalyInbox.vue'
  );
const AnomalyMessage = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "anomaly-detection"*/ '@/features/anomaly-detection/pages/AnomalyMessage.vue'
  );

export default [
  {
    path: '/fault-finder',
    redirect: '/fault-finder/active'
  },
  {
    path: '/fault-finder/:state',
    component: AnomalyInbox,
    name: 'AnomalyInbox',
    meta: {
      hasBackground: true,
      guard() {
        store.dispatch('resetPanelForSection', {
          grantSection: GRANT_SECTION.ANOMALY_DETECTION,
          grantPermission: 'view'
        });
        return (
          viewTypes.isEnterpriseView &&
          AuthService.hasPermissions(GRANT_SECTION.ANOMALY_DETECTION, 'view')
        );
      }
    }
  },
  {
    path: '/fault-finder/collection/:id',
    component: AnomalyMessage,
    name: 'AnomalyMessage',
    props: true,
    meta: {
      hasBackground: true,
      guard() {
        store.dispatch('resetPanelForSection', {
          grantSection: GRANT_SECTION.ANOMALY_DETECTION,
          grantPermission: 'view'
        });
        return (
          viewTypes.isEnterpriseView &&
          AuthService.hasPermissions(GRANT_SECTION.ANOMALY_DETECTION, 'view')
        );
      }
    }
  }
] as RouteConfig[];
