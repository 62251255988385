import SkConfig from '@/common/config';
import { SAML_SUBDOMAIN } from '@/common/helpers/single-sign-on';
import { Http } from '@samknows/utils';

export interface SAMLMetadata {
  login_redirect_url: string;
  logout_redirect_url: string;
  logout_binding: LOGOUT_BINDINGS;
}

export enum LOGOUT_BINDINGS {
  GET = 'HTTP-Redirect',
  POST = 'HTTP-POST'
}

interface SAMLMetadataResponse {
  code: string;
  message: string;
  data: SAMLMetadata;
}

export interface SAMLLogoutPayload {
  code: string;
  message: string;
  data: SAMLLogoutQuery;
}

interface SAMLLogoutQuery {
  querystring: string;
}

export const getSamlMetadata = async (
  subdomain: SAML_SUBDOMAIN
): Promise<SAMLMetadata> => {
  const res: SAMLMetadataResponse = await Http.request(
    `${SkConfig.api.auth}/saml/${subdomain}/metadata`
  );
  const { data, message } = res;

  if (!data) {
    throw new Error(message);
  }

  return data;
};

export const getSamlAuthXml = async (
  subdomain: SAML_SUBDOMAIN
): Promise<string> => {
  const res: string = await Http.request(
    `${SkConfig.api.auth}/saml/${subdomain}/auth`,
    { useAuth: false }
  );

  return res;
};

export const getSamlLogoutPayload = async (
  subdomain: SAML_SUBDOMAIN
): Promise<string | SAMLLogoutPayload> => {
  const res = await Http.request<string | SAMLLogoutPayload>(
    `${SkConfig.api.auth}/saml/${subdomain}/logout`
  );

  return res;
};
