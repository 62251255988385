
import DropdownBubble from '@/common/components/DropdownBubble.vue';
import viewTypes, { VIEW_TYPE } from '@/common/helpers/view-types';
import AuthService, {
  AccessiblePanel,
  Accessibles
} from '@/common/services/Auth';
import { SvgIcon } from '@samknows/design-system';
import { prefStorage } from '@samknows/utils';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    DropdownBubble,
    SvgIcon
  },
  props: {
    display: {
      type: Boolean,
      required: true
    },
    verticalOffset: {
      type: Object,
      required: true
    },
    pxWrapperRight: {
      type: String,
      required: true
    },
    pointerOrientation: {
      type: String,
      required: true
    },
    pxPointerPosition: {
      type: String,
      required: true
    }
  },
  data: () => ({
    activeView: viewTypes.activeView,
    isConsumerView: viewTypes.isConsumerView,
    isEnterpriseView: viewTypes.isEnterpriseView,
    accessibles: prefStorage.get<Accessibles>('accessibles'),
    spinnerId: 0 as string | number,
    notify: prefStorage.get<boolean>('notify-updates', true)
  }),
  computed: {
    unreadUpdates() {
      return this.$store.state.featureUpdates.newUpdates.length;
    }
  },
  methods: {
    showLanguageSwitcher() {
      this.$store.commit('languageSwitcher/SET_VISIBILITY', true);
    },
    switchToDataSubs() {
      this.spinnerId = 'data-subs';

      if (!prefStorage.get<AccessiblePanel>('panel')) {
        const sortedPanels = prefStorage
          .get<Accessibles>('accessibles')
          .apollo.sort((a, b) =>
            a.front_name.toLowerCase() > b.front_name.toLowerCase() ? 1 : -1
          );

        this.$store.commit('setPanel', sortedPanels[0]);
      }

      this.switchToView(VIEW_TYPE.ENTERPRISE, {});
    },
    switchToWhitebox(box) {
      this.spinnerId = box.unitId;
      this.switchToView('units', box);
    },
    switchToView(type, data) {
      AuthService.goToView(type, data);
    },
    selectLanguageClicked() {
      this.showLanguageSwitcher();
    }
  }
});
