import AuthService from '@/common/services/Auth';
import ZeusService from '@/common/services/Zeus';
import viewTypes from '@/common/helpers/view-types';

export const namespaced = true;

export const state = {
  isModalVisible: false,
  newUpdates: []
};

/* eslint-disable no-shadow */
export const mutations = {
  TOGGLE_IS_MODAL_VISIBLE(state) {
    state.isModalVisible = !state.isModalVisible;
  },
  SET_NEW_UPDATES(state, payload) {
    state.newUpdates = payload;
  }
};

export const actions = {
  getUpdatesUnread({ commit }) {
    const tokenPayload = AuthService.getTokenPayload();
    const userId = tokenPayload.sub;
    const updatesType = viewTypes.isConsumerView ? 'consumer' : 'enterprise';

    ZeusService.get(`users/${userId}/feature_updates`, {
      page: 1,
      per_page: 10000,
      sorts: '-id',
      type: updatesType
    }).then((res) => {
      commit('SET_NEW_UPDATES', res.data);
    });
  }
};
