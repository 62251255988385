export const namespaced = true;

const rFS =
  document.body.requestFullscreen ||
  document.body.webkitRequestFullscreen ||
  document.body.mozRequestFullScreen ||
  document.body.msRequestFullscreen;

export const state = {
  canFullScreen: !!rFS
};
