import {
  LOGOUT_BINDINGS,
  SAMLLogoutPayload,
  SAMLMetadata,
  getSamlMetadata
} from '@/common/services/SingleSignOn';

export enum SAML_SUBDOMAIN {
  virginmedia = 'virginmedia',
  googlefiber = 'googlefiber',
  vodafonehungary = 'vodafone-hungary'
}

export enum AUTHENTICATION_METHOD {
  PASSWORD = 'password',
  SAML = 'saml'
}

/**
 * Either returns the value at a query parameter named "host"
 * of if there is no query param with that name, returns the hostname of the url.
 * @param location usually from `window.location`
 */
export const getHostnameFromQueryParams = (location: Location): string => {
  const { search, hostname } = location;
  const queryParams = new URLSearchParams(search);
  const host = queryParams.get('host');
  return host || hostname;
};

/**
 * If the first part of the hostname matches one of the supported SAML provider subdomains,
 * returns the first part of the hostname. If not, returns null
 * @param hostname usually from `window.location.hostname`
 */
export const getSAMLSubdomain = (): SAML_SUBDOMAIN | null => {
  const hostname =
    process.env.VUE_APP_DEPLOY_ENV !== 'production'
      ? getHostnameFromQueryParams(window.location)
      : window.location.hostname;
  const firstHostnamePart = hostname.split('.')[0];
  const isSAMLSubdomain = (Object.values(SAML_SUBDOMAIN) as string[]).includes(
    firstHostnamePart
  );
  if (isSAMLSubdomain) {
    return firstHostnamePart as SAML_SUBDOMAIN;
  }
  return null;
};

export function hasSamlEnabled(): boolean {
  return getSAMLSubdomain() !== null;
}

function createAndPostForm(redirectUrl: string, inputValue: string): void {
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', redirectUrl);

  const XMLField = document.createElement('input');
  XMLField.setAttribute('type', 'hidden');
  XMLField.setAttribute('name', 'SAMLRequest');
  XMLField.setAttribute('value', inputValue);

  form.appendChild(XMLField);

  document.body.appendChild(form);
  form.submit();
}

export async function samlSetup(
  getSamlPayload: (sub: SAML_SUBDOMAIN) => Promise<string | SAMLLogoutPayload>,
  redirectUrlKey: keyof SAMLMetadata
): Promise<void> {
  const subdomain = getSAMLSubdomain();

  if (!subdomain) {
    return;
  }

  const SamlMetadata = await getSamlMetadata(subdomain);
  const redirectUrl = SamlMetadata[redirectUrlKey];
  const logoutBinding = SamlMetadata['logout_binding'];
  const payload = await getSamlPayload(subdomain);

  if (
    redirectUrlKey === 'logout_redirect_url' &&
    logoutBinding === LOGOUT_BINDINGS.GET
  ) {
    const { data } = payload as SAMLLogoutPayload;
    const { querystring } = data;
    window.location.href = `${redirectUrl}?${querystring}`;
  } else {
    const encodedXML = btoa(payload as string);
    createAndPostForm(redirectUrl, encodedXML);
  }
}

export async function getSamlLogoutBinding(): Promise<string> {
  const subdomain = getSAMLSubdomain();

  if (!subdomain) {
    return;
  }

  const SamlMetadata = await getSamlMetadata(subdomain);
  const logoutBinding = SamlMetadata['logout_binding'];
  return logoutBinding;
}
