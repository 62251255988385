
import { defineComponent } from 'vue';
import AuthService from '@/common/services/Auth';
import { BaseStateIndicator } from '@samknows/design-system';

export default defineComponent({
  components: {
    BaseStateIndicator
  },
  metaInfo() {
    return {
      title: this.$t('common.redirecting')
    };
  },
  data() {
    return {
      state: 'loading'
    };
  },

  async created() {
    try {
      this.state = 'loading';
      await AuthService.getUserData();
      AuthService.goToDefaultPath(this.$route);
    } catch {
      this.state = 'error';
    }
  }
});
