<template>
  <div
    class="main-wrapper"
    :class="{
      'main-wrapper--deprecation-banner':
        isDeprecationBannerEnabled && isDeprecationBannerShown,
      'main-wrapper--sidebar-closed-improvements':
        hideSidebar && hasSidebar && showSideBarImprovements,
      'main-wrapper--sidebar-closed':
        hideSidebar && hasSidebar && !showSideBarImprovements,
      'main-wrapper--no-sidebar': !hasSidebar,
      'main-wrapper--with-search': mobileNavbarSearch,
      'main-wrapper--with-background': hasNeutralBackground
    }"
    :style="{ '--banner-height': deprecationBannerHeight + 'px' }"
  >
    <!-- TODO: move this above header and navbar when the navbar doesn't require to use position: absolute anymore -->
    <div v-if="showStaffWarning" class="main-wrapper__banner">
      <SvgIcon name="warning" />
      <p class="u-margin-0x">
        <strong>You are "impersonating" the currently logged in user</strong>
      </p>
    </div>
    <main :class="{ 'container-fluid': useContainer }">
      <div
        class="content-wrapper"
        :class="{
          'content-wrapper--with-footer': showFooter,
          'content-wrapper--with-navbar': showNavbar
        }"
      >
        <slot></slot>
      </div>
    </main>
    <AppFooter v-if="!hideFooter" />
  </div>
</template>

<script>
import { SvgIcon } from '@samknows/design-system';
import { prefStorage, FeatureFlag } from '@samknows/utils';
import AppFooter from '@/common/components/AppFooter.vue';
import { FEATURE_FLAGS } from '../global.interfaces';
export default {
  components: {
    AppFooter,
    SvgIcon
  },
  props: {
    showFooter: {
      type: Boolean,
      default: true
    },
    useContainer: {
      type: Boolean,
      default: true
    },
    hasSidebar: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showStaffWarning: false,
      showNeutralUiImprovements: false
    };
  },
  computed: {
    deprecationBannerHeight() {
      return this.$store.state.menus.deprecationBannerHeight;
    },
    isDeprecationBannerEnabled() {
      return FeatureFlag.isEnabled(FEATURE_FLAGS.SHOW_DEPRECATION_BANNER);
    },
    isDeprecationBannerShown() {
      return this.$store.state.menus.isDeprecationBannerShown;
    },
    showSideBarImprovements() {
      return this.$store.state.menus.isSideBarImprovementsEnabled;
    },
    hideSidebar() {
      return this.$store.state.menus.hideSidebar;
    },
    hideFooter() {
      return this.$route.meta.hideFooter || !this.showFooter;
    },
    hasNeutralBackground() {
      return (
        (this.showNeutralUiImprovements &&
          this.$route.path.includes('/presets')) ||
        this.$route.meta.hasBackground
      );
    },
    showNavbar() {
      return this.$route.query.navbar !== 'false';
    },
    mobileNavbarSearch() {
      return this.$store.state.menus.hasMobileSearchField;
    }
  },
  watch: {
    $route() {
      this.updateShowStaffWarning();
    }
  },
  mounted() {
    this.updateShowStaffWarning();
  },
  async created() {
    this.showNeutralUiImprovements = await FeatureFlag.fetchFlagState(
      FEATURE_FLAGS.NEUTRAL_UI_IMPROVEMENTS_V1
    );
  },
  methods: {
    updateShowStaffWarning() {
      this.showStaffWarning =
        !window.Cypress && prefStorage.get('staff-warning', false);
    }
  }
};
</script>

<style lang="scss" scoped>
$sidebar-width: rem-calc(40px);
$banner-height: var(--banner-height);

.main-wrapper {
  position: relative;
  min-height: $main-height;

  &--deprecation-banner {
    top: $banner-height;
  }

  transition: margin 0.2s ease-in-out;

  &--with-background {
    background: color('neutral-background');
  }

  @include breakpoint(large) {
    margin-left: 22rem;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 22rem;
    }
  }

  &--no-sidebar {
    margin-left: 0;

    [dir='rtl'] & {
      margin-right: 0;
    }
  }

  &--sidebar-closed-improvements {
    @include breakpoint(large) {
      margin-left: calc(#{$sidebar-width} + 1px);

      [dir='rtl'] & {
        margin-left: 0;
        margin-right: calc(#{$sidebar-width} + 1px);
      }
    }
  }

  &--sidebar-closed {
    @include breakpoint(large) {
      margin-left: calc(3.75rem + 1px);

      [dir='rtl'] & {
        margin-left: 0;
        margin-right: calc(3.75rem + 1px);
      }
    }
  }

  &--with-search {
    min-height: $search-main-height;
  }

  &__banner {
    color: color('error');
    display: flex;
    padding: spacing('4x');
    align-items: center;
    justify-content: center;
    border-bottom: $border-normal;
  }
}

.content-wrapper {
  position: relative;

  padding-top: 0.1px; // No margin collapsing

  &--with-footer {
    display: flex;
    flex-direction: column;
    $header-height: 60;
    $footer-height: 65;
    $mobile-footer-height: 75;
    padding-bottom: 1rem;
    min-height: calc(100vh - #{$mobile-footer-height}px - #{$header-height}px);

    &:not(.content-wrapper--with-navbar) {
      min-height: calc(100vh - #{$mobile-footer-height}px);
    }

    @include breakpoint(small) {
      min-height: calc(100vh - #{$footer-height}px - #{$header-height}px);

      &:not(.content-wrapper--with-navbar) {
        min-height: calc(100vh - #{$footer-height}px);
      }
    }
  }
}
</style>
