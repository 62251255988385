import Vue from 'vue';
import { prefStorage } from '@samknows/utils';
import AuthService from '@/common/services/Auth';
import { Grants } from '../global.interfaces';
import {
  getAdditionalPermissions,
  adminGrants,
  getUserType
} from './user-group-tracking.helpers';
import store from '@/common/store';
import viewTypes from './view-types';
import {
  setHeapIdentity,
  setHeapGlobalEventProperties
} from '../services/heapTracking';
import {
  setSentryUserIdentity,
  setSentryTagForGlobalEventProperties
} from '../services/sentryTracking';

/**
 * Check if app is bing used while in CI environments
 * @returns boolean
 */
export function isPipeline(): boolean {
  const isClientSide = typeof window !== 'undefined';
  const inPipeline =
    !isClientSide ||
    window?.Cypress ||
    window?.isChromatic ||
    window?.isStorybook;

  return inPipeline;
}
/**
 * Get list of profile or user properties
 * Those properties should not change within the same session and should very rarely change between logins
 * They relate directly to a user and giving us insight on their user archetype and what they have access to
 *
 * Those properties require the user to be logged in and new values would erase previous values
 * They should be set only once after login
 * @returns Record
 */
export function getProfileProperties(): Record<
  string,
  string | number | boolean
> {
  const userId = AuthService.userId;
  if (!userId) {
    return;
  }
  const viewType = AuthService.getViewTypeAccess();
  // Send org name or id as fallback if getting name failed
  const userOrganisation =
    AuthService.getUserOrganisationName() ||
    AuthService.getUserOrganisationId();
  const hasHomeViewAccess = AuthService.hasHomeViewAccess();
  const hasEnterpriseViewAccess = AuthService.hasEnterpriseViewAccess();
  const whiteboxesAccessList = AuthService.getAccessibleUnitsList();

  const grants: Grants = prefStorage.get('grants');

  let additionalPermissions: string[] | string = getAdditionalPermissions(
    grants,
    adminGrants
  );

  additionalPermissions = additionalPermissions?.length
    ? additionalPermissions.join(',')
    : undefined;
  const userGroup = getUserType(grants);

  const userData = {
    userOrganisation,
    skUserId: userId,
    userGroup: userGroup || 'Not found',
    samknowsEmployee: AuthService.isSkStaff(),
    additionalPermissions,
    viewTypeAccess: viewType,
    hasHomeViewAccess,
    hasEnterpriseViewAccess,
    whiteboxesAccessList: whiteboxesAccessList?.join(',') || undefined,
    userLocale: Vue.config.lang
  };

  return userData;
}

/**
 * Get list of global event properties
 * Those properties are unrelated to a user and can change while the user is using the app
 * They give us insight on usage and what actions are users performing
 *
 * Most should not require the user to be logged in but might only be available once logged in
 * They can be updated as many time as needed so we get the latest value
 * @returns Record
 */
export function getGlobalEventProperties(): Record<
  string,
  string | number | boolean
> {
  const isLoggedIn = AuthService.userAuthed();
  const userPanel = store.state?.panel?.front_name; // e.g. in management suite or analytics
  let viewTypeSelected = viewTypes.isConsumerView
    ? 'Home view'
    : 'Enterprise view';
  // Set view type to undefined when user is not logged in
  viewTypeSelected = isLoggedIn ? viewTypeSelected : undefined;

  // Log permissions for debugging purpose
  const grants: Grants = prefStorage.get('grants');
  const { Apollo = {}, Unit = {} } = grants || {};
  const apolloKeys = Object.keys(Apollo).join(', ') || undefined;
  const unitKeys = Object.keys(Unit).join(', ') || undefined;

  return {
    isLoggedIn,
    viewTypeSelected,
    userPanel,
    apolloPermissions: apolloKeys,
    unitPermissions: unitKeys
  };
}

/**
 * Set user identity for all tracking tools
 *
 * @returns void
 */
export function setUserIdentity(): void {
  setHeapIdentity();
  setSentryUserIdentity();
}

/**
 * Set user global properties for all tracking tools
 *
 * @returns void
 */
export function setGlobalEventProperties(): void {
  setHeapGlobalEventProperties();
  setSentryTagForGlobalEventProperties();
}
