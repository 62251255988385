import ZeusService from '../../services/Zeus';
import { getRestrictedMetricsConfigXenia } from '@/features/unit-tests-view/services/ConsumerConfig';

export const state = {
  countries: [],
  panels: [],
  organisations: [],
  isps: [],
  searchData: {},
  searchOptions: [],
  unit: {},
  consumerConfig: {}
};

/* eslint-disable no-shadow */
export const mutations = {
  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },
  SET_PANELS(state, panels) {
    state.panels = panels;
  },
  SET_ORGANISATIONS(state, organisations) {
    state.organisations = organisations;
  },
  SET_ISPS(state, isps) {
    state.isps = isps;
  },
  SET_SEARCH_DATA(state, searchData) {
    state.searchData = searchData;
  },
  SET_SEARCH_OPTIONS(state, searchOptions) {
    state.searchOptions = searchOptions;
  },
  SET_UNIT(state, unit) {
    state.unit = unit;
  },
  SET_CONSUMER_CONFIG(state, consumerConfig) {
    state.consumerConfig = consumerConfig;
  }
};

export const actions = {
  getCountries({ commit }) {
    ZeusService.get('countries', { per_page: 10000 }).then((res) => {
      commit('SET_COUNTRIES', res.data);
    });
  },
  getPanels({ commit }) {
    return ZeusService.get('panels', {
      per_page: 10000,
      sorts: 'front_name'
    }).then((res) => {
      commit('SET_PANELS', res.data);
    });
  },
  getOrganisations({ commit }) {
    return ZeusService.get('organisations', {
      per_page: 10000,
      sorts: 'name'
    }).then((res) => {
      commit('SET_ORGANISATIONS', res.data);
    });
  },
  getIsps({ commit }) {
    return ZeusService.get('isps', { per_page: 10000, sorts: 'name' }).then(
      (res) => {
        commit('SET_ISPS', res.data);
      }
    );
  },
  getUnit({ commit }, unitId) {
    const getUnitData = ZeusService.get(`units/${unitId}`, {
      include:
        'panel,isp,user,environment,package,location.timezone,address,lmap_config'
    });

    // this config is for CST (Saudi) unit - see SR-21
    const hasRestrictedMetricsConfigXenia =
      getRestrictedMetricsConfigXenia(unitId);

    return Promise.all([getUnitData, hasRestrictedMetricsConfigXenia]).then(
      ([unit, config]) => {
        commit('SET_UNIT', unit.data);
        commit('SET_CONSUMER_CONFIG', config);
      }
    );
  },
  async getConsumerConfig({ commit }, unitId) {
    // this config is for CST (Saudi) unit - see SR-21
    const config = await getRestrictedMetricsConfigXenia(unitId);
    commit('SET_CONSUMER_CONFIG', config);
  }
};
