
import Vue, {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  reactive,
  Ref,
  ref
} from 'vue';

import {
  createSignup,
  handleErrors,
  scrollToTop
} from '@/features/volunteer-signup/services/signup';
import {
  SignupPayload,
  Package,
  Isp,
  Country,
  FormConfig
} from '@/features/volunteer-signup/types';
import {
  PasswordInput,
  BaseButton,
  BaseFormField,
  BaseFormInput,
  BaseFormCheckbox,
  BaseFormSelect,
  BaseIconTooltip,
  BaseAlert,
  BaseFormRadio
} from '@samknows/design-system';
import { formConfig } from '@/features/volunteer-signup/volunteer-form.config';
import { ASYNC_STATE } from '@/common/global.interfaces';
import {
  citiesList,
  createEulaLink,
  ispsForCountry,
  packagesForIsp
} from '@/features/volunteer-signup/helpers/volunteer.helper';
import { heapTrack } from '@/common/services/heapTracking';

export default defineComponent({
  name: 'BasicForm',
  components: {
    BaseButton,
    PasswordInput,
    BaseFormField,
    BaseFormInput,
    BaseFormCheckbox,
    BaseFormSelect,
    BaseIconTooltip,
    BaseAlert,
    BaseFormRadio
  },
  props: {
    ownerId: {
      type: String,
      required: true
    },
    countryId: {
      type: Number,
      default: undefined
    },
    countries: {
      type: Array as PropType<Country[]>,
      required: true
    },
    isp: {
      type: Number,
      default: undefined
    },
    product: {
      type: Number,
      default: undefined
    }
  },
  setup(props, context) {
    const inputs = {
      first_name: ref(null),
      last_name: ref(null),
      email: ref(null),
      mobile_number: ref(null),
      isp_other: ref(null),
      package_other: ref(null),
      post_code: ref(null),
      other: ref(null)
    };
    const isps = ref(undefined);

    const formStatus = ref(ASYNC_STATE.RESOLVED);
    const ispInputDisabled = ref(false);
    const showErrors = ref(false);
    const isPasswordValid = ref(false);
    const errorsList: Ref<Record<string, never>> = ref({});
    const showGenericError = ref(false);

    const formData = reactive({
      owner: props.ownerId,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      password: undefined,
      country: props.countryId || 235,
      region: undefined,
      city: undefined,
      postcode: undefined,
      isp: undefined,
      package: undefined,
      packageOther: undefined,
      ispOther: undefined,
      // hidden field - "honeypot" for bots to avoid spam signups. FEU PR 223
      other: null,
      tos: false,
      locale: Vue.config.lang?.replace('-', '_') ?? 'en_GB',
      // mobile_number is used as Telephone number
      mobile_number: undefined,
      fibre_availability: undefined
    });

    const formConfigForOwner = computed((): FormConfig => {
      return formConfig[props.ownerId] || {};
    });

    const regionsLabel = computed(() => {
      const regionsLabel = formConfigForOwner.value.regionsLabel;

      return regionsLabel ?? 'SignupForm.region';
    });

    const regionsSelectPlaceholder = computed(() => {
      const regionsSelectPlaceholder =
        formConfigForOwner.value.regionsSelectPlaceholder;
      return regionsSelectPlaceholder ?? 'SignupForm.selectYourRegion';
    });

    const ispError = computed(() => {
      return errorsList.value.isp || errorsList.value.isp_other;
    });

    const packageError = computed(() => {
      return errorsList.value.package || errorsList.value.package_other;
    });

    const getIspsForCountry = computed((): Isp[] => {
      return ispsForCountry(formData.country, props.countries);
    });

    const getPackagesForIsp = computed((): Package[] => {
      return packagesForIsp(formData.isp, getIspsForCountry.value);
    });

    const regions = computed(() => {
      const language = formData.locale.split('_')[0];
      const listOfRegions = formConfigForOwner.value.regions?.[language];

      return listOfRegions?.map((region: string) => {
        return {
          name: region
        };
      });
    });

    const showRegion = computed(() => {
      return !!regions.value;
    });
    const cities = computed(() => {
      const language = formData.locale.split('_')[0];
      return citiesList(language, props.ownerId);
    });
    const showCity = computed(() => {
      return !!cities.value;
    });
    const showPostcode = computed(() => {
      return formConfigForOwner.value.showPostcodeField;
    });
    const showFibreAvailability = computed(() => {
      return formConfigForOwner.value.showFibreAvailability;
    });
    const comComFibreLink =
      'https://comcom.govt.nz/regulated-industries/telecommunications/regulated-services/consumer-protections-for-copper-withdrawal/map-of-specified-fibre-areas';
    const showPhoneNumber = computed(() => {
      return formConfigForOwner.value.showPhoneNumberField;
    });

    const eulaLink = computed((): string => {
      return createEulaLink(props.ownerId);
    });
    const isSubmitting = computed(() => {
      return formStatus.value === ASYNC_STATE.LOADING;
    });

    async function formSubmit() {
      // hidden field - "honeypot" for bots to avoid spam signups. FEU PR 223
      if (formData.other !== null) {
        return;
      }

      if (!isPasswordValid.value) {
        showErrors.value = true;
        return;
      }

      formStatus.value = ASYNC_STATE.LOADING;
      showGenericError.value = false;
      errorsList.value = {};

      if (formData.isp !== 0) {
        formData.ispOther = '';
      }

      if (formData.package !== 0) {
        formData.packageOther = '';
      }

      const payload: SignupPayload = {
        ...formData
      };

      if (payload.isp === -1) {
        payload.isp = null;
      }

      if (payload.package === -1) {
        payload.package = null;
      }

      try {
        await createSignup(payload);
        formStatus.value = ASYNC_STATE.RESOLVED;
        scrollToTop();
        context.emit('success');
        heapTrack('SignupSubmitSuccess');
      } catch (error) {
        if (error instanceof Response) {
          formStatus.value = ASYNC_STATE.ERROR;
          const signupErrors = await handleErrors(
            error,
            inputs,
            errorsList.value
          );
          showGenericError.value = signupErrors.showGenericError;
        } else {
          heapTrack('SignupSubmitError', {
            type: 'uncaught'
          });
          showGenericError.value = true;
          console.error(error);
        }
      }
    }

    onBeforeMount(() => {
      if (props.isp) {
        formData.isp = props.isp;
        ispInputDisabled.value = true;
      }
      if (props.product) {
        formData.package = props.product;
      }
    });

    return {
      inputs,
      isps,
      formStatus,
      ispInputDisabled,
      showErrors,
      isPasswordValid,
      errorsList,
      formData,
      eulaLink,
      regionsLabel,
      regionsSelectPlaceholder,
      ispError,
      packageError,
      getIspsForCountry,
      getPackagesForIsp,
      regions,
      showRegion,
      cities,
      showCity,
      showPostcode,
      showFibreAvailability,
      comComFibreLink,
      showPhoneNumber,
      isSubmitting,
      showGenericError,
      formSubmit
    };
  }
});
