import { ActionTree, MutationTree, Module as VuexModule } from 'vuex';
import dayjs from 'dayjs';
import ZeusService from '@/common/services/Zeus';
import { SORTING } from '@/common/global.interfaces';
import { heapTrack } from '@/common/services/heapTracking';

interface SelectedResult {
  unitId: string;
  selectedType: string; // e.g. ICOMS_id,
  selectedTypeName: string; // e.g. ICOMS id
  selectedTypeValue: string;
}

interface UnitSearchState {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  agentsFormData: any;
  formTouched: boolean;
  latestPanelId: number | null;
  searchState: string;
  error: string | undefined;
  page: number;
  perPage: number;
  sort: {
    isNegative: boolean;
    columnName: '';
    direction: string;
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  resultData: any[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  paginationData: Record<string, any>;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  metaSearchResults: any[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  metaSearchResultsInfo: Record<string, any>;
  selectedResult: SelectedResult | null;
}

const initialState = (): UnitSearchState => {
  return {
    agentsFormData: {},
    formTouched: false,
    latestPanelId: null,
    searchState: 'new',
    error: undefined,
    page: 1,
    perPage: 50,
    sort: {
      isNegative: false,
      columnName: '',
      direction: SORTING.NOT_SORTED
    },
    resultData: [],
    paginationData: {},
    metaSearchResults: [],
    metaSearchResultsInfo: {},
    selectedResult: null
  };
};

export const mutations: MutationTree<UnitSearchState> = {
  setAgentsFormData(state, formData) {
    state.agentsFormData = JSON.parse(JSON.stringify(formData));
  },
  setFormTouched(state, formTouched) {
    state.formTouched = formTouched;
  },
  setLatestPanelId(state, panelId) {
    state.latestPanelId = panelId;
  },
  setPage(state, page) {
    state.page = page;
  },
  setResultsData(state, results) {
    state.resultData = results;
  },
  setPaginationData(state, pagination) {
    state.paginationData = pagination;
  },
  setError(state, error) {
    state.error = error;
  },
  setSearchState(state, searchState) {
    state.searchState = searchState;
  },
  setSort(state, sortData) {
    state.sort = sortData;
  },
  setMetaResultsData(state, results) {
    state.metaSearchResults = results;
  },
  setMetaResultsInfoData(state, data) {
    state.metaSearchResultsInfo = data;
  },
  setSelectedResult(state, data) {
    state.selectedResult = data;
  },
  reset(state) {
    const startingState = initialState();
    state.agentsFormData = startingState.agentsFormData;
    state.formTouched = startingState.formTouched;
    state.latestPanelId = startingState.latestPanelId;
    state.searchState = startingState.searchState;
    state.error = startingState.error;
    state.page = startingState.page;
    state.perPage = startingState.perPage;
    state.sort = startingState.sort;
    state.resultData = startingState.resultData;
    state.paginationData = startingState.paginationData;
    state.metaSearchResults = startingState.metaSearchResults;
    state.metaSearchResultsInfo = startingState.metaSearchResultsInfo;
  },
  resetSort(state) {
    state.sort = initialState().sort;
  }
};

const getLastSeenTime = (time: number | null | undefined): string => {
  if (!time) {
    return '-';
  }

  const dayjsTime = dayjs(time * 1000);
  return dayjsTime.fromNow();
};

const actions: ActionTree<UnitSearchState, unknown> = {
  async metaSearch({ commit }, data) {
    const { panelId, searchTerm } = data || {};

    if (!panelId) {
      return;
    }
    commit('setLatestPanelId', panelId);

    const endpoint = 'search/units';
    const params = {
      panelId,
      searchTerm
    };

    ZeusService.get(endpoint, params)
      .then((response) => {
        commit('setMetaResultsData', response.data.results);
        commit('setMetaResultsInfoData', response.data.search);
        commit('setSearchState', 'loaded');
        commit('setError', undefined);
      })
      .catch((error) => {
        commit('setSearchState', 'error');
        commit('setError', error);

        if (error instanceof Error) {
          console.error(error);
        }

        heapTrack('ccSearchResultsError', {
          error,
          searchTerm
        });
      });
  },
  async search({ commit, state }, data) {
    const { panelId, sorting } = data || {};
    const type = `panels/${panelId}/agents`;

    if (!panelId) {
      return;
    }

    const query = {
      page: sorting ? 1 : state.page,
      per_page: sorting ? state.resultData.length : state.perPage,
      sorts: state.sort.isNegative
        ? `-${state.sort.columnName}`
        : state.sort.columnName,
      include: 'lmap_config',
      ...state.agentsFormData
    };

    const searchTerm = query?.email || query?.mac || query?.id;
    commit('setLatestPanelId', panelId);

    ZeusService.get(type, query)
      .then((data) => {
        // transform last_seen times
        const results = data.data.map((item) => {
          item.last_seen = getLastSeenTime(item.last_seen);
          return item;
        });
        const resultsCount = data?.meta?.pagination?.total;

        if (!resultsCount) {
          heapTrack('ccSearchResultsEmpty', {
            count: resultsCount,
            searchTerm
          });
        } else {
          heapTrack('ccSearchResults', {
            count: resultsCount,
            searchTerm
          });
        }

        if (state.page === 1 || sorting) {
          commit('setResultsData', results);
        } else {
          const updatedResults = [...state.resultData, ...results];
          commit('setResultsData', updatedResults);
        }

        commit('setPaginationData', data.meta.pagination);
        commit('setSearchState', 'loaded');
        commit('setError', undefined);
      })
      .catch((error) => {
        commit('setSearchState', 'error');
        commit('setError', error);

        if (error instanceof Error) {
          console.error(error);
        }

        heapTrack('ccSearchResultsError', {
          error,
          searchTerm
        });
      });
  },
  clearResults({ commit }) {
    commit('setResultsData', initialState().resultData);
  },
  resetState({ commit }) {
    commit('reset');
  }
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
} as VuexModule<UnitSearchState, unknown>;
