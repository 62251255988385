var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper",class:{
    'main-wrapper--deprecation-banner':
      _vm.isDeprecationBannerEnabled && _vm.isDeprecationBannerShown,
    'main-wrapper--sidebar-closed-improvements':
      _vm.hideSidebar && _vm.hasSidebar && _vm.showSideBarImprovements,
    'main-wrapper--sidebar-closed':
      _vm.hideSidebar && _vm.hasSidebar && !_vm.showSideBarImprovements,
    'main-wrapper--no-sidebar': !_vm.hasSidebar,
    'main-wrapper--with-search': _vm.mobileNavbarSearch,
    'main-wrapper--with-background': _vm.hasNeutralBackground
  },style:({ '--banner-height': _vm.deprecationBannerHeight + 'px' })},[(_vm.showStaffWarning)?_c('div',{staticClass:"main-wrapper__banner"},[_c('SvgIcon',{attrs:{"name":"warning"}}),_vm._v(" "),_vm._m(0)],1):_vm._e(),_vm._v(" "),_c('main',{class:{ 'container-fluid': _vm.useContainer }},[_c('div',{staticClass:"content-wrapper",class:{
        'content-wrapper--with-footer': _vm.showFooter,
        'content-wrapper--with-navbar': _vm.showNavbar
      }},[_vm._t("default")],2)]),_vm._v(" "),(!_vm.hideFooter)?_c('AppFooter'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"u-margin-0x"},[_c('strong',[_vm._v("You are \"impersonating\" the currently logged in user")])])
}]

export { render, staticRenderFns }