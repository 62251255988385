import AuthService from '@/common/services/Auth';
import ZeusService from '@/common/services/Zeus';
import viewTypes from '@/common/helpers/view-types';

export const namespaced = true;

export const state = {
  address: {
    loading: true
  },
  firstName: null,
  lastName: null,
  email: null,
  unitDetails: {
    loading: false
  },
  unitDetailsError: null,
  packageUpdateStatus: false,
  isChangePasswordSuccess: false
};

export const getters = {
  isp(state) {
    const isp = state.unitDetails.isp;
    return (isp && isp.data) || '-';
  },

  broadbandPackage(state) {
    const broadbandPackage = state.unitDetails.package;
    return (broadbandPackage && broadbandPackage.data) || '-';
  }
};

export const mutations = {
  SET_ADDRESS(state, address) {
    state.address = address;
  },
  SET_FIRST_NAME(state, name) {
    state.firstName = name;
  },
  SET_LAST_NAME(state, name) {
    state.lastName = name;
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  RESET_STATE(state) {
    state.address = {
      loading: true
    };
    state.unitDetails = {
      loading: true
    };
    state.firstName = null;
    state.lastName = null;
    state.email = null;
    state.unitDetailsError = null;
  },

  SET_UNIT_DETAILS(state, unitDetails) {
    state.unitDetails = unitDetails;
  },

  SET_UNIT_DETAILS_ERROR(state, error) {
    state.unitDetailsError = error;
  },

  SET_UPDATE_PACKAGE_STATUS(state, status) {
    state.packageUpdateStatus = status;
  },

  SET_IS_CHANGE_PASSWORD_SUCCESS(state, status) {
    state.isChangePasswordSuccess = status;
  }
};

export const actions = {
  async fetchPersonalDetails({ commit }) {
    if (!AuthService.userAuthed()) {
      return;
    }
    const body = await ZeusService.get(
      `users/${AuthService.getTokenPayload().sub}/personal_information`
    );
    const { first_name, last_name, email } = body.data;

    commit('SET_FIRST_NAME', first_name);
    commit('SET_LAST_NAME', last_name);
    commit('SET_EMAIL', email);
  },

  async fetchUnitDetails({ commit }) {
    const unitId = viewTypes.activeView.data.unitId;
    commit('SET_UNIT_DETAILS_ERROR', null);
    commit('SET_UNIT_DETAILS', {
      loading: true
    });
    try {
      const body = await ZeusService.get(
        `users/${AuthService.getTokenPayload().sub}/accessible-units/${unitId}`,
        {
          include: 'environment,isp,package,address,location,geo_data'
        }
      );
      const unitDetails = body.data;
      const address = unitDetails.address;

      commit('SET_UNIT_DETAILS', unitDetails);
      if (address) {
        commit('SET_ADDRESS', unitDetails.address.data);
      }
    } catch (error) {
      commit('SET_UNIT_DETAILS_ERROR', error);
    }
  },

  async updateUnitDetails({ commit, dispatch }, data) {
    commit('SET_UPDATE_PACKAGE_STATUS', {
      type: '',
      text: ''
    });
    const unitId = viewTypes.activeView.data.unitId;
    const userId = AuthService.getTokenPayload().sub;
    try {
      await ZeusService.update(
        `users/${userId}/accessible-units/${unitId}/location`,
        data
      );
      commit('SET_UPDATE_PACKAGE_STATUS', {
        type: 'success',
        text: 'components.pages.settings.BroadbandDetails.successMessage'
      });
      await dispatch('fetchUnitDetails');
    } catch {
      commit('SET_UPDATE_PACKAGE_STATUS', {
        type: 'danger',
        text: 'components.pages.settings.BroadbandDetails.errorMessage'
      });
    }
  }
};
