import AuthService, { AccessiblePanel } from '@/common/services/Auth';
import { prefStorage } from '@samknows/utils';
import viewTypes from '@/common/helpers/view-types';
import { GRANT_SECTION } from '../account/constants';

const PageEnterpriseDataSub = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/data-subscriptions/pages/DataSubscription.vue'
  );
const PageEnterpriseDataSubFeatureSubs = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/data-subscriptions/pages/DataSubFeatureSubs.vue'
  );
const PageEnterpriseDataSubGroups = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/data-subscriptions/pages/DataSubGroups.vue'
  );
const PageEnterpriseDataSubUsers = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/data-subscriptions/pages/DataSubUsers.vue'
  );
const PageEnterpriseRootView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/RootView.vue'
  );
const PageEnterpriseAgentsView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/AgentsView.vue'
  );
const PageEnterpriseAgentView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/AgentView.vue'
  );
const PageEnterpriseUserView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/UserView.vue'
  );
const PageEnterpriseMetadataView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/MetadataView.vue'
  );
const PageEnterpriseInstantTestApiView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/InstantTestAPIStats.vue'
  );
const PageEnterpriseDataSubsView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/DataSubs.vue'
  );
const PageEnterpriseSwitchPanelView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/SwitchPanel.vue'
  );
const PageEnterprisePackagesView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/PackagesView.vue'
  );
const PageEnterpriseUnitInfoView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/UnitInfo.vue'
  );
const PageEnterpriseUnitDetailsView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/UnitDetails.vue'
  );
const PageEnterpriseCpeView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "management-suite" */ '@/features/management-suite/pages/CPE.vue'
  );

export const adminPermissionRoutes = [
  {
    grantSection: GRANT_SECTION.TEST_MEASUREMENTS,
    grantPermission: 'agents_manage',
    route: 'agents',
    panelSpecific: true
  },
  {
    grantSection: GRANT_SECTION.TEST_MEASUREMENTS,
    grantPermission: 'agents_view',
    route: 'agents',
    panelSpecific: true
  },
  {
    grantSection: 'Owner',
    grantPermission: 'org_super_admin',
    route: 'users'
  },
  {
    grantSection: GRANT_SECTION.TEST_MEASUREMENTS,
    grantPermission: 'metadata_manage',
    route: 'metadata',
    panelSpecific: true
  },
  {
    grantSection: GRANT_SECTION.TEST_MEASUREMENTS,
    grantPermission: 'cpe_subscriptions_manage',
    route: 'agent-activation',
    panelSpecific: true
  },
  {
    grantSection: GRANT_SECTION.TEST_MEASUREMENTS,
    grantPermission: 'agent_owners_manage',
    route: 'users',
    panelSpecific: true
  },
  {
    grantSection: GRANT_SECTION.TEST_MEASUREMENTS,
    grantPermission: 'admin_users_manage',
    route: 'data-subs',
    panelSpecific: true
  },
  {
    grantSection: GRANT_SECTION.TEST_MEASUREMENTS,
    grantPermission: 'instant_test_api_manage',
    route: 'instant-test-api-usage',
    panelSpecific: true
  }
];

export const adminDefaultRoute = (
  panel = prefStorage.get<AccessiblePanel>('panel', {} as AccessiblePanel).pid
): string => {
  const route = adminPermissionRoutes.find((item) => {
    const query = item.panelSpecific ? { panel } : {};
    return AuthService.hasPermissions(
      item.grantSection,
      item.grantPermission,
      query
    );
  });

  if (route) {
    return `/admin/${route.route}`;
  }

  const routeAnotherPanel = adminPermissionRoutes.find((item) => {
    return AuthService.hasPermissions(item.grantSection, item.grantPermission);
  });

  if (routeAnotherPanel) {
    return '/admin/switch-panel';
  }

  return AuthService.defaultPath();
};

export default [
  {
    path: '/admin',
    component: PageEnterpriseRootView,
    redirect: () => adminDefaultRoute(),
    guard: viewTypes.isEnterpriseView && AuthService.adminAccess(),
    children: [
      {
        path: 'agents',
        component: PageEnterpriseAgentsView,
        meta: {
          type: 'search',
          guard: () => viewTypes.isEnterpriseView && AuthService.adminAccess()
        }
      },
      {
        path: 'agent/:agentId',
        component: PageEnterpriseAgentView,
        meta: {
          type: 'single',
          guard: () => viewTypes.isEnterpriseView && AuthService.adminAccess()
        }
      },
      {
        path: 'users',
        component: PageEnterpriseAgentsView,
        meta: {
          type: 'search',
          guard: () => {
            if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
              return false;
            }

            return (
              AuthService.hasPermissions('Owner', 'org_super_admin') ||
              AuthService.hasPermissions(
                GRANT_SECTION.TEST_MEASUREMENTS,
                'agent_owners_manage'
              )
            );
          },
          guardFailure: '/admin'
        }
      },
      {
        path: 'user/:userId',
        component: PageEnterpriseUserView,
        meta: {
          type: 'single',
          guard: () => {
            if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
              return false;
            }

            return (
              AuthService.hasPermissions('Owner', 'org_super_admin') ||
              AuthService.hasPermissions(
                GRANT_SECTION.TEST_MEASUREMENTS,
                'agent_owners_manage'
              ) ||
              AuthService.hasPermissions(
                GRANT_SECTION.TEST_MEASUREMENTS,
                'admin_users_manage'
              )
            );
          }
        }
      },
      {
        path: 'metadata',
        component: PageEnterpriseMetadataView,
        meta: {
          guard: () => {
            if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
              return false;
            }

            return AuthService.hasPanelPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'metadata_manage'
            );
          },
          guardFailure: '/admin',
          type: 'search'
        }
      },
      {
        path: 'instant-test-api-usage',
        component: PageEnterpriseInstantTestApiView,
        name: 'InstantTestAPIStats',
        meta: {
          type: 'search',
          guard: () => {
            if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
              return false;
            }

            return AuthService.hasPanelPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'instant_test_api_manage'
            );
          },
          guardFailure: '/admin'
        }
      },
      {
        path: 'switch-panel',
        component: PageEnterpriseSwitchPanelView,
        meta: {
          type: 'switch-panel',
          guard: () => viewTypes.isEnterpriseView || AuthService.adminAccess()
        }
      },
      {
        path: 'data-subs',
        name: 'AdminDataSubs',
        component: PageEnterpriseDataSubsView,
        meta: {
          type: 'search',
          guard: () => {
            if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
              return false;
            }

            return (
              AuthService.hasPermissions('Owner', 'org_super_admin') ||
              AuthService.hasPermissions(
                GRANT_SECTION.TEST_MEASUREMENTS,
                'admin_users_manage'
              )
            );
          },
          guardFailure: '/admin'
        }
      },
      {
        path: 'packages',
        component: PageEnterprisePackagesView,
        meta: {
          type: 'search',
          guard: () => {
            if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
              return false;
            }

            return AuthService.hasPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'manage_products'
            );
          }
        }
      },

      // Because cpe routes have been renamed to agent-activation
      { path: 'cpe', redirect: 'agent-activation' },
      { path: 'cpe/status', redirect: 'agent-activation/status' },
      { path: 'cpe/log', redirect: 'agent-activation/log' },
      { path: 'cpe/token', redirect: 'agent-activation/token' },

      {
        path: 'agent-activation',
        component: PageEnterpriseCpeView,
        redirect: 'agent-activation/status',
        meta: {
          type: 'search',
          guard: () => {
            if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
              return false;
            }

            return AuthService.hasPanelPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'cpe_subscriptions_manage'
            );
          },
          guardFailure: '/admin'
        },
        children: [
          {
            path: 'status',
            meta: {
              type: 'search',
              guard: () => {
                if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
                  return false;
                }

                return AuthService.hasPanelPermissions(
                  GRANT_SECTION.TEST_MEASUREMENTS,
                  'cpe_subscriptions_manage'
                );
              }
            }
          },
          {
            path: 'log',
            meta: {
              type: 'search',
              guard: () => {
                if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
                  return false;
                }

                return AuthService.hasPanelPermissions(
                  GRANT_SECTION.TEST_MEASUREMENTS,
                  'cpe_subscriptions_manage'
                );
              }
            }
          },
          {
            path: 'token',
            meta: {
              type: 'search',
              guard: () => {
                if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
                  return false;
                }

                return AuthService.hasPanelPermissions(
                  GRANT_SECTION.TEST_MEASUREMENTS,
                  'cpe_api_manage'
                );
              }
            }
          }
        ]
      },
      {
        path: 'data-subs/:dataSubId',
        component: PageEnterpriseDataSub,
        props: true,
        redirect: 'data-subs/:dataSubId/feature-subs',
        meta: {
          guard: () => {
            if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
              return false;
            }

            return (
              AuthService.hasPermissions('Owner', 'org_super_admin') ||
              AuthService.hasPermissions(
                GRANT_SECTION.TEST_MEASUREMENTS,
                'admin_users_manage'
              )
            );
          }
        },
        children: [
          {
            path: 'feature-subs',
            name: 'AdminDataSubFeatureSubs',
            component: PageEnterpriseDataSubFeatureSubs,
            props: true,
            meta: {
              hasBackground: true,
              type: 'single',
              guard: () => {
                if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
                  return false;
                }

                return (
                  AuthService.hasPermissions('Owner', 'org_super_admin') ||
                  AuthService.hasPermissions(
                    GRANT_SECTION.TEST_MEASUREMENTS,
                    'admin_users_manage'
                  )
                );
              }
            }
          },
          {
            path: 'groups',
            name: 'AdminDataSubGroups',
            component: PageEnterpriseDataSubGroups,
            props: true,
            meta: {
              type: 'single',
              hasBackground: true,
              guard: () => {
                if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
                  return false;
                }

                return (
                  AuthService.hasPermissions('Owner', 'org_super_admin') ||
                  AuthService.hasPermissions(
                    GRANT_SECTION.TEST_MEASUREMENTS,
                    'admin_users_manage'
                  )
                );
              }
            }
          },
          {
            path: 'users',
            name: 'AdminDataSubUsers',
            component: PageEnterpriseDataSubUsers,
            props: true,
            meta: {
              type: 'single',
              hasBackground: true,
              guard: () => {
                if (!AuthService.adminAccess() || !viewTypes.isEnterpriseView) {
                  return false;
                }

                return (
                  AuthService.hasPermissions('Owner', 'org_super_admin') ||
                  AuthService.hasPermissions(
                    GRANT_SECTION.TEST_MEASUREMENTS,
                    'admin_users_manage'
                  )
                );
              }
            }
          }
        ]
      }
    ]
  },
  {
    path: '/admin/unit/:unitId',
    component: PageEnterpriseUnitInfoView,
    props: true,
    redirect: '/admin/unit/:unitId/details',
    meta: {
      type: 'single',
      guard: () => {
        return AuthService.adminAccess();
      }
    },
    children: [
      {
        path: 'details',
        name: 'UnitDetails',
        component: PageEnterpriseUnitDetailsView,
        meta: {
          hasBackground: true,
          guard: () => {
            return AuthService.adminAccess();
          }
        }
      }
    ]
  }
];
