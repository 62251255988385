export const namespaced = true;

export const state = {
  menuOpen: false,
  hasSidebar: false,
  hideSidebar: false,
  hasMobileSearchField: false,
  isSideBarImprovementsEnabled: false,
  isDeprecationBannerShown: false,
  deprecationBannerHeight: 0
};

/* eslint-disable no-shadow */
export const mutations = {
  toggleMenu(state, menuOpen) {
    if (typeof menuOpen !== 'undefined') {
      state.menuOpen = menuOpen;
    } else {
      state.menuOpen = !state.menuOpen;
    }
  },
  hasSidebar(state, isMounted) {
    state.hasSidebar = isMounted;
  },
  toggleSidebar(state) {
    state.hideSidebar = !state.hideSidebar;
  },
  isSideBarImprovementsEnabled(state, newState) {
    state.isSideBarImprovementsEnabled = newState;
  },
  isDeprecationBannerShown(state, newState) {
    state.isDeprecationBannerShown = newState;
  },
  deprecationBannerHeight(state, newState) {
    state.deprecationBannerHeight = newState;
  }
};
