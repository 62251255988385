import { GrantPermission, Grants } from '../global.interfaces';

export enum userGroups {
  CONSUMER = 'Consumer',
  CONSTANT_CARE = 'Constant Care',
  MULTIVIEW = 'Multiview',
  ADMIN = 'Admin'
}

export const careAgentGrants = ['agents_view', 'analytics', 'trigger_testing'];

export const careAgentGrantsConstantCareApollo = [
  'analytics',
  'trigger_testing'
];

export const careAgentGrantsConstantCareUnit = ['search', 'test_view'];

export const multiViewGrants = [
  ...careAgentGrants,
  'agents_manage',
  'advanced_analytics',
  'analytics_ui',
  'reports',
  'unit_test_schedules_manage',
  'test_schedules_manage'
];

export const adminGrants = [
  ...multiViewGrants,
  'admin_users_manage',
  'agent_owners_manage',
  'metadata_manage',
  'manage_products'
];

export const matchesAllGrants = (
  grantsObj: Record<string, GrantPermission[]>,
  grantsToMatch: string[]
): boolean => {
  if (!grantsObj) {
    return false;
  }

  const grants = Object.keys(grantsObj);
  if (!grants || grants.length < 1 || !grantsToMatch) {
    return false;
  }

  return grantsToMatch.every((key) => grants.includes(key));
};

function isConsumer(grants: Grants = {}): boolean {
  const hasOnlyOneSet = Object.keys(grants)?.length === 1;
  const containsAgentGrantSet = grants.hasOwnProperty('Agent');

  return hasOnlyOneSet && containsAgentGrantSet;
}

export const getUserType = (grants: Grants): string => {
  if (!grants) {
    return '';
  }
  const { Apollo, Unit } = grants || {};

  const constantCareUnitUser = (): userGroups | string => {
    const unitMatches = matchesAllGrants(Unit, careAgentGrantsConstantCareUnit);
    return unitMatches ? userGroups.CONSTANT_CARE : '';
  };

  const consumerUser = isConsumer(grants) ? userGroups.CONSUMER : '';

  const careAgentUser = matchesAllGrants(Apollo, careAgentGrants)
    ? userGroups.CONSTANT_CARE
    : '';
  const multiViewUser = matchesAllGrants(Apollo, multiViewGrants)
    ? userGroups.MULTIVIEW
    : '';
  const adminUser = matchesAllGrants(Apollo, adminGrants)
    ? userGroups.ADMIN
    : '';

  return (
    adminUser ||
    multiViewUser ||
    constantCareUnitUser() ||
    careAgentUser ||
    consumerUser ||
    ''
  );
};

export const getAdditionalPermissions = (
  grants: Grants,
  permissionKeys: string[]
): string[] | null => {
  const { Apollo } = grants || {};

  if (!Apollo || !permissionKeys) {
    return null;
  }

  const apolloKeys = Object.keys(Apollo);
  if (!apolloKeys || apolloKeys.length < 1) {
    return null;
  }

  return apolloKeys.filter((key) => {
    const match = permissionKeys.includes(key);

    if (!match) {
      return key;
    }
  });
};
