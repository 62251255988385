import {
  getDateTimeDifference,
  getToday,
  TIME_MEASUREMENTS
} from '@/common/helpers/date-utils';
import dayjs from 'dayjs';
import Vue from 'vue';

export const getTimeSinceOnline = (datetime?: dayjs.Dayjs): string => {
  let offlineMessage = Vue.t(
    'pages.consumerDashboard.offlineStateSubTitleNoDurationUpdate'
  );
  if (!datetime) {
    return offlineMessage;
  }

  const now = getToday();

  const weeksSince = getDateTimeDifference(
    now,
    datetime,
    TIME_MEASUREMENTS.WEEK
  );
  const daysSince = getDateTimeDifference(now, datetime, TIME_MEASUREMENTS.DAY);
  const hoursSince = getDateTimeDifference(
    now,
    datetime,
    TIME_MEASUREMENTS.HOUR
  );
  const minutesSince = getDateTimeDifference(
    now,
    datetime,
    TIME_MEASUREMENTS.MINUTE
  );

  // very edge case but could happen when looking at agents in other timezones
  const lastOnlineNotInPast =
    weeksSince < 0 || daysSince < 0 || hoursSince < 0 || minutesSince < 0;
  if (lastOnlineNotInPast) {
    return '';
  }

  let durationText = '';
  switch (true) {
    case weeksSince > 4:
      // 'over a month'
      durationText = Vue.t('pages.consumerDashboard.offlineStateOverAMonth');
      break;
    case weeksSince === 1:
      // `over a week`
      durationText = Vue.t('pages.consumerDashboard.offlineStateOneWeek');
      break;
    case weeksSince > 1:
      // `over X weeks`
      durationText = Vue.t('pages.consumerDashboard.offlineStateWeeks', {
        count: weeksSince
      });
      break;
    case daysSince === 1:
      //`over a day`
      durationText = Vue.t('pages.consumerDashboard.offlineStateOneDay', {
        count: daysSince
      });
      break;
    case daysSince > 1:
      //`over X days`
      durationText = Vue.t('pages.consumerDashboard.offlineStateDays', {
        count: daysSince
      });
      break;
    case hoursSince === 1:
      // `over an hour`
      durationText = Vue.t('pages.consumerDashboard.offlineStateOneHour', {
        count: hoursSince
      });
      break;
    case hoursSince > 1:
      // `over X hours`
      durationText = Vue.t('pages.consumerDashboard.offlineStateHours', {
        count: hoursSince
      });
      break;
    case minutesSince === 1:
      // `over 1 minute`
      durationText = Vue.t('pages.consumerDashboard.offlineStateOneMinute');
      break;
    case minutesSince > 0:
      // `over X mins`
      durationText = Vue.t('pages.consumerDashboard.offlineStateMinutes', {
        count: minutesSince
      });
      break;
    default:
      durationText = '';
  }

  if (durationText) {
    offlineMessage = Vue.t('pages.consumerDashboard.offlineStateTitleUpdate', {
      duration: durationText
    });
  }

  return offlineMessage;
};
