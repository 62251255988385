import AuthService from '@/common/services/Auth';
import {
  isPipeline,
  getGlobalEventProperties,
  getProfileProperties
} from '@/common/helpers/tracking-utils';

export function initiateHeap(): void {
  if (isPipeline()) {
    return;
  }
  const appId = process.env.VUE_APP_HEAP_ID;

  window?.heap?.load(appId);
}

export function setHeapIdentity(): void {
  const userId = AuthService.userId;
  if (isPipeline() || !userId) {
    return;
  }
  const profileProperties = getProfileProperties();
  window?.heap?.identify(userId);
  window?.heap?.addUserProperties(profileProperties);
}

export function setHeapGlobalEventProperties(): void {
  if (isPipeline()) {
    return;
  }
  const eventProperties = getGlobalEventProperties();

  window?.heap?.addEventProperties(eventProperties);
}

/**
 * Track an event via Heap. Heap auto track most events so this should only be used for event that can't be tracked automatically
 * This can be logs, sending extra data from APIs, hover events
 *
 * Note: Currently, heap.track calls cannot be named click, change, submit as it clashes with internal variables.
 * @param  {string} eventName
 * @param  {Record<string, unknown>} properties?
 * @returns void
 */
export function heapTrack(
  eventName: string,
  properties?: Record<string, unknown>
): void {
  if (isPipeline()) {
    return;
  }
  window?.heap?.track(eventName, properties);
}

/**
 * This method should be used typically when a user logs out of an application on a device
 * that might be shared with others who use the application,
 * or when your application has the concept of a user switch applied
 * This is common for support teams who log in or "masquerade" as their customers
 *
 * @returns void
 */
export function resetHeapIdentity(): void {
  if (isPipeline()) {
    return;
  }
  window?.heap?.resetIdentity?.(); // TODO CH-1231 the ?.() is here to suppress errors where sometimes heap gets loaded after auth. It should be removed as part of this ticket.
}
