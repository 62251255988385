import { Store } from 'vuex';
import { getCurrentInstance } from 'vue';

// "any" is required here to provide a generic interface. Replace with "unknown" if we use strict mode.
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function useStore<S = any>(): Store<S> {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('must be called in setup');
  }

  return vm.proxy.$store;
}
