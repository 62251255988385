import {
  ActionTree,
  GetterTree,
  MutationTree,
  Module as VuexModule
} from 'vuex';

import { ASYNC_STATE } from '@/common/global.interfaces';

export interface DataBuilderState {
  status: ASYNC_STATE;
}

const moduleState = (): DataBuilderState => ({
  status: ASYNC_STATE.RESOLVED
});

const mutations: MutationTree<DataBuilderState> = {
  setStatus(state, status: ASYNC_STATE) {
    state.status = status;
  }
};

const actions: ActionTree<DataBuilderState, unknown> = {};

const getters: GetterTree<DataBuilderState, unknown> = {};

export default {
  namespaced: true,
  state: moduleState,
  mutations,
  actions,
  getters
} as VuexModule<DataBuilderState, unknown>;
