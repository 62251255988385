
import { defineComponent } from 'vue';
import { BaseSpinner, BaseStateIndicator } from '@samknows/design-system';

import { samlSetup } from '@/common/helpers/single-sign-on';
import { getSamlAuthXml } from '@/common/services/SingleSignOn';

export default defineComponent({
  components: {
    BaseSpinner,
    BaseStateIndicator
  },
  metaInfo() {
    return {
      title: this.$t('common.redirecting')
    };
  },
  data() {
    return {
      hasError: false
    };
  },
  async created() {
    try {
      await samlSetup(getSamlAuthXml, 'login_redirect_url');
    } catch {
      this.hasError = true;
    }
  }
});
