import AuthService from '@/common/services/Auth';
import viewTypes from '@/common/helpers/view-types';

const UserSettings = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/SettingsView.vue'
  );
const BroadbandDetails = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/BroadbandDetails.vue'
  );
const UpdateBroadbandDetails = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/UpdateBroadbandDetails.vue'
  );
const SettingsPersonalDetails = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/PersonalDetails.vue'
  );
const SettingsName = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/NameView.vue'
  );
const SettingsEmailAddress = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/EmailAddress.vue'
  );
const SettingsPassword = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/PasswordView.vue'
  );
const SettingsAddress = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/AddressView.vue'
  );
const SettingsEmailPrefs = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/EmailPreferences.vue'
  );
const SettingsPublicEmailPrefs = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/PublicEmailPreferences.vue'
  );
const PageContact = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "settings" */ '@/features/settings/pages/ContactName.vue'
  );

export default [
  // Public page to unsubscribe from emails
  {
    path: '/email-preferences',
    component: SettingsPublicEmailPrefs
  },
  {
    path: '/settings',
    component: UserSettings,
    children: [
      {
        path: 'broadband-details',
        name: 'BroadbandDetails',
        component: BroadbandDetails,
        meta: {
          hasSideBar: true,
          guard: () => AuthService.userAuthed() && viewTypes.isConsumerView
        }
      },
      {
        path: 'broadband-details/update',
        name: 'UpdateBroadbandDetails',
        component: UpdateBroadbandDetails,
        meta: {
          hasSideBar: true,
          guard: () =>
            AuthService.userAuthed() &&
            AuthService.hasPermissions('Agent', 'unit_manage') &&
            viewTypes.isConsumerView
        }
      },
      {
        path: 'personal-details',
        component: SettingsPersonalDetails,
        meta: {
          hasSideBar: true,
          guard: () => AuthService.userAuthed()
        }
      },
      {
        path: 'personal-details/name',
        component: SettingsName,
        meta: {
          hasSideBar: true,
          guard: () => AuthService.userAuthed()
        }
      },
      {
        path: 'personal-details/email',
        component: SettingsEmailAddress,
        meta: {
          hasSideBar: true,
          guard: () => AuthService.userAuthed()
        }
      },
      {
        path: 'personal-details/password',
        component: SettingsPassword,
        meta: {
          hasSideBar: true,
          guard: () => AuthService.userAuthed()
        }
      },
      {
        path: 'personal-details/address',
        name: 'SettingsAddress',
        component: SettingsAddress,
        meta: {
          hasSideBar: true,
          guard: () => AuthService.userAuthed() && viewTypes.isConsumerView
        }
      },
      {
        path: 'email-preferences',
        component: SettingsEmailPrefs,
        meta: {
          hasSideBar: true,
          guard: () => AuthService.userAuthed()
        }
      },
      {
        path: 'my-whitebox',
        redirect: '/dashboard/agent-details'
      },
      {
        path: 'contact',
        component: PageContact,
        name: 'PageContact',
        meta: {
          hasSideBar: true,
          guard: () => AuthService.userAuthed()
        }
      },
      {
        path: '/',
        redirect: '/settings/personal-details',
        guard: () => AuthService.userAuthed()
      }
    ]
  }
];
