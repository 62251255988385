import type {
  AnalyticsDataList,
  AnalyticsFormData,
  MeasurementBuilderFormData,
  Metric,
  MetricDataPoint
} from '@/common/global.interfaces';
import { CHART_TYPE, MetricKeys } from '@/common/global.interfaces';
import {
  AnalyticsDataCollection,
  AnalyticsRequestWrapper
} from '@/features/analytics/services/core';
import type {
  Dashboard,
  DashboardChildren,
  DashboardContents
} from '@/features/dashboard/types';

import viewTypes from '@/common/helpers/view-types';
import { AccessiblePanel } from '@/common/services/Auth';
import type { Preset } from '@/common/services/presets/types';
import store from '@/common/store';
import { execOnChildren } from '@/features/dashboard/helpers/utils';
import { prefStorage } from '@samknows/utils';
import cloneDeep from 'lodash.clonedeep';
import Vue from 'vue';
import { titleForSeries } from './data-visualisations-utils';
import splitSort from './split-sort';

export const enhancedMetadata = [
  'cross_traffic.rx_avg_bps',
  'cross_traffic.rx_peak_bps',
  'cross_traffic.tx_avg_bps',
  'cross_traffic.tx_peak_bps',
  'device_environment.carrier_name',
  'device_environment.iso_country_code',
  'device_environment.manufacturer',
  'device_environment.model',
  'device_environment.operating_system_version',
  'device_environment.test_mode',
  'global_metadata.app_version',
  'network.is_failover',
  'network.is_roaming',
  'network.state',
  'network.subtype_name',
  'telephony.cell_signal.asu',
  'telephony.cell_signal.cell_identity',
  'telephony.cell_signal.physical_cell_identity',
  'telephony.cell_signal.bandwidth',
  'telephony.cell_signal.signal_level',
  'telephony.cell_signal.absolute_rf_channel_number',
  'telephony.cell_signal.base_station_identity_code',
  'telephony.cell_signal.location_area_code',
  'telephony.cell_signal.cell_identity_id',
  'telephony.cell_signal.primary_scrambling_code',
  'telephony.cell_signal.network_id',
  'telephony.cell_signal.system_id',
  'telephony.cell_signal.latitude',
  'telephony.cell_signal.longitude',
  'telephony.cellular_technology',
  'telephony.connection_type',
  'telephony.cell_signal.mobile_network_code',
  'telephony.cell_signal.mobile_country_code',
  'telephony.cell_signal.signal_strength',
  'telephony.cell_signal.received_signal_power',
  'telephony.cell_signal.channel_quality_indicator',
  'telephony.cell_signal.tracking_area_code',
  'telephony.cell_signal.received_signal_noise_ratio',
  'telephony.cell_signal.received_signal_quality',
  'memory.app_used',
  'memory.device_free',
  'memory.device_used',
  'beginning.location.lat',
  'beginning.location.lon',
  'end.location.lat',
  'end.location.lon',
  'location.lat',
  'location.lon'
];

export function metricIsAggregateByAgent(metric: Metric): 'user' | 'always' {
  if (!metric) {
    return 'user';
  }

  if (
    metric.key.startsWith('vcop_') ||
    metric.key.startsWith('consistency_') ||
    metric.key.startsWith('accc_tbps_') ||
    metric.key.startsWith('short_term_reliability') ||
    metric.key.startsWith('perc_above_90_evening_best_') ||
    metric.key.startsWith('disconnection_')
  ) {
    return 'always';
  }

  return 'user';
}

export function realTypeToUsefulType(type: string): string {
  const usefulType: Record<string, string> = {
    Average: 'mean',
    OkFailPercentage: 'failure_rate',
    FailureRatePercentage: 'failure_rate',
    VcopMax: 'value',
    VcopNormallyAvailableLower: 'value',
    VcopNormallyAvailableUpper: 'value',
    EightyTime: 'value',
    SeventyTime: 'value',
    SixtyTime: 'value',
    EightyTimePercentageOfAdvertised: 'mean',
    SeventyTimePercentageOfAdvertised: 'mean',
    SixtyTimePercentageOfAdvertised: 'mean',
    AcccTypicalBusyPeriodSpeed: 'value',
    RequiredUploadSpeedPercentage: 'value',
    RequiredDownloadSpeedPercentage: 'value',
    RequiredLatencyPercentage: 'value',
    SuccessRatePercentage: 'success_rate',
    PercentageBest: 'mean',
    PercentageBestFailed: 'mean',
    ShortTermReliabilityThresholded: 'value',
    ShortTermReliabilityUnthresholded: 'value',
    PercentageUnitsAboveNinetyEveningDailyBest: 'mean',
    WebTestAverage: 'mean',
    DisconnectionCount: 'value',
    DisconnectionDuration: 'value',
    Sum: 'value'
  };
  return usefulType[type] || type;
}

export function getRequestData(
  formData: MeasurementBuilderFormData
): AnalyticsFormData {
  const COMCAST_USAGE_PANEL_ID = 313;
  const COX_USAGE_PANEL_ID = 557;
  let chartType = formData.chartType;

  if (['boxplot', 'column', 'column_stacked'].includes(formData.chartType)) {
    chartType = CHART_TYPE.AGGREGATE;
  } else if (formData.chartType === 'raw_mobile') {
    chartType = CHART_TYPE.RAW_MAP;
  } else if (formData.chartType === 'geo_heatmap') {
    chartType = CHART_TYPE.LAT_LON;
  }

  const panel: AccessiblePanel = prefStorage.get('panel');

  const includes = [];

  if (
    formData.enhancedMetadata &&
    ['raw', 'raw_mobile'].includes(formData.chartType)
  ) {
    includes.push(...enhancedMetadata);
  }

  let agentAggregation = formData.agent_agg;

  if (['raw', 'raw_mobile', 'geo_heatmap'].includes(formData.chartType)) {
    agentAggregation = false;
  }

  return Object.assign({}, formData, {
    // Just send key, not unit too
    metric: formData.metrics[0].key,
    metrics: formData.metrics.map(({ key }) => key).filter((metric) => metric),

    // The front-end supports more chart types than the backend
    chartType,
    actualChartType: formData.chartType,
    timeAggregation: ['boxplot', 'column', 'column_stacked', 'cdf'].includes(
      formData.chartType
    )
      ? 'total'
      : formData.timeAggregation,

    // Remove null values from split array
    splits:
      chartType === 'map'
        ? [`geo_${formData.mapAggregation}`]
        : formData.splits.filter((split) => split && split !== 'metric'),

    // Only request split data when it's available
    splitData: formData.metrics[0].can_split ? formData.splitData : false,

    includes,

    // Send normalised: false for home users and mobile data and comcast data usage panel
    normalised:
      viewTypes.isEnterpriseView &&
      panel.type === 'unit' &&
      formData.panel !== COMCAST_USAGE_PANEL_ID &&
      formData.panel !== COX_USAGE_PANEL_ID
        ? formData.normalised
        : false,

    agent_agg: agentAggregation,

    // Use correct schema for filters
    filters: formData.filters.map((filter) => {
      if (filter.id === 'advanced_sample_count') {
        let id;
        const filterValues = filter.filterValues.slice();

        if (filter.pointOrAgent === 'point') {
          id = 'sample_count';
        } else if (filter.input === 'series') {
          id = 'selected_period_sample_count';
        } else if (filter.affect === 'series') {
          id = 'all_results_sample_count';
          filterValues.push(filter.bucketThreshold);
        } else {
          id = 'time_period_sample_count';
        }

        return {
          id,
          filterType: -1, // for the all_results_sample_count filter
          filterValues
        };
      }

      return {
        id: filter.id,
        filterType: filter.filterType,
        filterValues: filter.filterValues.map((value) => {
          if (typeof value === 'object' && value !== null) {
            return value.id;
          }

          if (filter.meta.type === 'int') {
            return Number(value);
          }

          return value;
        })
      };
    })
  });
}

export function getRequestDataArray(
  formDataArray: MeasurementBuilderFormData[]
): AnalyticsRequestWrapper[] {
  return formDataArray.reduce((acc, formData) => {
    if (formData.metrics.length > 1) {
      const linkedBy = Symbol();
      const requests = formData.metrics.map((metric) => ({
        formData,
        requestData: getRequestData({
          ...formData,
          metric,
          metrics: [metric]
        }),
        linkedBy
      }));

      return acc.concat(requests);
    }

    const requestData = getRequestData(formData);
    return acc.concat({ formData, requestData });
  }, []);
}

function getMetricValue(point: MetricDataPoint): number {
  if (typeof point.mean !== 'undefined') {
    return point.mean;
  }

  if (typeof point.failure_rate !== 'undefined') {
    return point.failure_rate;
  }

  if (typeof point.success_rate !== 'undefined') {
    return point.success_rate;
  }

  if (typeof point.value !== 'undefined') {
    return point.value;
  }

  if (typeof point.metricValue !== 'undefined') {
    return point.metricValue;
  }

  throw new Error('Metric type not supported');
}

export function transformData(
  data: AnalyticsRequestWrapper[]
): AnalyticsRequestWrapper[] {
  const transformed = data.map(transformDatum);
  return transformed;
}

function isAGeographicalMap(data: unknown): data is AnalyticsDataCollection {
  return typeof data === 'object' && Object.hasOwn(data, 'features');
}

/**
 * Takes an AnalyticsRequestWrapper containing a successful Analytics API
 * response and returns it mutated-in-place to meet the requirements of Front-end
 * data visualisation
 */
export function transformDatum(
  data: AnalyticsRequestWrapper
): AnalyticsRequestWrapper {
  const { responseData, requestData, formData } = data;

  // If it's not an array, the data is likely for chart that is a Geographical Map
  if (!Array.isArray(responseData.data)) {
    if (isAGeographicalMap(responseData.data)) {
      // TODO: Do not the store here as it can create unexpected behavior
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] store.state should have typings for the sub-states
      if ((store.state as any).analytics.hideInsignificant) {
        responseData.data.features = responseData.data.features.filter(
          (feature) => feature.properties.agentCount >= 5
        );
      }

      responseData.data.features.forEach((feature) => {
        if (feature.type !== 'Feature') {
          console.warn('non-Feature type found, not recursive yet');
          return;
        }

        const point = feature.properties;
        point.metricValue = getMetricValue(point);

        if (feature.properties.percentiles) {
          feature.properties.median = feature.properties.percentiles['50'];
          delete feature.properties.percentiles;
        }
      });
    }

    return { ...data, responseData };
  }

  responseData.data = responseData.data.map((series) => {
    if (!series.metricData) {
      return series;
    }

    series.metricData = series.metricData.map((point: MetricDataPoint) => {
      if (point.percentiles && requestData.chartType !== 'cdf') {
        for (const key of Object.keys(point.percentiles)) {
          if (key === '50') {
            point.median = point.percentiles[key];
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            point[`perc_${key}`] = point.percentiles[key];
          }
        }

        delete point.percentiles;
      }

      if (!['cdf', 'lat_lon'].includes(requestData.chartType)) {
        point.metricValue = getMetricValue(point);
      }

      // Work around a bug in the android app: metricValue shouldn't be 0
      if (
        requestData.metric === 'mobile_signal_strength' &&
        point.metricValue === 0
      ) {
        point.metricValue = null;
      }

      return point;
    });

    if (formData && formData.chartType === 'raw_mobile') {
      series.metricData = series.metricData.filter((point) => {
        const lat = Number(point['location.lat']);
        const lon = Number(point['location.lon']);

        if (lat === -1 && lon === -1) {
          return false;
        }

        return !(Math.abs(lat) > 90 || Math.abs(lon) > 180);
      });
    }

    return series;
  });

  return { ...data, responseData };
}

// Transform the data so that it's split by split, not by metric
export function transformMultiMetricResponse(
  data: Record<string, AnalyticsDataList[]>,
  formData: AnalyticsFormData
): Record<string, unknown> {
  const metrics = Object.keys(data);

  const tableObject: Record<string, Record<string, unknown>> = {};

  metrics.forEach((metric) => {
    const metricObj = data[metric];

    metricObj.forEach((item) => {
      if (item.splitGroup) {
        item.name = item.splitGroup.reduce((acc, cur) => {
          let splitLabelCur = cur.splitLabel;
          if (!cur.splitLabel || cur.splitLabel === 'NULL') {
            splitLabelCur = Vue.t('common.other');
          }
          acc += `${splitLabelCur} - `;
          return acc;
        }, '');
        item.name = item.name.substring(0, item.name.length - 3);
      } else {
        item.name = 'data';
      }

      // flatten metric percentiles object
      item.metricData = item.metricData.map((point) => {
        if (point.percentiles) {
          for (const key of Object.keys(point.percentiles)) {
            if (key === '50' && formData.chartType !== 'cdf') {
              point.median = point.percentiles[key];
            } else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              point[`perc_${key}`] = point.percentiles[key];
            }
          }

          delete point.percentiles;
        }

        point.metricValue = getMetricValue(point);

        return point;
      });

      if (!tableObject[item.name]) {
        tableObject[item.name] = {};
      }
      tableObject[item.name][metric] = item.metricData[0];
      tableObject[item.name].splitGroup = item.splitGroup;
    });
  });

  const sortedKeys = splitSort(Object.keys(tableObject));

  const sortedTableObject: Record<string, unknown> = {};

  sortedKeys.forEach((key: string) => {
    sortedTableObject[key] = tableObject[key];
  });

  return sortedTableObject;
}

export function consolidateResponses(
  data: AnalyticsRequestWrapper[],
  formData: MeasurementBuilderFormData[],
  isLandingDashboard?: boolean
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add return types
): any[] {
  // Merge multiple metric responses together
  let clone = cloneDeep(data);
  clone = clone.reduce((responses, response) => {
    const { formData, requestData, responseData, linkedBy } = response;
    if (linkedBy) {
      const linkedTo = responses.find((res) => res.linkedBy === linkedBy);

      const metric = requestData.metrics[0];

      if (!Array.isArray(responseData.data)) {
        return responses;
      }

      responseData.data.forEach((dataset) => {
        let position = 0;
        if (dataset.splitGroup) {
          const metricPosition = formData.splits.indexOf('metric');

          position = dataset.splitGroup.findIndex(
            ({ splitType }) =>
              formData.splits.indexOf(splitType) > metricPosition
          );

          if (position === -1) {
            position = dataset.splitGroup.length;
          }
        } else {
          dataset.splitGroup = [];
        }

        // TODO: This was an easy way to make sure the landing dashboard had series titles,
        // in time for alpha release.
        // This should be reconsidered / refactored where appropriate, post alpha.
        if (isLandingDashboard) {
          dataset.splitGroup.splice(position, 0, {
            splitType: 'metric',
            splitIdentifier: metric as MetricKeys,
            splitLabel: Vue.t(
              `metrics.${dataset.metricMetadata.metricKey}.name`
            )
          });
        } else {
          dataset.splitGroup.splice(position, 0, {
            splitType: 'metric',
            splitIdentifier: metric as MetricKeys,
            splitLabel: Vue.t(`metrics.${metric}.name`)
          });
        }
      });

      if (linkedTo) {
        linkedTo.requestData.metrics.push(metric);
        linkedTo.responseData.data.push(...responseData.data);

        // No concat here - data has been added to the previous series
        return responses;
      }
    }

    return responses.concat(response);
  }, []);

  // Collapse array of objects of arrays into array
  return clone.reduce((seriesSet, series, i) => {
    let newSeries;
    if (Array.isArray(series.responseData.data)) {
      newSeries = series.responseData.data.map((dataset) => ({
        ...series,
        ...dataset,
        responseData: undefined,
        series: i, // This is changed later!
        name: titleForSeries(dataset, series.formData, formData)
      }));
    } else {
      newSeries = {
        ...series,
        data: series.responseData.data,
        responseData: undefined,
        series: i, // This is changed later!
        name: 'Map series'
      };
    }

    return seriesSet.concat(newSeries);
  }, []);
}

// Dashboard and preset
export function dashboardInfo(dashboard: Dashboard, presets: Preset[]): string {
  const info: Record<string, number> = {
    charts: 0,
    tables: 0,
    maps: 0
  };

  const children =
    dashboard.type === 'warboard'
      ? dashboard.contents.children
      : dashboard.contents;

  execOnChildren(children, (child: DashboardChildren) => {
    if (!child.meta) {
      return;
    }

    const presetId = child.meta.id;
    const preset = presets.find(({ id }) => id === presetId);

    if (!preset) {
      return;
    }

    const presetCharType = Array.isArray(preset.data)
      ? preset.data[0].chartType
      : '';

    if (presetCharType === 'table') {
      info.tables++;
      return;
    }

    if (['map', 'raw_mobile', 'geo_heatmap'].includes(presetCharType)) {
      info.maps++;
      return;
    }

    info.charts++;
  });

  return Object.keys(info)
    .filter((key) => info[key])
    .map((key) =>
      Vue.t(`components.pages.enterprise.DashboardsIndex.${key}`, {
        count: info[key]
      })
    )
    .join(', ');
}

export const presetsOnDashboard = (
  children: (DashboardContents & DashboardChildren)[]
): any[] /* eslint-disable-line @typescript-eslint/no-explicit-any */ => // TODO [WEBPLAT-151] add return types
  children.reduce((presets, child) => {
    const dashboardChildren = child as DashboardChildren;
    if (dashboardChildren.type === 'panel') {
      presets.push(child.meta.id);
    } else {
      presets.push(...presetsOnDashboard(child.children));
    }

    return presets;
  }, []);
