import { ActionTree, MutationTree, Module as VuexModule } from 'vuex';
import ZeusService from '@/common/services/Zeus';
import { ASYNC_STATE, SORTING } from '@/common/global.interfaces';

interface DataSubscriptionsState {
  page: number;
  pageState: string;
  perPage: number;
  usersSort: {
    columnName: string;
    direction: string;
  };
  groupsSort: {
    columnName: string;
    direction: string;
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  paginationData: Record<string, any>;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  users: any[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  groups: any[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  dataSubGroups: any[];
  searchText: string;
}

const initialState = (): DataSubscriptionsState => {
  return {
    page: 1,
    perPage: 25,
    pageState: '',
    usersSort: {
      columnName: 'email',
      direction: SORTING.DESC
    },
    groupsSort: {
      columnName: 'name',
      direction: SORTING.DESC
    },
    paginationData: {},
    users: [],
    groups: [],
    dataSubGroups: [],
    searchText: ''
  };
};

export const mutations: MutationTree<DataSubscriptionsState> = {
  setDataSubGroups(state, dataSubGroups) {
    state.dataSubGroups = dataSubGroups;
  },
  setPage(state, page) {
    state.page = page;
  },
  setPaginationData(state, pagination) {
    state.paginationData = pagination;
  },
  setPageState(state, pageState) {
    state.pageState = pageState;
  },
  setSearchText(state, searchText) {
    state.searchText = searchText;
  },
  setUsersSort(state, sortData) {
    state.usersSort = sortData;
  },
  setUsers(state, userData) {
    state.users = userData;
  },
  setGroupsSort(state, sortData) {
    state.groupsSort = sortData;
  },
  setGroups(state, groupData) {
    state.groups = groupData;
  }
};

const actions: ActionTree<DataSubscriptionsState, unknown> = {
  async getGroups({ commit, state }, data) {
    const { dataSubId } = data || {};
    commit('setPageState', ASYNC_STATE.LOADING);

    let sorts;
    if (state.groupsSort.columnName) {
      if (state.groupsSort.direction === SORTING.ASC) {
        sorts = `-${state.groupsSort.columnName}`;
      } else {
        sorts = state.groupsSort.columnName;
      }
    }

    const query: Record<string, unknown> = {
      sorts
    };

    if (query.sorts === undefined) {
      delete query.sorts;
    }

    try {
      const res = await ZeusService.get(
        `data_subscriptions/${dataSubId}/groups`,
        query
      );

      commit('setPageState', ASYNC_STATE.RESOLVED);
      commit('setGroups', res.data);
    } catch (error) {
      console.error(error);
      commit('setPageState', ASYNC_STATE.ERROR);
    }
  },
  async getUsers({ commit, state }, data) {
    commit('setPageState', ASYNC_STATE.LOADING);
    commit('setPaginationData', {});
    const { dataSubId, searching, sorting } = data || {};

    let sorts;
    if (state.usersSort.columnName) {
      if (state.usersSort.direction === SORTING.ASC) {
        sorts = `-${state.usersSort.columnName}`;
      } else {
        sorts = state.usersSort.columnName;
      }
    }

    if (sorting || searching) {
      commit('setPage', 1);
    }

    const query: Record<string, unknown> = {
      page: state.page,
      per_page: state.perPage,
      include: 'groups,organisation.account_settings',
      sorts
    };

    if (query.sorts === undefined) {
      delete query.sorts;
    }
    if (state.searchText !== '') {
      query.search = state.searchText;
    }

    try {
      const res = await ZeusService.get(
        `data_subscriptions/${dataSubId}/users`,
        query
      );

      commit('setPageState', ASYNC_STATE.RESOLVED);
      commit('setPaginationData', res.meta ? res.meta.pagination : {});

      const users = res.data.map(
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add correct types for the parameters here
        (user: { groups: { data: any[] }; selectedGroup: any }) => {
          const selectedGroup = user.groups?.data.find(
            (group) => group.dataSubscription === Number(dataSubId)
          );

          user.selectedGroup = selectedGroup ?? {};

          return user;
        }
      );

      commit('setUsers', users);
    } catch (error) {
      console.error(error);
      commit('setPageState', ASYNC_STATE.ERROR);
    }
  },

  async getDataSubGroups({ commit }, data) {
    const { dataSubId } = data || {};

    const res = await ZeusService.get(`data_subscriptions/${dataSubId}/groups`);

    commit('setDataSubGroups', res.data);
  }
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
} as VuexModule<DataSubscriptionsState, unknown>;
