
import { defineComponent, PropType } from 'vue';
import { SvgIcon, BaseButton } from '@samknows/design-system';

enum SEARCH_VARIANTS {
  DESCRIPTIVE = 'descriptive',
  ICON_ONLY = 'icon-only'
}
export default defineComponent({
  name: 'SearchField',

  components: {
    SvgIcon,
    BaseButton
  },

  props: {
    items: {
      type: Array as PropType<Record<string, string>[]>,
      required: false,
      default: () => [] as Record<string, string>[]
    },

    property: {
      type: String,
      default: 'name'
    },

    placeholder: {
      type: String,
      default: ''
    },

    variant: {
      type: String,
      default: SEARCH_VARIANTS.DESCRIPTIVE,
      validator: (value: SEARCH_VARIANTS): boolean => {
        return [
          SEARCH_VARIANTS.DESCRIPTIVE,
          SEARCH_VARIANTS.ICON_ONLY
        ].includes(value);
      }
    },

    trim: {
      type: Boolean,
      default: false
    },

    value: {
      type: String,
      default: ''
    },

    autofocus: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: false,
      searchText: '',
      arrowCounter: -1,
      isFocused: false,
      isMobile: window.innerWidth < 767,
      SEARCH_VARIANTS
    };
  },

  computed: {
    hasResults(): boolean {
      return !!this.items.length;
    },
    showClearIcon(): boolean {
      return !!this.searchText;
    },
    searchVariant(): string {
      return this.isMobile ? SEARCH_VARIANTS.ICON_ONLY : this.variant;
    },
    searchTextValue(): string {
      let value = this.searchText;
      if (this.trim) {
        value = value.trim();
      }
      return value;
    }
  },

  watch: {
    items() {
      this.isOpen = this.hasResults;
    },
    value: {
      immediate: true,
      handler() {
        this.searchText = this.value;
      }
    }
  },

  methods: {
    clearSearch() {
      this.searchText = '';
      this.$emit('input', this.searchTextValue);
      this.$emit('input-cleared');
      document.getElementById('search-input').focus();
    },
    handleInput() {
      this.$emit('input', this.searchTextValue);
      this.isOpen = this.hasResults;
    },
    setResult(result: Record<string, string>) {
      this.$emit('select', result);
      this.searchText = result[this.property];
      this.isOpen = false;
    },

    handleArrowDown() {
      if (this.arrowCounter < this.items.length - 1) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },

    handleArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },

    handleEnter() {
      if (this.arrowCounter > -1 && this.arrowCounter < this.items.length) {
        const result: Record<string, string> = this.items[this.arrowCounter];
        this.$emit('select', result);
        this.searchText = result[this.property];
      }

      this.isOpen = false;
      this.arrowCounter = -1;
    },

    handleClickOutside(evt: Event) {
      const element = evt.target;

      if (element instanceof Node && !this.$el.contains(element)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },

    highlightResult(result: Record<string, string>) {
      if (!this.searchTextValue) {
        return result[this.property];
      }
      const regExp = new RegExp(this.searchTextValue, 'gi');
      return result[this.property].replace(regExp, (match) => {
        return `<b>${match}</b>`;
      });
    },

    closeList() {
      this.isOpen = false;
    }
  }
});
