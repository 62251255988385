import ZeusService from '@/common/services/Zeus';
import { UnitTestSchedulesTasks } from '@samknows/instant-test';
import { Unit } from '@/common/global.interfaces';
import { UnreachableCaseError } from '@/common/helpers/UnreachableCaseError';

export enum CallerType {
  INSTANT_TESTS = 'instant_tests',
  SCHEDULED_TESTS = 'scheduled_tests'
}

export enum TestScheduleEventType {
  SCHEDULED = 'SCHEDULED',
  STARTUP = 'STARTUP',
  MANUAL = 'MANUAL'
}

export interface AvailableTest {
  calendars: { data: { event_type: TestScheduleEventType }[] };
  description: string;
  id: number;
  is_triggerable: boolean;
  options: { connections?: string };
  task: { data: { name: UnitTestSchedulesTasks } };
}

export async function fetchAvailableTests(
  unit: Unit,
  callerType: CallerType
): Promise<UnitTestSchedulesTasks[]> {
  const BACKEND_PAGINATION = 50;

  // use panel default test schedule if none is set
  const lmapConfig =
    unit.lmap_config?.data?.id ?? unit.panel.data.default_lmap_config;

  const query: Record<string, unknown> = {
    include: 'task,calendars'
  };

  switch (callerType) {
    case CallerType.INSTANT_TESTS:
      query.isTriggerable = true;
      break;
    case CallerType.SCHEDULED_TESTS:
      query.perPage = BACKEND_PAGINATION;
      break;
    default:
      throw new UnreachableCaseError(callerType);
  }

  const { data } = await ZeusService.get(
    `panels/${unit.panel.data.id}/lmap/configs/${lmapConfig}/tests`,
    query
  );

  if (callerType === CallerType.SCHEDULED_TESTS) {
    return data
      .filter(isScheduledTest)
      .map(mapSingleConnectionTestsToSingleThreadedTestType); // We want to treat multithreaded tests with one connection as single treaded because the metric aggregation will do the same and we want the results to show up
  }

  return data.map((test) => test.task.data.name);
}

function isScheduledTest(test: AvailableTest): boolean {
  return test.calendars.data.some(
    (calendar) => calendar.event_type === TestScheduleEventType.SCHEDULED
  );
}

function mapSingleConnectionTestsToSingleThreadedTestType(
  test: AvailableTest
): UnitTestSchedulesTasks {
  const hasOneConnection = test.options.connections === '1';
  const isTcpMtDownloadMetric =
    test.task.data.name === UnitTestSchedulesTasks.HTTP_DOWNLOAD_MT;
  const isTcpMtUploadMetric =
    test.task.data.name === UnitTestSchedulesTasks.HTTP_UPLOAD_MT;

  if (isTcpMtDownloadMetric && hasOneConnection) {
    return UnitTestSchedulesTasks.HTTP_DOWNLOAD_ST;
  }
  if (isTcpMtUploadMetric && hasOneConnection) {
    return UnitTestSchedulesTasks.HTTP_UPLOAD_ST;
  }
  return test.task.data.name;
}
