
import { defineComponent } from 'vue';
import { BaseAlert, BaseButton } from '@samknows/design-system';
import AuthService from '@/common/services/Auth';
import { whitelabel } from '@samknows/utils';
import { Period, minutesToText } from '@/common/helpers/format';
import { PromiseResponse } from '@/common/global.interfaces';

enum LOGIN {
  minNumberOfAttempts = 3
}

enum LOGIN_CODES {
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOCKED_STATE_ACCOUNT = 'LOCKED_STATE_ACCOUNT',
  DISABLED_ACCOUNT = 'DISABLED_ACCOUNT',
  PASSWORD_EXPIRED_ACCOUNT = 'PASSWORD_EXPIRED_ACCOUNT',
  OVER_LOCKOUT_ATTEMPTS = 'OVER_LOCKOUT_ATTEMPTS'
}

interface LoginFailedData {
  REMAINING_LOGIN_ATTEMPTS: number | null;
  LOCKOUT_DURATION_SECONDS: number | null;
}

type LoginFailedResponse = PromiseResponse<
  LoginFailedData | undefined,
  LOGIN_CODES.LOGIN_FAILED
>;

interface LockedAccountData {
  LOCKED_UNTIL: string | null;
  LOCKOUT_DURATION_SECONDS: number | null;
}

type LockedAccountResponse = PromiseResponse<
  LockedAccountData,
  LOGIN_CODES.LOCKED_STATE_ACCOUNT
>;

type OverLockoutAttemptsResponse = PromiseResponse<
  LockedAccountData,
  LOGIN_CODES.OVER_LOCKOUT_ATTEMPTS
>;

type DisabledAccountResponse = PromiseResponse<
  undefined,
  LOGIN_CODES.DISABLED_ACCOUNT
>;

interface PasswordExpiredData {
  USER_ID: string;
  RESET_TOKEN: string;
  PASS_EXPIRY_PERIOD_DAYS: number | undefined;
}

type PasswordExpiredResponse = PromiseResponse<
  PasswordExpiredData,
  LOGIN_CODES.PASSWORD_EXPIRED_ACCOUNT
>;

type AuthFailedResponses =
  | LoginFailedResponse
  | LockedAccountResponse
  | PasswordExpiredResponse
  | DisabledAccountResponse
  | OverLockoutAttemptsResponse;

export default defineComponent({
  metaInfo() {
    return {
      title: this.$t('pages.login.title')
    };
  },
  components: {
    BaseAlert,
    BaseButton
  },
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      showLoginFailedAlert: false,
      isLocked: false,
      showDisabledAccountAlert: false,
      permanentLockout: false,
      duration: 0,
      attempts: 0,
      hideImage: whitelabel('hideLoginImage')
    };
  },
  computed: {
    minAttempts(): boolean {
      return !!(this.attempts && this.attempts <= LOGIN.minNumberOfAttempts);
    },
    lockedDuration(): number {
      return Math.round(this.duration / 60);
    },
    showAttempts(): boolean {
      return this.minAttempts && this.lockedDuration === 0;
    },
    showLockedDuration(): boolean {
      return this.minAttempts && this.lockedDuration > 0;
    },
    time(): Period {
      return minutesToText(this.lockedDuration);
    }
  },
  methods: {
    handlePasswordExpired(data: PasswordExpiredData) {
      const resetToken = data.RESET_TOKEN;
      const userId = data.USER_ID;
      const passwordExpiryTime = data.PASS_EXPIRY_PERIOD_DAYS;

      this.$router.push({
        name: 'PasswordExpired',
        params: {
          userId: userId,
          token: resetToken,
          passwordExpiryTime: `${passwordExpiryTime}`
        }
      });
    },
    handleLockedAccount(data: LockedAccountData) {
      this.showLoginFailedAlert = true;

      const { LOCKED_UNTIL, LOCKOUT_DURATION_SECONDS } = data;

      this.isLocked = typeof LOCKED_UNTIL !== 'undefined';
      this.permanentLockout = LOCKED_UNTIL === null;
      this.handleShowLockoutDuration(LOCKOUT_DURATION_SECONDS);
    },
    handleShowLockoutDuration(duration = 0) {
      this.duration = duration;
    },
    async authenticate(email: string, password: string) {
      this.isLoading = true;

      try {
        await AuthService.authenticate(email, password);
        AuthService.goToDefaultPath(this.$route);
      } catch (error) {
        this.isLoading = false;
        console.error(error);

        if (error instanceof Response) {
          const responseBody: AuthFailedResponses = await error.json();
          const { data, code } = responseBody;

          switch (code) {
            case LOGIN_CODES.DISABLED_ACCOUNT:
              this.showDisabledAccountAlert = true;
              break;
            case LOGIN_CODES.PASSWORD_EXPIRED_ACCOUNT:
              this.handlePasswordExpired(data);
              break;
            case LOGIN_CODES.LOCKED_STATE_ACCOUNT:
              this.handleLockedAccount(data);
              break;
            case LOGIN_CODES.OVER_LOCKOUT_ATTEMPTS:
              this.handleLockedAccount(data);
              break;
            case LOGIN_CODES.LOGIN_FAILED:
              this.showLoginFailedAlert = true;
              const { REMAINING_LOGIN_ATTEMPTS, LOCKOUT_DURATION_SECONDS } =
                data ?? {};
              this.attempts = REMAINING_LOGIN_ATTEMPTS;
              this.handleShowLockoutDuration(LOCKOUT_DURATION_SECONDS);
              break;
            default:
              this.showLoginFailedAlert = true;
          }
        } else {
          // Always show the login failed alert for other errors
          this.showLoginFailedAlert = true;
        }
      }
    }
  }
});
