
import Vue, {
  defineComponent,
  PropType,
  ref,
  computed,
  onBeforeMount,
  reactive
} from 'vue';
import { Country, Isp, Package } from '@/features/volunteer-signup/types';
import {
  BaseAlert,
  BaseButton,
  BaseFormCheckbox,
  BaseFormField,
  BaseFormInput,
  BaseFormSelect
} from '@samknows/design-system';
import {
  createEulaLink,
  ispsForCountry,
  packagesForIsp
} from '@/features/volunteer-signup/helpers/volunteer.helper';
import { formConfig } from '@/features/volunteer-signup/volunteer-form.config';
import { ORGANISATIONS } from '../../constants';
import {
  meqyasSubmit,
  scrollToTop
} from '@/features/volunteer-signup/services/signup';
import { heapTrack } from '@/common/services/heapTracking';
import { ASYNC_STATE } from '@/common/global.interfaces';

export default defineComponent({
  name: 'MeqyasForm',
  components: {
    BaseAlert,
    BaseButton,
    BaseFormCheckbox,
    BaseFormField,
    BaseFormInput,
    BaseFormSelect
  },
  props: {
    countryData: {
      type: Object as PropType<Country>,
      required: true
    },
    isp: {
      type: Number,
      default: undefined
    },
    product: {
      type: Number,
      default: undefined
    }
  },

  setup(props, context) {
    const view = ref(1);
    const formStatus = ref(ASYNC_STATE.RESOLVED);
    const showGenericError = ref(false);

    const signupRequirements = ref([
      { req: 'fixedConnection', reqMet: false },
      { req: 'someSpace', reqMet: false },
      { req: 'sparePower', reqMet: false }
    ]);
    function checkRequirements(): boolean {
      return signupRequirements.value.every(
        (requirement) => requirement.reqMet === true
      );
    }

    const formData = reactive({
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      mobile: undefined,
      installation_address_1: '',
      installation_address_2: '',
      installation_address_3: '',
      installation_city: undefined,
      installation_postcode: '',
      installation_country: 'Saudi Arabia',
      installation_phone: '',
      delivery_address_1: '',
      delivery_address_2: '',
      delivery_address_3: '',
      delivery_city: undefined,
      delivery_postcode: '',
      delivery_country: 'Saudi Arabia',
      delivery_phone: '',
      language_preference: Vue.config.lang?.replace('-', '_') ?? 'en_GB',
      ispId: undefined,
      ispOther: undefined,
      service_provider: undefined,
      productId: undefined,
      productOther: undefined,
      package: undefined,
      // hidden field - "honeypot" for bots to avoid spam signups. FEU PR 223
      other: null,
      tos: false
    });

    const cities = computed(() => {
      const lang = Vue.config.lang?.split('-')[0];
      const listOfCities = formConfig[ORGANISATIONS.CST].cities?.[lang];
      return listOfCities;
    });
    const deliveryAddressSame = ref(true);

    const ispInputDisabled = ref(false);
    onBeforeMount(() => {
      if (props.isp) {
        formData.ispId = props.isp;
        ispInputDisabled.value = true;
      }
      if (props.product) {
        formData.productId = props.product;
      }
    });
    const getIspsForCountry = computed((): Isp[] => {
      return ispsForCountry(195, [props.countryData]);
    });
    const getPackagesForIsp = computed((): Package[] => {
      return packagesForIsp(formData.ispId, getIspsForCountry.value);
    });
    const CHOICE_VALUE_UNKNOWN = -1;

    const eulaLink = computed((): string => {
      return createEulaLink(ORGANISATIONS.CST);
    });

    const isSubmitting = computed(() => {
      return formStatus.value === ASYNC_STATE.LOADING;
    });

    function setUpDeliveryAddress() {
      formData.delivery_address_1 = formData.installation_address_1;
      formData.delivery_address_2 = formData.installation_address_2;
      formData.delivery_address_3 = formData.installation_address_3;
      formData.delivery_city = formData.installation_city;
      formData.delivery_postcode = formData.installation_postcode;
      formData.delivery_country = formData.installation_country;
      formData.delivery_phone = formData.installation_phone;
    }

    function getFrontName(
      idProperty: number,
      list: Isp[] | Package[],
      otherProperty: undefined
    ) {
      if (idProperty === CHOICE_VALUE_UNKNOWN) {
        return Vue.t('SignupForm.dunno');
      } else {
        const frontName = list.find(({ id }) => id === idProperty);
        return frontName ? frontName.name : otherProperty;
      }
    }

    async function formSubmit() {
      if (view.value <= 3) {
        view.value += 1;
        return;
      }

      // hidden field - "honeypot" for bots to avoid spam signups. FEU PR 223
      if (formData.other !== null) {
        return;
      }

      formStatus.value = ASYNC_STATE.LOADING;
      showGenericError.value = false;

      if (deliveryAddressSame.value) {
        setUpDeliveryAddress();
      }
      formData.installation_phone = formData.mobile;
      formData.delivery_phone = formData.mobile;

      formData.service_provider = getFrontName(
        formData.ispId,
        getIspsForCountry.value,
        formData.ispOther
      );
      formData.package = getFrontName(
        formData.productId,
        getPackagesForIsp.value,
        formData.productOther
      );

      try {
        await meqyasSubmit(formData);
        formStatus.value = ASYNC_STATE.RESOLVED;
        scrollToTop();
        context.emit('success');
        heapTrack('SignupSubmitSuccess');
      } catch (error) {
        heapTrack('SignupSubmitError', {
          type: 'uncaught'
        });
        formStatus.value = ASYNC_STATE.ERROR;
        showGenericError.value = true;
        console.error(error);
      }
    }

    return {
      CHOICE_VALUE_UNKNOWN,
      view,
      showGenericError,
      signupRequirements,
      checkRequirements,
      formData,
      deliveryAddressSame,
      ispInputDisabled,
      getIspsForCountry,
      getPackagesForIsp,
      cities,
      eulaLink,
      isSubmitting,
      formSubmit
    };
  }
});
