import Vue from 'vue';
import { prefStorage } from '@samknows/utils';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { snakeCaseToSentenceCase } from './format';
dayjs.extend(LocalizedFormat);

/**
 * Formats a number but removes unused decimal places
 * The parseFloat removes unused decimal places (e.g. 12.00 => 12).
 */
Vue.filter('formatNumber', (value: number) => parseFloat(value.toFixed(2)));

// date | withTimeZone | dateFormat
Vue.filter('withTimezone', (value: string, fallback = '') => {
  if (!value) {
    return fallback;
  }
  // String to Date
  const d = new Date(value.replace(/\s+/g, 'T'));

  // Get timezone
  const currentTime = new Date(d.getTime() - d.getTimezoneOffset() * 60000);

  return currentTime;
});

Vue.filter(
  'dateFormat',
  (
    value: Date | string,
    options: Intl.DateTimeFormatOptions,
    fallback = ''
  ) => {
    if (!value) {
      return fallback;
    }
    // String or date
    const date =
      value instanceof Date ? value : new Date(value.replace(/\s+/g, 'T'));

    // Default options for dates
    options = options || {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };

    // Use options and locale to convert the date
    const currentTime = date.toLocaleTimeString(
      prefStorage.get<string>('locale'),
      options
    );

    return currentTime;
  }
);

Vue.filter('precision', (value: string | number, precision: number) => {
  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (value > Math.pow(10, precision)) {
    return value.toFixed(0);
  }

  return value.toPrecision(precision);
});

Vue.filter('slugToSnake', (str: string) => str.replace('-', '_'));

Vue.filter('snakeCaseToSentenceCase', snakeCaseToSentenceCase);

Vue.filter('JsonStrToCSV', (str: string) =>
  str
    .replace(/\{|\}|"/g, '')
    .replace(/,/g, ', ')
    .replace(/:/g, ': ')
);

Vue.filter('formatTimezone', (timezone: Record<string, unknown>) => {
  const standardTime = timezone.standard_time;

  return `UTC${standardTime >= 0 ? '+' : ''}${standardTime} (${timezone.name})`;
});

Vue.filter(
  'formatDate',
  (
    value: string | number | Date | dayjs.Dayjs,
    format: string,
    fallback = ''
  ) => {
    if (!value) {
      return fallback;
    }
    return dayjs(value).format(format);
  }
);
