import AuthService from '@/common/services/Auth';
import { GRANT_SECTION } from '../account/constants';
import store from '@/common/store';
import viewTypes from '@/common/helpers/view-types';

const PageSearch = (): Promise<unknown> =>
  import(
    /* webpackChunkName:  "unit-search" */ '@/features/unit-search/pages/SearchPage.vue'
  );

const PageSearchResults = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "unit-search"*/ '@/features/unit-search/pages/SearchResultsPage.vue'
  );

const searchPermissionCheck = (): boolean => {
  const hasRequiredPermissions = AuthService.hasPermissions(
    GRANT_SECTION.UNIT,
    'search'
  );
  const isEnterpriseUser = viewTypes.isEnterpriseView;

  return hasRequiredPermissions && isEnterpriseUser;
};

/*
  Handle users coming from areas with multiple panel access
  They should only be able to search against a single panel but a situation can occur where their selected
  panel is not set to the panel they have permission to search.
  For this reason we get and set the correct panel when landing on the search page.
*/
export const setPanel = (): void => {
  store.dispatch('resetPanelForSection', {
    grantSection: GRANT_SECTION.UNIT,
    grantPermission: 'search',
    setDefault: true
  });
};

export default [
  {
    path: '/units',
    component: PageSearch,
    redirect: (): string => {
      setPanel();
      return '/units/search';
    },
    children: [
      {
        path: 'search',
        component: PageSearchResults,
        meta: {
          type: 'search',
          guardFailure: 'notfound',
          guard: (): boolean => {
            setPanel();
            return searchPermissionCheck();
          },
          userArea: 'unit-search'
        }
      }
    ]
  }
];
