import * as dataUsageHelpers from '@/common/helpers/data-usage.helpers';

import {
  CHART_TYPE,
  HighchartsData,
  MetricKeys,
  MeasurementBuilderFormData
} from '@/common/global.interfaces';
import splitSort from '@/common/helpers/split-sort';
import { dateParse, numberFormatter } from '@samknows/utils';
import dayjs from 'dayjs';
import Vue from 'vue';
import {
  AggregatedDailyData,
  ChartInputData,
  ChartInputDataItem,
  DailyScheduledTestsByMetric,
  DataUsageEvent,
  MetricResultsData,
  YAxes
} from '../types';

interface ConsolidateResponses extends DailyScheduledTestsByMetric {
  data: DailyScheduledTestsByMetric;
  series: number;
  responseData: undefined;
  name: string;
}

export const generateYAxes = (
  data: MetricResultsData,
  metric: string
): YAxes[] => {
  const yLabelKey = `metrics.${metric}.label`;

  let yLabel = Vue.t(yLabelKey);
  if (yLabel === yLabelKey) {
    yLabel = Vue.t('common.labelValue');
  }

  const axis: YAxes = {
    metric: {
      key: metric as MetricKeys
    },
    yLabel,
    unit: data?.metricMetadata?.metricUnit,
    yAxis: 0
  };

  return [axis];
};

// expectation that the data is in format DD-MM-YYYY e.g. 30-10-2022
type FromToDatesType = {
  from: string;
  to: string;
};

export const fillInMissingDates = (
  data: AggregatedDailyData[],
  fromToDates: FromToDatesType
): AggregatedDailyData[] => {
  if (!fromToDates || !data || !Array.isArray(data) || data.length < 1) {
    return data;
  }

  // expectation that the data is in format DD-MM-YYYY e.g. 30-10-2022
  const fromReversed = fromToDates.from.split('-').reverse().join('-');
  const toReversed = fromToDates.to.split('-').reverse().join('-');
  const from = dayjs(fromReversed);
  const to = dayjs(toReversed);
  const diff = to.diff(from, 'day');
  const toFormatted = to.format('YYYY-MM-DD');
  const fromFormatted = from.format('YYYY-MM-DD');

  const days = [];
  for (let i = 0; i < diff; i++) {
    if (i === 0) {
      days.push(fromFormatted);
    } else {
      const thisDate = from.add(i, 'day').format('YYYY-MM-DD');
      days.push(thisDate);
    }
  }
  days.push(toFormatted);

  const completedData: AggregatedDailyData[] = days.map((day) => {
    const matchingDataItem = data.filter((x) => x.dtime === day)[0];
    if (!matchingDataItem) {
      const newDataItem: AggregatedDailyData = {
        dtime: day,
        metricValue: null
      };
      return newDataItem;
    }

    return matchingDataItem;
  });
  return completedData;
};

export const metricDataToHighcharts = (
  metricData: AggregatedDailyData[] = []
): HighchartsData[] => {
  const seriesData = metricData.map((item, index) => {
    const prev = metricData[index - 1];
    const next = metricData[index + 1];
    const prevHasData = prev?.metricValue || null;
    const nextHasData = next?.metricValue || null;

    const data: HighchartsData = {
      x: dateParse(item.dtime),
      y: item.metricValue,
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add types for the values here
      data: item as Record<string, any>
    };

    // add markers to items in series that are isolated, e.g. a single data point with empty days either side of it
    if (!prevHasData && !nextHasData) {
      data.marker = { enabled: true };
    }

    return data;
  });

  return seriesData;
};

export const dataToSeries = (
  data: ConsolidateResponses[],
  panel: number,
  formData: MeasurementBuilderFormData
): ChartInputDataItem[] => {
  const fromToDates = formData?.date;
  const unsortedSeries = data.map((series: ConsolidateResponses) => {
    const timePeriod = formData?.timeAggregation;
    const isHourly = timePeriod === 'hourly';
    let aggregatedData = isHourly
      ? series.aggregatedHourly
      : series.aggregatedDaily;

    // add missing dates
    if (!isHourly) {
      aggregatedData = fillInMissingDates(aggregatedData, fromToDates);
    }

    const metricDataToHCharts = metricDataToHighcharts(aggregatedData);

    const singleSeries = {
      type: 'line',
      showInLegend: false,
      turboThreshold: 0,
      boostThreshold: 1000,
      ...series,
      metricData: undefined as undefined,
      data: metricDataToHCharts,
      formData,
      yAxis: 0,
      marker: {}
    };

    if (singleSeries.data.length === 1) {
      singleSeries.marker = { enabled: true };
    }

    return singleSeries;
  });

  const multiMetric: boolean = unsortedSeries.some(
    (series) => series.series > 0
  );

  return (
    splitSort(unsortedSeries, 'name')
      // This reducer basically defines what the colours should be - when a
      // chart has multiple formDatas, each series has its own colour, otherwise
      // each chart series has its own colour
      .reduce((seriesSet, series, i: number) => {
        const previousSeries = i ? seriesSet[seriesSet.length - 1] : undefined;
        const previousSeriesNum = previousSeries ? previousSeries.series : -1;

        let seriesNum = previousSeriesNum;
        if (
          !multiMetric ||
          (multiMetric && series.series > previousSeriesNum)
        ) {
          seriesNum++;
        }
        series.series = seriesNum;

        return seriesSet.concat(series);
      }, [])
  );
};

export const consolidateResponses = (
  data: DailyScheduledTestsByMetric[]
): ConsolidateResponses[] => {
  // Merge multiple metric responses together
  data = data.reduce((responses, response) => {
    return responses.concat(response);
  }, []);

  // Collapse array of objects of arrays into array
  return data.reduce((seriesSet, series, i) => {
    const newSeries = {
      ...series,
      data: series,
      responseData: undefined as undefined,
      series: i, // This is changed later!
      name: ''
    };

    return seriesSet.concat(newSeries);
  }, []);
};

// convert data into format required for highcharts
export const getUnitAnalyticsChartData = (
  data: MetricResultsData,
  metricKey: string,
  formData: MeasurementBuilderFormData[]
): ChartInputData => {
  const appendedData = { ...data, formData: formData[0] };
  const panel = formData[0]?.panel;
  const transformedData = consolidateResponses([appendedData]);

  // Convert data into Highcharts-readable data
  return {
    status: CHART_TYPE.GRAPH,
    yAxes: generateYAxes(data, metricKey),
    data: dataToSeries(transformedData, panel, formData[0])
  };
};

export const populateDataUsage = (
  events: DataUsageEvent[]
): Record<string, string | number> => {
  if (!events || events.length < 1) {
    return null;
  }

  // endpoints, timePeriods and metrics all used to cycle through to create an object with keys created from the strings in these arrays and with the corresponding data found in the events
  // e.g. { dataUsageBytes_metric_results_week_total: 1234 }
  const endpoints = [
    'metric_results',
    'instant_tests',
    'scheduled_tests_daily',
    'skipped_tests',
    'disconnections',
    'bytes_used'
  ];
  const timePeriods = ['day', 'week', 'month'];
  const metrics: MetricKeys | string[] = [
    MetricKeys.LATENCY,
    MetricKeys.PACKET_LOSS,
    MetricKeys.DOWNLOAD_MT,
    MetricKeys.UPLOAD_MT
  ];

  // Get all metrics passed through so we can include any additional metrics (e.g. when a chart is added in CC/CH)
  const allMetrics = events.map((x) => x.metric);

  // Add any metrics not already included
  allMetrics.forEach((metric) => {
    const included = metrics.includes(metric);
    if (!included) {
      metrics.push(metric);
    }
  });

  const dataPrefix = 'dataUsageBytes';
  let dataUsage: Record<string, string | number> = {};

  // start by looping over endpoints and then go into both metrics and timeperiods in order to return more granular data for tracking
  endpoints.forEach((endpoint) => {
    const endpointData = dataUsageHelpers.getDataUsageProperties(
      events,
      { endpoint },
      dataPrefix
    );

    // replace dataUsage with first set of results, this will be the top level info on data usage for endpoints regardless of time period or metrics requested
    // e.g. { dataUsageBytes_metric_results_total: 1000 }
    dataUsage = { ...dataUsage, ...endpointData };

    // for each endpoint we want to know which time period was requested
    //  e.g. { dataUsageBytes_metric_results_week_total: 250, dataUsageBytes_metric_results_month_total: 750 }
    timePeriods.forEach((timePeriod) => {
      const timePeriodData = dataUsageHelpers.getDataUsageProperties(
        events,
        {
          endpoint,
          timePeriod
        },
        dataPrefix
      );
      dataUsage = { ...dataUsage, ...timePeriodData };

      // for each endpoint and time period we want to know specifically how many bytes used for each metrics in those requests
      //  e.g. { dataUsageBytes_metric_results_week_httpgetmt_total: 150, dataUsageBytes_metric_results_week_udpLatency_total: 100 }
      metrics.forEach((metric) => {
        const metricData = dataUsageHelpers.getDataUsageProperties(
          events,
          {
            endpoint,
            timePeriod,
            metric
          },
          dataPrefix
        );
        dataUsage = { ...dataUsage, ...metricData };
      });
    });

    // some tests don't have a time period in the request (scheduled tests daily) so we need to make sure we still capture the metric data usage for those
    //  e.g. { dataUsageBytes_scheduled_tests_daily_udpLatency_total: 123 }
    metrics.forEach((metric) => {
      const metricData = dataUsageHelpers.getDataUsageProperties(
        events,
        { endpoint, metric },
        dataPrefix
      );
      dataUsage = { ...dataUsage, ...metricData };
    });
  });

  // get total of all bytes processed for every event
  const totalDataUsed = events.reduce(
    (sum: number, item: DataUsageEvent) => sum + item?.dataUsed || 0,
    0
  );
  const callsThatUsedData = events.filter((x) => {
    const { dataUsed = 0 } = x || {};
    return dataUsed > 0;
  }).length;
  dataUsage[`${dataPrefix}_totalDataUsed`] = totalDataUsed;
  dataUsage[`${dataPrefix}_totalCallsMade`] = callsThatUsedData;

  return dataUsage;
};

export function formatScheduleTestValue(value: number, unit: string): string {
  if (isNaN(value)) {
    return '-';
  }

  const translatedUnit = Vue.t(`units.${unit}`);
  return `${numberFormatter(value, unit)} ${translatedUnit}`;
}
