export const namespaced = true;

export const state = {
  asImage: false
};

/* eslint-disable no-shadow */
export const mutations = {
  setAsImage(state, asImage) {
    state.asImage = asImage;
  }
};
