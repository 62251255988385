<template>
  <div />
</template>

<script>
import AuthService from '@/common/services/Auth';
import { setupStyling } from '@samknows/utils';

export default {
  mounted() {
    AuthService.deauthenticate();
    this.$router.push('/login');
    setupStyling();
  }
};
</script>
