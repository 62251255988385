export async function logError(error: unknown): Promise<void> {
  if (error instanceof Response) {
    try {
      const jsonError = await error.json();
      console.error(jsonError);
      return;
    } catch {
      console.error(error);
      return;
    }
  }

  if (typeof error === 'object' && 'error' in error) {
    console.error(error.error);
    return;
  }

  console.error(error);
}

/**
 * Handle errors thrown when a javascript file fail to load.
 * This can happen on deploy, within a loaded app when the user navigate to a new area that was not loaded.
 * Other valid reason can be: network issue or a real error with deployments
 * We save a chunk that previously error to prevent en endless loop in case of a valid error
 * @param {string} source - name of the chunk file which failed to load
 */
function handleChunkLoadingFailed(source: string): void {
  const LOCAL_STORAGE_KEY = 'sk1-chunk-failed-load';

  try {
    const savedChunkFailedLoadedDetails = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY)
    );

    const hasChunkFailedLoadingOnce =
      savedChunkFailedLoadedDetails?.source === source;

    // If the same chunk keeps failing to load, it could be a valid issue (network...)
    if (hasChunkFailedLoadingOnce) {
      // Show error page
      document.location.href = '/500';
      return;
    }
    const chunkFailedLoadedDetails = {
      source
    };

    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(chunkFailedLoadedDetails)
    );
    window.location.reload();
  } catch {
    return;
  }
}

export function handleGlobalErrors(): void {
  window.onerror = function (
    message: string | Event,
    source: string,
    lineno: number,
    colno: number,
    error: Error
  ) {
    const browsersChunkErrorMessage = {
      firefox: "expected expression, got '<'",
      other: "Unexpected token '<'"
    };
    if (
      [
        browsersChunkErrorMessage.firefox,
        browsersChunkErrorMessage.other
      ].includes(error.message)
    ) {
      handleChunkLoadingFailed(source);
      // Needed to prevent the firing of the default event handler.
      return true;
    }
  };
}
