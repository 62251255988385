import AuthService, {
  AccessiblePanel,
  Accessibles
} from '@/common/services/Auth';
import viewTypes from '@/common/helpers/view-types';
import { prefStorage } from '@samknows/utils';
import { GRANT_SECTION } from '../account/constants';

const PageHomeAnalytics = (): Promise<unknown> =>
  import('@/features/analytics/pages/home/AnalyticsView.vue');
const PageEnterpriseAnalytics = (): Promise<unknown> =>
  import('@/features/analytics/pages/enterprise/AnalyticsView.vue');
const PageEnterpriseAnalyticsChart = (): Promise<unknown> =>
  import('@/features/analytics/pages/enterprise/ChartView.vue');
const PageEnterpriseAnalyticsPresets = (): Promise<unknown> =>
  import('@/features/analytics/pages/enterprise/PresetsView.vue');
const PageDataExport = (): Promise<unknown> =>
  import('@/features/analytics/pages/DataExport.vue');

const homeAnalytics = {
  path: '/analytics',
  component: PageHomeAnalytics,
  meta: {
    hasSideBar: true,
    guard: (accessibles: Accessibles) => {
      if (!AuthService.userAuthed() || !accessibles.units.length) {
        return false;
      }

      return viewTypes.isConsumerView;
    }
  }
};

const enterpriseAnalytics = {
  path: '/analytics',
  alias: '/mapping',
  component: PageEnterpriseAnalytics,
  children: [
    {
      path: '/',
      component: PageEnterpriseAnalyticsChart,
      alias: 'preset/*',
      meta: {
        guard: () => AuthService.analyticsAccess(),
        hasSideBar: true
      }
    },
    {
      path: 'presets',
      component: PageEnterpriseAnalyticsPresets,
      meta: {
        guard: () => AuthService.analyticsAccess(),
        hasSideBar: true
      }
    }
  ]
};

const analyticsRoot = viewTypes.isEnterpriseView
  ? enterpriseAnalytics
  : homeAnalytics;
export default [
  {
    path: '/analytics',
    ...analyticsRoot
  },
  {
    // When !viewTypes.isEnterpriseView, this route is undefined
    path: '/analytics/presets',
    meta: {
      guard: () => false
    }
  },
  {
    path: '/view',
    redirect: '/analytics'
  },
  {
    path: '/view/*',
    redirect: '/analytics'
  },
  {
    // When !viewTypes.isEnterpriseView, this route is undefined
    path: '/mapping*',
    meta: {
      guard: () => false
    }
  },
  {
    path: '/data-api',
    redirect: '/data-export'
  },
  {
    path: '/data-export',
    component: PageDataExport,
    meta: {
      type: 'single',
      hasSideBar: true,

      guard: () => {
        if (
          !prefStorage.get<AccessiblePanel>('panel') ||
          !viewTypes.isEnterpriseView
        ) {
          return false;
        }
        return (
          viewTypes.isEnterpriseView &&
          AuthService.hasPanelPermissions(
            GRANT_SECTION.TEST_MEASUREMENTS,
            'data_api'
          )
        );
      }
    }
  }
];
