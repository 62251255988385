import { ORGANISATIONS } from './constants';
import { FormConfig } from './types';

export const formConfig: Record<ORGANISATIONS, FormConfig> = {
  [ORGANISATIONS.COM_COM]: {
    country: 'newZealand',
    studyName: 'Measuring Broadband New Zealand',
    eulaLink: 'https://www.measuringbroadbandnewzealand.com/eula',
    faqsLink: 'https://www.measuringbroadbandnewzealand.com/faqs',
    showPostcodeField: true,
    showFibreAvailability: true,
    branding: {
      logo: '/img/signup-newzealand-logo.svg',
      reportsLink:
        'https://comcom.govt.nz/regulated-industries/telecommunications/monitoring-the-telecommunications-market/monitoring-new-zealands-broadband/Reports-from-Measuring-Broadband-New-Zealand',
      image: '/img/signup-newzealand.svg',
      colour: '#b10d29'
    }
  },
  [ORGANISATIONS.ACCC]: {
    country: 'australia',
    studyName: 'Measuring Broadband Australia',
    eulaLink: 'https://measuringbroadbandaustralia.com.au/eula',
    faqsLink: 'https://measuringbroadbandaustralia.com.au/faqs',
    showPostcodeField: true,
    branding: {
      logo: '/img/signup-australia-logo.svg',
      reportsLink:
        'https://www.accc.gov.au/by-industry/telecommunications-and-internet/telecommunications-monitoring/measuring-broadband-australia-program',
      image: '/img/signup-australia.svg',
      colour: '#0e7c8d'
    }
  },
  [ORGANISATIONS.CST]: {
    studyName: 'Meqyas',
    eulaLink: 'https://www.meqyas.sa/eula',
    faqsLink: 'https://www.meqyas.sa/faqs',
    cities: {
      ar: [
        'ابقيق',
        'الأحساء',
        'الأسياح',
        'الأفلاج',
        'الباحة',
        'البدائع',
        'البكيرية',
        'الجبيل',
        'الجموم',
        'الجوف',
        'الحرث',
        'الحريق',
        'الحناكية',
        'الخبر',
        'الخرج',
        'الخرخير',
        'الخرمة',
        'الخفجي',
        'الدائر',
        'الدرعية',
        'الدمام',
        'الدوادمي',
        'الرس',
        'الرياض',
        'الريث',
        'الزلفي',
        'السليل',
        'الشماسية',
        'الشنان',
        'الطائف',
        'العارضة',
        'العقيق',
        'العلا',
        'العيدابي',
        'الغاط',
        'الغزالة',
        'القرى',
        'القريات',
        'القطيف',
        'القنفذة',
        'القويعية',
        'القياس',
        'الكامل',
        'الليث',
        'المجاردة',
        'المجمعة',
        'المخواة',
        'المدينة المنورة',
        'المذنب',
        'المزاحمية',
        'المندق',
        'المهد',
        'النبهانية',
        'النعيرية',
        'النماص',
        'الوجه',
        'أبـها',
        'أبو عريش',
        'أحد المسارحة',
        'أحد رفيدة',
        'أملج',
        'بالجرشي',
        'بدر',
        'بدر الجنوب',
        'بريدة',
        'بقعاء',
        'بلقرن',
        'بيش',
        'بيشة',
        'تبوك',
        'تثليث',
        'تربة',
        'تيماء',
        'ثادق',
        'ثار',
        'جازان',
        'جدة',
        'حائل',
        'حبونا',
        'حريملاء',
        'حفر الباطن',
        'حقل',
        'حوطة بني تميم',
        'خباش',
        'خليص',
        'خميس مشيط',
        'خيبر',
        'دومة الجندل',
        'رابغ',
        'رأس تنورة',
        'رجال ألمع',
        'رفحاء',
        'رماح',
        'رنية',
        'رياض الخبراء',
        'سراة عبيدة',
        'سكاكا',
        'شرورة',
        'شقراء',
        'صامطة',
        'صبياء',
        'ضباء',
        'ضرماء',
        'ضمد',
        'طريف',
        'ظهران الجنوب',
        'عرعر',
        'عفيف',
        'عنيزة',
        'عيون الجواء',
        'فرسان',
        'قرية العليا',
        'قلوة',
        'محايل',
        'مكة المكرمة',
        'نجران',
        'وادي الدواسر',
        'يدمة',
        'ينبع',
        'أخرى'
      ],
      en: [
        'Abha',
        'Abu Arish',
        'Ad Dair',
        'Ad Dammam',
        'Ad Diriyah',
        'Ad Duwadimi',
        'Afif',
        'Ahad Al Masarihah',
        'Ahad Rufaydah',
        'Al Aflaj',
        'Al Aqiq',
        'Al Ardah',
        'Al Aydabi',
        'Al Badai',
        'Al Bahah',
        'Al Bukayriyah',
        'Al Ghat',
        'Al Ghazalah',
        'Al Hariq',
        'Al Harth',
        'Al Hinakiyah',
        'Al Ila',
        'Al Jawf',
        'Al Jubayl',
        'Al Jumum',
        'Al Kamil',
        'Al Khafji',
        'Al Kharj',
        'Al Kharkhir',
        'Al Khubar',
        'Al Khurmah',
        'Al Lith',
        'Al MadInah Al Munawwarah',
        'Al Mahd',
        'Al Majardah',
        'Al Majmaah',
        'Al Mandaq',
        'Al Midhnab',
        'Al Mukhwah',
        'Al Muzahimiyah',
        'Al Qatif',
        'Al Qayyas',
        'Al Qunfudhah',
        'Al Qura',
        'Al Qurayyat',
        'Al QuwayIyah',
        'Al Wajh',
        'Alahsa',
        'Alasyah',
        'An Nabhaniyah',
        'An Namas',
        'An NuayrIyah',
        'Ar Rass',
        'Ar Rayth',
        'Arar',
        'As Sulayyil',
        'Ash Shimasiyah',
        'Ash Shinan',
        'Az Zulfi',
        'Badr',
        'Badr Al Janub',
        'Balqarn',
        'Baqaa',
        'Baysh',
        'BiljurashI',
        'Bishah',
        'Buqayq',
        'Buraydah',
        'Damad',
        'Dawmat Al Jandal',
        'Dirma',
        'Duba',
        'Farasan',
        'Hafar Al Batin',
        'Hail',
        'Haqil',
        'Hawtat Bani Tamim',
        'Hubuna',
        'Huraymila',
        'Jazan',
        'Jeddah',
        'Khabash',
        'Khamis Mushayt',
        'Khaybar',
        'Khulays',
        'Makkah Al Mukarramah',
        'Muhayil',
        'Najran',
        'Other',
        'Qaryah Al Ulya',
        'Qilwah',
        'Rabigh',
        'Rafha',
        'Ranyah',
        'Ras Tannurah',
        'Rijal Alma',
        'Riyad Al Khabra',
        'Riyadh',
        'Rumah',
        'Sabya',
        'Sakaka',
        'Samtah',
        'Sarat Abidah',
        'Shaqra',
        'Sharurah',
        'Tabuk',
        'Taif',
        'Tathlith',
        'Tayma',
        'Thadiq',
        'Thar',
        'Turayf',
        'Turubah',
        'Umluj',
        'Unayzah',
        'Uyun Al Jiwa',
        'WadI Ad Dawasir',
        'Yadamah',
        'Yanbu',
        'Zahran Al Janub'
      ]
    },
    branding: {
      logo: '/img/signup-meqyas-logo.svg',
      reportsLink: 'https://www.meqyas.sa/reports',
      image: '/img/signup-meqyas.svg',
      colour: '#000062'
    }
  },
  [ORGANISATIONS.TRA]: {
    country: 'oman',
    studyName: 'Measuring Broadband Oman',
    eulaLink: 'https://www.measuringbroadbandoman.com/eula',
    faqsLink: 'https://www.measuringbroadbandoman.com/faqs',
    regionsLabel: 'SignupForm.governorate',
    regionsSelectPlaceholder: 'SignupForm.selectYourGovernorate',
    regions: {
      ar: [
        'مسقط',
        'الوسط',
        'مسند',
        'الداخلي',
        'شمال الشرقي',
        'جنوب الشرقي',
        'ظفا',
        'الظاهر',
        'البريم',
        'شمال الباطن',
        'جنوب الباطنة'
      ],
      en: [
        'Muscat',
        'Dofar',
        'Musandam',
        'Al Buraymi',
        'Ad Dakhliyah',
        'Al Batina North',
        'Al Batina South',
        'Ash Sharqiyah South',
        'Ash Sharqiyah North',
        'Adh Dhahirah',
        'Al Wusta'
      ]
    },
    cities: {
      ar: [
        'العامرا',
        'بوشر',
        'قريا',
        'الجاز',
        'محو',
        'الدق',
        'هيما',
        'خص',
        'مدحا',
        'بخ',
        'دب',
        'سماي',
        'نزو',
        'من',
        'إزك',
        'الحمرا',
        'بدب',
        'بهلا',
        'أد',
        'وادي بني خال',
        'صو',
        'القاب',
        'مصير',
        'الكامل والواف',
        'إبرا',
        'دما والطايي',
        'بدي',
        'المضيب',
        'جعلان بني بوحس',
        'جعلان بني بوعل',
        'صلال',
        'سد',
        'رخيو',
        'ضلكو',
        'طاق',
        'شليم وجزر الحلانيا',
        'مقش',
        'مربا',
        'ثمري',
        'ضن',
        'عبر',
        'البريم',
        'ينق',
        'محض',
        'صح',
        'الرستا',
        'شنا',
        'نخ',
        'وادي المعاو',
        'لو',
        'الخابور',
        'بركا',
        'العواب',
        'السوي',
        'المصنع',
        'مسق',
        'السي',
        'مطر',
        'صحا',
        'الجبل الأخض',
        'سنا',
        'السنين',
        'المزيونة'
      ],
      en: [
        'AL AMRAT',
        'BAWSHAR',
        'QURAYYAT',
        'AL JAZIR',
        'MAHAWT',
        'AD DUQM',
        'HAYMA',
        'KHASAB',
        'MADHA',
        'BUKHA',
        'DABA',
        'SAMAIL',
        'NIZWA',
        'MANAH',
        'IZKI',
        'AL HAMRA',
        'BIDBID',
        'BAHLA',
        'ADAM',
        'WADI BANI KHALID',
        'SUR',
        'AL QABIL',
        'MASIRAH',
        'AL KAMIL WA AL WAFI',
        'IBRA',
        'DAMA WA AT TAIYIN',
        'BIDIYAH',
        'AL MUDAYBI',
        'JAALAN BANI BU HASAN',
        'JAALAN BANI BU ALI',
        'SALALAH',
        'SADAH',
        'RAKHYUT',
        'DALKUT',
        'TAQAH',
        'SHALIM',
        'MUQSHIN',
        'MIRBAT',
        'THUMRAYT',
        'DANK',
        'IBRI',
        'AL BURAYMI',
        'YANQUL',
        'MAHADAH',
        'SAHAM',
        'AR RUSTAQ',
        'SHINAS',
        'NAKHAL',
        'WADI AL MAAWIL',
        'LIWA',
        'AL KHABURAH',
        'BARKA',
        'AL AWABI',
        'AS SUWAYQ',
        'AL MUSANAAH',
        'MUSCAT',
        'AS SEEB',
        'MUTRAH',
        'SOHAR',
        'Jabal Akhdar',
        'Sinaw',
        'Al Sinina',
        'Al Mazyona'
      ]
    },
    showPhoneNumberField: true,
    branding: {
      logo: '/img/signup-oman-logo.svg',
      image: '/img/signup-oman.svg',
      colour: '#1967fb'
    }
  },
  [ORGANISATIONS.INTERNAL_TESTING]: {
    studyName: 'Measuring Broadband Test Name',
    eulaLink: 'https://sktesting.com/eula',
    faqsLink: 'https://sktesting.com/faqs',
    regionsLabel: 'SignupForm.governorate',
    regionsSelectPlaceholder: 'SignupForm.selectYourGovernorate',
    regions: {
      ar: ['Region 1 ar', 'Region 2 ar', 'Region 3 ar'],
      en: ['Region 1 en', 'Region 2 en', 'Region 3 en'],
      fr: ['Region 1 fr', 'Region 2 fr', 'City 3 fr']
    },
    cities: {
      ar: ['City 1 ar', 'City 2 ar', 'City 3 ar'],
      en: ['City 1 en', 'City 2 en', 'City 3 en'],
      fr: ['City 1 fr', 'City 2 fr', 'City 3 fr']
    },
    showPhoneNumberField: true,
    showPostcodeField: true,
    showFibreAvailability: true,
    branding: {
      image: '/img/login.svg'
    }
  }
};
