import { CoreModule } from 'dynamsoft-core';
import { LicenseManager } from 'dynamsoft-license';
import 'dynamsoft-capture-vision-router';
import 'dynamsoft-barcode-reader';

export function setupDBR(): void {
  /* Our licence is an annual licence that allows us to use the
   * latest version of the software as long as we have maintenance in place.
   * */
  LicenseManager.initLicense(process.env.VUE_APP_BARCODE_READER_LICENSE_KEY);

  CoreModule.engineResourcePaths = {
    std: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.2.10/dist/',
    dip: 'https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.2.30/dist/',
    core: 'https://cdn.jsdelivr.net/npm/dynamsoft-core@3.2.30/dist/',
    license: 'https://cdn.jsdelivr.net/npm/dynamsoft-license@3.2.21/dist/',
    cvr: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.2.30/dist/',
    dbr: 'https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.2.10/dist/',
    dce: 'https://cdn.jsdelivr.net/npm/dynamsoft-camera-enhancer@4.0.3/dist/'
  };

  // Preload "LabelRecogznier" module for recognizing text. It will save time on the initial recognizing by skipping the module loading.
  CoreModule.loadWasm(['DBR']).catch((ex) => {
    const errMsg = ex.message || ex;
    console.error(errMsg);
  });
}
