export enum DIRECTION {
  UP = 'up',
  DOWN = 'down'
}

export enum ANOMALY_STATE {
  ONGOING = 'ongoing',
  NEW = 'new',
  CLOSED = 'closed'
}

export enum ANOMALY_STATE_UI {
  ACTIVE = 'active',
  CLOSED = 'closed'
}

export enum ANOMALY_TYPE {
  SAMPLE = 'sample',
  SUCCESS_RATE = 'successrate',
  MEASUREMENT = 'measurement',
  OUTAGE = 'outage'
}

export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'dddd, D MMMM';
