export interface GetDataUsageSum {
  array: DataUsageEvent[];
  endpoint?: string;
  timePeriod?: string;
  metric?: string;
}

export interface DataUsageEvent {
  endpoint: string;
  dataUsed: number;
  metric?: string;
  timePeriod?: string;
}

// returns the data used and calls made for the events matching the filters passed in
export const getDataUsageProperties = (
  events: DataUsageEvent[],
  filters: Record<string, string>,
  dataPrefix: string
): Record<string, string | number> => {
  const { endpoint, metric, timePeriod } = filters;
  const dataUsage: Record<string, string | number> = {};

  // add together all the dataUsage figures to get a total
  const sum = getDataUsageSum({ array: events, endpoint, metric, timePeriod });

  if (sum && sum > 0) {
    // create the object key based on the data received
    // e.g. [dataUsageBytes, metric_results, week, httpgetmt] => dataUsageBytes_metric_results_week_httpgetmt
    const keyPrefix = createDataUsageKey([
      dataPrefix,
      endpoint,
      timePeriod,
      metric
    ]);

    const keyTotal = keyPrefix + '_total';
    const keyCalls = keyPrefix + '_calls';
    dataUsage[keyTotal] = sum;

    // filter events to find only events that match the filters received and return the number found
    dataUsage[keyCalls] = events.filter((x) => {
      const { dataUsed = 0 } = x || {};
      const endpointMatch = endpoint ? x.endpoint === endpoint : true;
      const timePeriodMatch = timePeriod ? x.timePeriod === timePeriod : true;
      const metricMatch = metric ? x.metric === metric : true;
      const usedSomeData = dataUsed > 0;
      return endpointMatch && timePeriodMatch && metricMatch && usedSomeData;
    }).length;
  }

  return dataUsage;
};

// finds total dataUsed for all items in array passed in for items matching all filters
export const getDataUsageSum = (data: GetDataUsageSum): number | null => {
  const { array, endpoint, timePeriod, metric } = data || {};
  if (!array || array.length < 1) {
    return null;
  }

  const filtered = array.filter((x) => {
    const endpointMatch = endpoint ? x.endpoint === endpoint : true;
    const timePeriodMatch = timePeriod ? x.timePeriod === timePeriod : true;
    const metricMatch = metric ? x.metric === metric : true;
    return endpointMatch && timePeriodMatch && metricMatch;
  });

  return filtered.reduce((sum, item) => {
    sum += item?.dataUsed || 0;
    return sum;
  }, 0);
};

// creates string from array of strings passed in
export const createDataUsageKey = (names: string[]): string =>
  names
    ? names.reduce((res, name) => {
        if (!name || typeof name !== 'string') {
          return res;
        }

        return res ? `${res}_${name}` : name;
      }, '')
    : '';
