<template>
  <BaseModal
    :visible="isModalVisible"
    :title="$t('components.ecosystems.UpdatesModal.header')"
    @close="$store.commit('featureUpdates/TOGGLE_IS_MODAL_VISIBLE')"
  >
    <div>
      <p>
        <label>
          <input v-model="notify" type="checkbox" />
          {{ $t('components.ecosystems.UpdatesModal.notify') }}
        </label>
      </p>

      <div
        v-if="!allUpdates.length"
        class="all-read text-center u-margin-bottom-4x"
      >
        <img src="/img/sunflower.svg" />
        <h3>
          {{ $t('components.ecosystems.UpdatesModal.allRead') }}
        </h3>
        <p class="all-read__sub-text">
          {{ $t('components.ecosystems.UpdatesModal.allReadSub') }}
        </p>
      </div>

      <template v-else-if="allUpdates.length">
        <ul class="feature-updates-list">
          <li v-for="(update, updateIndex) in allUpdates" :key="update.id">
            <time class="text-muted">{{ getDate(update.createdAt) }}</time>
            <h3>{{ update.title }}</h3>
            <div v-html="update.description"></div>
            <hr v-if="updateIndex < allUpdates.length - 1" />
          </li>
        </ul>
      </template>
    </div>
    <template #footer>
      <BaseButton
        v-if="isMoreUpdatesLinkVisible"
        :loading="isFetching"
        data-test="load-more"
        @click.native="page++"
      >
        {{ $t('components.ecosystems.UpdatesModal.loadRead') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { BaseButton, BaseModal } from '@samknows/design-system';
import AuthService from '@/common/services/Auth';
import ZeusService from '@/common/services/Zeus';
import { prefStorage } from '@samknows/utils';
import viewTypes from '@/common/helpers/view-types';
import { formatDate } from '@/common/helpers/date-utils';

const userId = AuthService.getTokenPayload().sub;

export default {
  components: {
    BaseButton,
    BaseModal
  },
  props: {
    isModalVisible: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    page: 0,
    pagination: {},
    isFetching: false,
    allUpdates: [],
    notify: prefStorage.get('notify-updates', true)
  }),
  computed: {
    newUpdates() {
      return this.$store.state.featureUpdates.newUpdates;
    },
    isMoreUpdatesLinkVisible() {
      return (
        !this.page || this.pagination.current_page < this.pagination.total_pages
      );
    }
  },
  watch: {
    page() {
      this.getMoreUpdatesRead();
    },
    notify() {
      prefStorage.set('notify-updates', this.notify, true);
    }
  },
  mounted() {
    this.allUpdates.push(...this.newUpdates);
  },
  destroyed() {
    this.clearUpdates();
  },
  methods: {
    clearUpdates() {
      if (prefStorage.get('staff-warning', false)) {
        return;
      }

      const date = Date.now() / 1000;

      this.newUpdates.forEach((item) => {
        this.markUpdateRead(item.id, date);
      });

      this.$store.commit('featureUpdates/SET_NEW_UPDATES', []);
    },
    markUpdateRead(updateId, date) {
      ZeusService.update(`users/${userId}/feature_updates/${updateId}`, {
        last_read_date: date
      });
    },
    getMoreUpdatesRead() {
      this.isFetching = true;

      const updatesType = viewTypes.isConsumerView ? 'consumer' : 'enterprise';

      ZeusService.get(`users/${userId}/feature_updates/read`, {
        page: this.page,
        sorts: '-id',
        type: updatesType,
        per_page: 5
      }).then((res) => {
        this.isFetching = false;
        this.allUpdates.push(...res.data);
        this.pagination = res.meta.pagination;
      });
    },
    getDate(createdAt) {
      if (!createdAt) {
        return '';
      }

      const date = createdAt * 1000;
      return formatDate(date, 'LL');
    }
  }
};
</script>
