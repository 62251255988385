
import { defineComponent } from 'vue';
import { SvgIcon } from '@samknows/design-system';

export default defineComponent({
  components: {
    SvgIcon
  },
  data: () => ({
    year: new Date().getFullYear()
  })
});
