import ZendeskAPI from '@/common/services/ZendeskAPI';
import Vue from 'vue';

export const namespaced = true;

export const state = () => ({
  pageError: {
    status: 200,
    message: ''
  },
  section: {},
  category: {},
  breadcrumbs: [],
  searchResults: {},
  article: {},
  sectionsForCategory: [],
  articlesForSection: {},
  articlesForCategory: [],
  query: ''
});

export const mutations = {
  SET_PAGE_ERROR(state, error) {
    state.pageError = error;
  },

  RESET_PAGE_ERROR(state) {
    state.pageError = {
      status: 200,
      message: ''
    };
  },

  SET_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },

  SET_SECTION(state, section) {
    state.section = section;
  },

  SET_CATEGORY(state, category) {
    state.category = category;
  },

  SET_SEARCH_RESULTS(state, results) {
    state.searchResults = results;
  },

  SET_QUERY(state, query) {
    state.query = query;
  },

  SET_ARTICLE(state, article) {
    state.article = article;
  },

  SET_ARTICLES_FOR_SECTIONS(state, { sectionId, articles }) {
    Vue.set(state.articlesForSection, sectionId, articles);
  },

  SET_SECTIONS_FOR_CATEGORY(state, sections) {
    state.sectionsForCategory = sections;
  },

  SET_ARTICLES_FOR_CATEGORY(state, articles) {
    state.articlesForCategory = articles;
  }
};

export const actions = {
  fetchSection({ commit, state }, sectionId) {
    if (state.section.id === sectionId) {
      return Promise.resolve();
    }
    return ZendeskAPI.getSection(sectionId).then((data) => {
      commit('SET_SECTION', data.section);
    });
  },

  fetchCategory({ commit, state }, categoryId) {
    if (state.category.id === categoryId) {
      return Promise.resolve();
    }
    return ZendeskAPI.getCategory(categoryId).then((data) => {
      commit('SET_CATEGORY', data.category);
    });
  },

  async search({ commit }, { query, pageNumber, categoryId }) {
    if (!query) {
      commit('SET_SEARCH_RESULTS', {});
      return;
    }
    const results = await ZendeskAPI.search(query, pageNumber, categoryId);
    commit('SET_SEARCH_RESULTS', results);
  },

  async fetchArticle({ commit, state }, articleId) {
    if (state.article.id === articleId) {
      return Promise.resolve();
    }
    const { article } = await ZendeskAPI.getArticle(articleId);
    commit('SET_ARTICLE', article);
  },

  async fetchSectionsForCategory({ commit }, categoryId) {
    const { sections } = await ZendeskAPI.getSectionsForCategory(categoryId);

    commit('SET_SECTIONS_FOR_CATEGORY', sections);
    for (const section of sections) {
      const { articles } = await ZendeskAPI.getArticlesForSection(section.id);

      commit('SET_ARTICLES_FOR_SECTIONS', {
        sectionId: section.id,
        articles
      });
    }
  },

  async fetchArticlesForSection({ commit }, sectionId) {
    const { articles } = await ZendeskAPI.getArticlesForSection(sectionId);

    commit('SET_ARTICLES_FOR_SECTIONS', {
      sectionId: sectionId,
      articles
    });
  },

  async fetchArticlesForCategory({ commit }, categoryId) {
    const { articles } = await ZendeskAPI.getArticlesForCategory(categoryId);

    commit('SET_ARTICLES_FOR_CATEGORY', articles);
  }
};
