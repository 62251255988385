export enum ORGANISATIONS {
  COM_COM = 'c6595315-631d-11e8-826d-002590e569f4', // MB New zealand
  ACCC = '6c41ccff-f06f-11e7-83e5-002590e569f4', // MB Australia
  CST = 'b6f3409a-2fdd-11e7-92a9-002590e569f4', // Meqyas
  TRA = '4bb132fc-7785-11eb-a2c4-ecf4bbe6bbe0', // Oman
  INTERNAL_TESTING = 'internal-testing-fake-own-id' // Internal form to test all fields at once
}

export const DEFAULT_EULA_LINK = 'https://samknows.com/eula';
export const DEFAULT_FAQS_LINK = 'https://samknows.one/hc/en-gb/';
