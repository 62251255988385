import Vue from 'vue';

Vue.directive('autogrow', {
  inserted(el) {
    const autoHeight = (): void => {
      el.style.height = '5px';
      el.style.height = `${Math.max(50, el.scrollHeight + 2)}px`;
    };

    autoHeight();
    el.addEventListener('keyup', autoHeight);
  }
});
