import { Preset } from '@/common/services/presets/types';

export enum DASHBOARD_COMPONENT_TYPE {
  GRAPH = 'graph',
  TEXT = 'text',
  HEADER = 'header'
}

export interface DashboardChildren {
  component: DASHBOARD_COMPONENT_TYPE;
  meta: Partial<Preset> & {
    error?: string;
    text?: string;
  };
  size?: number;
  type?: 'panel' | string;
}

enum DASHBOARD_CONTENTS_TYPE {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export interface DashboardContents {
  size: number;
  type: DASHBOARD_CONTENTS_TYPE;
  children: (DashboardContents & DashboardChildren)[];
}

export enum DASHBOARD_TYPE {
  WAR_BOARD = 'warboard',
  REPORT = 'report'
}

export interface Dashboard {
  id: string;
  name: string;
  owner?: string;
  ownerId?: string;
  type: DASHBOARD_TYPE;
  contents: DashboardContents & DashboardChildren[];
}

export interface CafReport {
  finalised: boolean;
  fromDate: string;
  toDate: string;
  passRate: number;
  expectedTests: number;
  passingTests: number;
  failingTests: number;
  missingTests: number;
}
