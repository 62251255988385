var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{staticClass:"account",class:{ 'account--hide-image': _vm.hideImage }},[_c('section',{staticClass:"account__column account__column--cta"},[(!_vm.resetSuccess)?_c('div',[_c('header',{staticClass:"account__header"},[_c('h1',{staticClass:"account__title"},[_vm._v("\n          "+_vm._s(_vm.isPasswordExpired
              ? _vm.$t('common.password_expired')
              : _vm.$t('pages.passwordReset.header'))+"\n        ")]),_vm._v(" "),(!_vm.isLoading)?_c('div',[(!_vm.isValidToken)?_c('p',{staticClass:"account__subtitle"},[_vm._v("\n            "+_vm._s(_vm.$t('pages.passwordReset.textInvalidToken'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.isPasswordExpired && _vm.isValidToken)?_c('p',{staticClass:"account__subtitle",attrs:{"data-cy":"password-expired-desc"}},[_vm._v("\n            "+_vm._s(_vm.$t('pages.passwordExpired.description'))+"\n          ")]):_vm._e(),_vm._v(" "),(
              _vm.isPasswordExpired &&
              _vm.isValidToken &&
              parseInt(_vm.passwordExpiryTime, 10) > 0
            )?_c('p',{staticClass:"account__subtitle u-margin-top-4x u-color-primary-text",attrs:{"data-cy":"password-expired-time"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('pages.passwordExpired.expiryTime', { passwordExpiryTime: _vm.passwordExpiryTime })))])]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('BaseSpinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"u-margin-top-12x"}),_vm._v(" "),(_vm.isValidToken && !_vm.isLoading)?_c('form',{attrs:{"data-cy":"password-reset-form"},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword(_vm.userId, _vm.token, _vm.password)}}},[_c('PasswordInput',{staticClass:"u-margin-bottom-12x",attrs:{"id":"new-password","label":_vm.$t('pages.passwordReset.textResetPassword'),"show-errors":_vm.showErrors,"is-valid":_vm.isPasswordValid,"error":_vm.error,"data-cy":"passing-password-input"},on:{"update:isValid":function($event){_vm.isPasswordValid=$event},"update:is-valid":function($event){_vm.isPasswordValid=$event}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('BaseButton',{staticClass:"u-margin-bottom-4x",attrs:{"data-cy":"reset-password-submit","loading":_vm.isLoadingButton,"type":"submit"}},[_vm._v("\n          "+_vm._s(_vm.isPasswordExpired
              ? _vm.$t('common.btnCreate')
              : _vm.$t('pages.passwordReset.buttonResetPassword'))+"\n        ")])],1):_vm._e(),_vm._v(" "),_c('BaseButton',{attrs:{"to":"/login","variant":"link"}},[_vm._v("\n        "+_vm._s(_vm.$t('common.linkBackToLogin'))+"\n      ")])],1):_c('div',[_c('header',{staticClass:"account__header"},[_c('h1',{staticClass:"account__title"},[_vm._v("\n          "+_vm._s(_vm.$t('pages.passwordReset.passwordResetDone'))+"\n        ")])]),_vm._v(" "),_c('BaseAlert',{staticClass:"u-margin-bottom-4x",attrs:{"title":_vm.$t('common.success'),"data-cy":"password-reset-success","variant":"success","value":true}},[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('pages.passwordReset.alertPasswordResetSuccess'))+"\n        ")])]),_vm._v(" "),_c('BaseButton',{attrs:{"to":"/login","data-cy":"back-to-login","loading":_vm.isLoadingButton}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.passwordReset.backToLogin'))+"\n      ")])],1)]),_vm._v(" "),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"account__column account__column--showcase"},[_c('img',{staticClass:"account__img",attrs:{"src":"/img/reset-pass.svg"}})])
}]

export { render, staticRenderFns }