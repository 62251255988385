import { Route } from 'vue-router';
import AuthService from '@/common/services/Auth';

import { hasSamlEnabled } from '@/common/helpers/single-sign-on';

import PageLockedAccount from '@/features/account/pages/LockedView.vue';
import SsoVerifyError from '@/features/account/pages/SsoVerifyError.vue';
import PageLogin from '@/features/account/pages/LoginView.vue';
import PageLogout from '@/features/account/pages/LogoutView.vue';
import PageRequestPasswordReset from '@/features/account/pages/RequestPasswordReset.vue';
import PagePasswordReset from '@/features/account/pages/PasswordReset.vue';
import PageRedirect from '@/features/account/pages/RedirectView.vue';
import PageSAMLRedirect from '@/features/account/pages/SAMLRedirect.vue';
import PageSAMLLogin from '@/features/account/pages/SAMLLogin.vue';
import PageSAMLLogout from '@/features/account/pages/SAMLLogout.vue';

export default [
  {
    path: '/login',
    name: 'PasswordLogin',
    component: PageLogin,
    meta: {
      guard: () => !AuthService.userAuthed()
    }
  },
  {
    path: '/request-password',
    component: PageRequestPasswordReset,
    meta: {
      guard: () => !AuthService.userAuthed()
    }
  },
  {
    path: '/reset-password/:userId/:token',
    component: PagePasswordReset,
    meta: {
      guard: () => !AuthService.userAuthed()
    },
    props: true
  },
  {
    name: 'PasswordExpired',
    path: '/expired-password/:userId/:token',
    component: PagePasswordReset,
    meta: {
      guard: () => !AuthService.userAuthed()
    },
    props: (route: Route) => ({ isPasswordExpired: true, ...route.params })
  },
  {
    path: '/logout',
    component: hasSamlEnabled() ? PageSAMLLogout : PageLogout
  },
  {
    path: '/redirect',
    component: PageRedirect
  },
  {
    path: '/saml-redirect',
    name: 'samlRedirect',
    component: PageSAMLRedirect,
    meta: {
      guard: () => {
        // Only allow access if on SAML supported client subdomain and not logged in
        return hasSamlEnabled() && !AuthService.userAuthed();
      }
    }
  },
  {
    path: '/saml-login',
    name: 'samlLogin',
    component: PageSAMLLogin,
    meta: {
      guard: () => {
        return hasSamlEnabled() && AuthService.userAuthed();
      }
    }
  },
  {
    path: '/locked',
    component: PageLockedAccount,
    meta: {
      guard: () => !AuthService.userAuthed()
    }
  },
  {
    path: '/verify-error',
    component: SsoVerifyError,
    name: 'samlVerifyError',
    meta: {
      guard: () => hasSamlEnabled() && !AuthService.userAuthed()
    }
  }
];
