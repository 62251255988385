import SkConfig from '../config';
import { prefStorage, EventBus, Http } from '@samknows/utils';

import Vue from 'vue';
import { AccessiblePanel } from './Auth';

const API_ENDPOINT = SkConfig.api.zeus;

/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] types in this file are all `any`

// TODO Please add types in this file to replace `any` if you touch it!
export default {
  getPackagesForIsp(ispId: number, filter = { per_page: '250' }) {
    const params = { ...filter };
    return Http.get<any>(API_ENDPOINT, `/isps/${ispId}/packages`, params, {
      newErrors: true
    });
  },
  getData(page = '1', search = {}, filter = {}, perPage = '50') {
    const params = {
      page,
      ...search,
      ...filter,
      per_page: perPage
    };
    return Http.get<any>(API_ENDPOINT, `/signups`, params, {
      newErrors: true
    });
  },
  getMetadata() {
    return Http.get<any>(API_ENDPOINT, `/signups/metadata`, undefined, {
      newErrors: true
    });
  },
  updateSignup(id: number, body: Record<string, unknown>) {
    return Http.request<any>(`${API_ENDPOINT}/signups/${id}`, {
      method: 'PUT',
      newErrors: true,
      body: JSON.stringify(body)
    });
  },

  prioritise(ids: any, panel: any) {
    return Http.request<any>(`${API_ENDPOINT}/signups/prioritise`, {
      method: 'PUT',
      body: JSON.stringify({
        signups: ids,
        panel
      })
    });
  },

  approve(ids: any, panel: any) {
    return Http.request<any>(`${API_ENDPOINT}/signups/accept`, {
      method: 'PUT',
      body: JSON.stringify({
        signups: ids,
        panel
      })
    });
  },

  reject(ids: any) {
    return Http.request<any>(`${API_ENDPOINT}/signups/reject`, {
      method: 'PUT',
      newErrors: true,
      body: JSON.stringify({
        signups: ids
      })
    });
  },

  autocomplete(
    key: any,
    value: any,
    panelId = prefStorage.get<AccessiblePanel>('panel').pid
  ) {
    return Http.request<any>(
      `${API_ENDPOINT}/autocomplete/metadata/${panelId}`,
      {
        method: 'POST',
        newErrors: true,
        body: JSON.stringify({ key, value })
      }
    ).then((data) => {
      // Work around a backend bug
      if (!data.data) {
        data.data = [];
      }

      return data;
    });
  },
  uploadLogo(
    formData: any,
    panelId = prefStorage.get<AccessiblePanel>('panel').pid
  ) {
    return Http.request<any>(`${API_ENDPOINT}/panels/${panelId}/logo`, {
      method: 'POST',
      newErrors: true,
      body: formData
    });
  },
  get(type: string, params?: any) {
    return Http.get<any>(API_ENDPOINT, `/${type}`, params, {
      newErrors: true
    });
  },

  update<T = unknown>(type: string, data: any): Promise<T> {
    return Http.request<T>(`${API_ENDPOINT}/${type}`, {
      method: 'PUT',
      newErrors: true,
      body: JSON.stringify(data)
    });
  },
  create(type: string, data?: any) {
    return Http.request<any>(`${API_ENDPOINT}/${type}`, {
      method: 'POST',
      body: JSON.stringify(data),
      newErrors: true,
      getFullResponse: true
    }).then((res) => ({ location: res.headers.get('Location') }));
  },
  delete(type: string) {
    return Http.request<any>(`${API_ENDPOINT}/${type}`, {
      method: 'DELETE',
      newErrors: true
    });
  },
  getCsv(type: string, params: any) {
    return Http.get<any>(API_ENDPOINT, `/${type}`, params, {
      headers: {
        Accept: 'text/csv'
      },
      newErrors: true
    }).then((response) => {
      if (response.code === 'EXPORTING_CSV') {
        EventBus.$emit('toast:show', {
          content: Vue.t(
            'components.pages.enterprise.Admin.Metadata.csvExporting'
          )
        });
        return response;
      }
      const anchor = document.createElement('a');
      anchor.setAttribute('href', response.data.url);
      anchor.setAttribute('download', 'data.csv'); // file name not actually used
      document.body.appendChild(anchor);

      anchor.click();

      setTimeout(() => {
        document.body.removeChild(anchor);
      });

      return response;
    });
  },
  uploadCsv(type: string, csv: any) {
    return Http.request<any>(`${API_ENDPOINT}/${type}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/csv'
      },
      body: csv,
      newErrors: true
    });
  },
  gdprRemoval(email: string) {
    return Http.request<any>(`${API_ENDPOINT}/gdpr-removal/${email}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      newErrors: true
    });
  }
};
