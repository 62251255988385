import SkConfig from '@/common/config';
import { Metric } from '@/common/global.interfaces';
import { Filterables, Splittables } from '@/common/services/metaData';
import { Http, RequestOptions } from '@samknows/utils';

export interface GenerateFileData {
  url: string;
}

type DataApiMetaData = Partial<Metric> | string[] | Filterables | Splittables;

export default {
  getMetadata(endpoint: string, panelId: number): Promise<DataApiMetaData> {
    return Http.request(
      `${SkConfig.api.data_api}/web-user/panel/${panelId}/${endpoint}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  },

  generateFileURL(
    body: unknown,
    panelId: number,
    endpoint: string,
    options: RequestOptions = {}
  ): Promise<GenerateFileData> {
    return Http.request(
      `${SkConfig.api.data_api}/web-user/panel/${panelId}/${endpoint}_download`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Accept: 'text/csv',
          'Content-Type': 'application/json'
        },
        ...options
      }
    );
  }
};
