
import { defineComponent } from 'vue';

export default defineComponent({
  metaInfo() {
    return {
      title: this.$t('pages.verifyError.pageTitle')
    };
  }
});
