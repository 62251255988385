import { AUTHENTICATION_METHOD } from '@/common/helpers/single-sign-on';
import { GRANT_SECTION } from '@/features/account/constants';
import { UnitMetric } from '@/features/unit-tests-view/types';
import type { Unit as InstantTestUnit } from '@samknows/instant-test';
import dayjs from 'dayjs';
import type { Location } from 'vue-router';

interface AccountSettings {
  lockout_enabled: boolean;
  lockout_attempts: number;
  lockout_period_seconds: number;
  pass_expiry_enabled: boolean;
  pass_expiry_period_days: number;
  locked_at: number;
}

export interface Organisation {
  front_name: string;
  id: number;
  name: string;
  owner_id: string;
  vat_number: string;
  account_settings: AccountSettings;
  authentication: {
    name: AUTHENTICATION_METHOD;
  };
}

export interface Metric {
  key: MetricKeys;
  unit: string;
  group: string;
  type: string;
  direction: string;
  untrimmed_keys: string[];
  trimmed_keys: string[];
  rounding_precision: number;
  can_split: boolean;
}

export type GroupedUnitMetrics = Record<string, UnitMetric[]>;

export interface MetaPagination {
  total: number;
  count: number;
  per_page: number;
  page: number;
  total_pages: number;
}

export interface PromiseMeta {
  pagination: Partial<MetaPagination>;
}

export interface PromiseResponse<Data, Code = string> {
  code?: Code;
  message?: string;
  data: Data;
  meta?: PromiseMeta;
}

export enum ASYNC_STATE {
  ERROR = 'error',
  EMPTY = 'empty',
  RESOLVED = '',
  LOADING = 'loading',
  SUCCESS = 'success'
}

export interface GenericData<T> {
  data: T;
}

export interface TokenPayload {
  sub: number;
}

interface Isp {
  full_name: string;
  front_name?: string;
}

interface Package {
  name: string;
  front_name?: string;
}

interface UnitAddress {
  city: string;
  postcode: string;
}

interface LmapConfig {
  id: number;
}

enum AGENT_TYPE {
  MOBILE = 'mobile',
  UNIT = 'unit',
  WEB = 'web'
}

export interface Panel {
  front_name: string;
  pid: number;
  type: AGENT_TYPE;
}

export interface TimeRange {
  to: string; // ISO Datestring
  from: string;
}

export interface Unit extends InstantTestUnit {
  mac?: number;
  isp: GenericData<Isp> & string;
  package: GenericData<Package> & string;
  address?: GenericData<UnitAddress>;
  lmap_config: GenericData<LmapConfig> & number;
  is_tt_compatible?: boolean;
  error?: boolean;
  environment?: {
    data: {
      duplex: string;
      firmware_version: string;
      gateway_ip: string;
      gateway_mac: string;
      lan_ip: string;
      last_seen: number;
      link_speed: number;
      public_ip: string;
      public_ip6: string;
      public_port6: number;
      uptime: number;
      wan_ip: string;
      wan_ip6: string;
    };
  };
}

export interface APIDataMeta {
  totalResultsCount?: number;
}

export interface AuthTokenPayload {
  accessibles?: { units: { unitId: number; panel: number }[] };
  context?: string;
  exp?: number; // Timestamp
  iat?: number; // Timestamp
  issuer?: string;
  jti?: string;
  organisation_id?: number;
  organisation_owner_id?: string; // uuid
  owner_id?: string; // uuid
  preferred_locale?: string;
  signup?: unknown[];
  sub?: number;
  super_admin?: boolean;
  units_remaining?: number;
  user_id?: string;
}

export interface FilterValue {
  id: string;
}

export interface Filter {
  id: string;
  filterValues: (string | number | FilterValue)[];
  filterType: number;
  pointOrAgent?: 'point' | 'agent';
  input?: string;
  affect?: string;
  meta?: {
    type: 'int';
  };
  bucketThreshold?: number;
}

export enum CHART_TYPE {
  // CHARTS
  CDF = 'cdf',
  RAW = 'raw',
  AGGREGATE = 'aggregate',
  BOXPLOT = 'boxplot',
  TABLE = 'table',
  BAR_CHART = 'column',
  STACKED_BAR_CHART = 'column_stacked',
  LINE = 'aggregate',

  // Generic term for the types above
  GRAPH = 'graph',

  // MAPPING
  MAP = 'map',

  //  Raw data maps
  LAT_LON = 'lat_lon', // BE value
  GEO_HEATMAP = 'geo_heatmap', // FE value

  //  Geographic heatmap
  RAW_MAP = 'raw_map', // BE Value
  RAW_MOBILE = 'raw_mobile', // FE value

  // OTHER
  TIME_SERIES = 'time_series' // Deprecated on backend, kept in for safety
}

export enum MetricKeys {
  DOWNLOAD_ST = 'httpgetst',
  DOWNLOAD_MT = 'httpgetmt',
  DOWNLOAD_ST_MT = 'httpget',
  UDP_DOWNLOAD = 'udp_accelerated_dl',
  UPLOAD_ST = 'httppostst',
  UPLOAD_MT = 'httppostmt',
  UPLOAD_ST_MT = 'httppost',
  UDP_UPLOAD = 'udp_accelerated_ul',
  LATENCY = 'udpLatency',
  PACKET_LOSS = 'udpPacketLoss',
  UDP_DISCONNECTS = 'udpDisconnects',
  MOBILE_SIGNAL_STRENGTH = 'mobile_signal_strength',
  DISCONNECTION = 'disconnection',
  CONNECTION_COUNT = 'connection',
  ACTIVE_AGENT_COUNT = 'active_agent_count',
  SKIPPED_TESTS = 'skipped_tests',
  WEB_BASED_JITTER = 'web_jitter',
  RESPONSIVENESS = 'responsiveness'
}

export interface MeasurementFormDataBase {
  date: {
    from: string;
    to: string;
  };
  time?: {
    from: string;
    to: string;
  };
  normalised: boolean;
  prefilter: boolean;
  panel: number;
  splits?: string[];
  filters?: Filter[];
  splitData?: boolean;
  chartType?: CHART_TYPE;
  timeAggregation?: 'hourly' | 'daily';
  mapAggregation?: 'country' | 'province' | 'landmark' | 'city';
  localTargetSetResultsOnly?: boolean;
  enhancedMetadata?: boolean;
  agent_agg?: boolean;
  plotValue?: string;
  test?: string;
}

// Type for request payload sent to BE API
export interface AnalyticsFormData extends MeasurementFormDataBase {
  metric?: MetricKeys;
  metrics: (Partial<Metric> | MetricKeys)[];
}

// Type for form data generated via measurement builder form
export interface MeasurementBuilderFormData extends MeasurementFormDataBase {
  metric?: Partial<Metric> | MetricKeys;
  metrics: Partial<Metric>[];
  sortBy?: string;
}

export interface HighchartsData {
  x: Date;
  y: number;
  data: Record<string, unknown>;
  marker?: {
    enabled: boolean;
  };
}

export interface AnalyticsMetricMetadata {
  metricKey: MetricKeys;
  metricUnit: string;
}

export interface AnalyticsChartData {
  data: HighchartsData[];
  type: string;
  yAxis: number;
  boostThreshold: number;
  canViewPoint: boolean;
  formData: MeasurementBuilderFormData;
  metricMetadata: AnalyticsMetricMetadata;
  name: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add types here
  requestData: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add types here
  responseData: any;
  series: number;
  showInLegend: boolean;
  splitGroup: AnalyticsSplitGroup;
  turboThreshold: number;
  marker?: {
    enabled: boolean;
  };
}

export interface HighChartYAxisMetricUnit {
  metric: {
    key: MetricKeys;
    unit: string;
  };
  yLabel: string;
  unit: string;
}

export interface MetricDataPoint {
  mean?: number;
  failure_rate?: number;
  success_rate?: number;
  value?: number;
  metricValue?: number;
  agentCount?: number;
  sampleCount?: number;
  agent?: number;
  unit?: number;
  percentiles?: Record<string, number>;
  median?: number;
  dtime?: string; // datetime string
  'location.lat'?: number;
  'location.lon'?: number;
  perc_95?: number;
  caf_tier_download_speed?: number;
  caf_tier_upload_speed?: number;
  caf_tier_name?: string;
}

export interface AnalyticsSplitGroup {
  splitIdentifier: number | MetricKeys;
  splitLabel: string;
  splitType: string;
}

export interface AnalyticsDataList {
  name?: string;
  metricData: MetricDataPoint[];
  metricMetadata: AnalyticsMetricMetadata;
  splitGroup: AnalyticsSplitGroup[];
}

interface Breadcrumb {
  name: string;
  to?: string | Location;
}
export type Breadcrumbs = Breadcrumb[];

export interface DateFilterPeriod extends Segment {
  date: string;
  text: string;
}

export interface UnitOfflineStatus {
  offline: boolean;
  lastSeenOnline: dayjs.Dayjs | null;
  offlineMessage: string;
  id: number | null;
  isUnreliable: boolean;
}

export interface GrantPermission {
  panel: number;
  organisation_id: number;
  data_subscription_id: number;
}

export interface LmapConfigResponse {
  id: number;
  meta?: {
    data: {
      assigned_agents: string;
    };
  };
  name: string;
  owner: string;
}

export type Grants = Partial<
  Record<GRANT_SECTION, Record<string, GrantPermission[]>>
>;

export enum SORTING {
  DESC = 'desc',
  ASC = 'asc',
  NOT_SORTED = ''
}

export interface Segment {
  label?: string;
  icon?: string;
}

export interface PanelMetadata {
  filterable: boolean;
  id: string;
  identifier: string;
  splittable: boolean;
  type: string;
}

export enum FEATURE_FLAGS {
  CAF_SHOW_SELF_SERVE_EXPORT = 'caf-show-self-serve-export',
  CC_AREA_OUTAGE_ALERT = 'cc-area-outage-alert',
  MULTIPLE_METRIC_LOADING = 'multiple-metric-loading',
  NEUTRAL_UI_IMPROVEMENTS_V1 = 'neutral-ui-improvements-v1',
  SHOW_ACTIVE_AGENT_COUNT_METRIC = 'show-active-agent-count-metric',
  SHOW_BETA_BANNER = 'show-fault-finder-beta-banner',
  SHOW_BETA_CALL_CTA = 'show-fault-finder-beta-call-cta',
  SHOW_BETA_FEEDBACK_CTA = 'show-fault-finder-beta-feedback-cta',
  SHOW_GOOGLE_DRIVE_CLOUD_STORAGE_METRICS = 'show-google-drive-cloud-storage-metrics',
  SIDEBAR_IMPROVEMENTS = 'sidebar-improvements',
  SHOW_SKIPPED_TESTS_METRIC = 'show-skipped-tests-metric',
  SHOW_WEB_BASED_JITTER_METRIC = 'show-web-based-jitter-metric',
  SHOW_DEPRECATION_BANNER = 'show-deprecation-banner'
}
