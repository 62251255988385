import * as Sentry from '@sentry/browser';
import AuthService from '@/common/services/Auth';
import {
  isPipeline,
  getGlobalEventProperties,
  getProfileProperties
} from '@/common/helpers/tracking-utils';

export function setSentryUserIdentity(): void {
  const userId = AuthService.userId;
  if (isPipeline() || !userId) {
    return;
  }
  const profileProperties = getProfileProperties();
  Sentry.setUser({ id: `${userId}` });

  for (const key in profileProperties) {
    Sentry.setTag(key, profileProperties[key]);
  }
}

export function setSentryTagForGlobalEventProperties(): void {
  if (isPipeline()) {
    return;
  }
  const eventProperties = getGlobalEventProperties();
  for (const key in eventProperties) {
    Sentry.setTag(key, eventProperties[key]);
  }
}

export function resetSentryUserIdentity(): void {
  Sentry.setUser(null);
}
