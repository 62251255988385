import {
  ActionTree,
  GetterTree,
  Module as VuexModule,
  MutationTree
} from 'vuex';
import type { RootState } from '@/common/store';
import { getCollections } from '@/features/anomaly-detection/services/AnomalyDetection';
import { Collection } from '../types';
import { ANOMALY_STATE_UI } from '../constants';
import { PromiseMeta } from '@/common/global.interfaces';

interface AnomalyDetectionState {
  collections: {
    data: Collection[];
    state: string;
    anomalyState: ANOMALY_STATE_UI;
    meta: PromiseMeta;
    pagination: {
      perPage: 20;
      currentPage: 1;
    };
  };
  panelId: number;
  searchText: string;
  isSearching: boolean;
}

interface CollectionsPayload {
  anomalyState: ANOMALY_STATE_UI;
  panelId: number;
  searchText: string;
  page?: number;
}

const anomalyDetectionState: AnomalyDetectionState = {
  collections: {
    data: [],
    state: '',
    anomalyState: undefined,
    meta: undefined,
    pagination: {
      perPage: 20,
      currentPage: 1
    }
  },
  panelId: undefined,
  searchText: '',
  isSearching: false
};

const getters: GetterTree<AnomalyDetectionState, RootState> = {
  alreadyFetched(state: AnomalyDetectionState) {
    return (
      anomalyState: ANOMALY_STATE_UI,
      searchText: string,
      panelId: number
    ) => {
      return (
        state.collections.data.length > 0 &&
        state.collections.anomalyState === anomalyState &&
        state.searchText === searchText &&
        state.panelId === panelId
      );
    };
  },
  totalCollections(state: AnomalyDetectionState): number {
    return state.collections.meta?.pagination?.total;
  }
};

const mutations: MutationTree<AnomalyDetectionState> = {
  setCollectionsData(state: AnomalyDetectionState, collections: Collection[]) {
    state.collections.data = collections;
  },
  setCollectionsState(state: AnomalyDetectionState, collectionState: string) {
    state.collections.state = collectionState;
  },
  setCollectionsMeta(state: AnomalyDetectionState, meta: PromiseMeta) {
    state.collections.meta = meta;
  },
  setCollectionsAnomalyState(
    state: AnomalyDetectionState,
    anomalyState: ANOMALY_STATE_UI
  ) {
    state.collections.anomalyState = anomalyState;
  },
  setSearchText(state: AnomalyDetectionState, searchText: string) {
    state.searchText = searchText;
  },
  setPanelId(state: AnomalyDetectionState, panelId: number) {
    state.panelId = panelId;
  },
  increasePaginationCurrentPage(state: AnomalyDetectionState) {
    state.collections.pagination.currentPage++;
  },
  resetPaginationCurrentPage(state: AnomalyDetectionState) {
    state.collections.pagination.currentPage = 1;
  },
  setSearchingFlag(state: AnomalyDetectionState, isSearching: boolean) {
    state.isSearching = isSearching;
  }
};

const actions: ActionTree<AnomalyDetectionState, RootState> = {
  async fetchCollections(
    { commit, state, rootState },
    payload: CollectionsPayload
  ) {
    const { anomalyState, searchText } = payload;
    const panelId = rootState.panel.pid;

    try {
      if (
        state.collections.anomalyState !== anomalyState ||
        state.isSearching ||
        state.panelId !== panelId
      ) {
        commit('setCollectionsData', []);
        commit('setCollectionsMeta', undefined);
        commit('resetPaginationCurrentPage');
        commit('setSearchingFlag', false);
      }
      commit('setCollectionsState', 'loading');
      commit('setCollectionsAnomalyState', anomalyState);
      commit('setSearchText', searchText);
      commit('setPanelId', panelId);

      const response = await getCollections(
        anomalyState,
        panelId,
        searchText,
        state.collections.pagination
      );
      const { data, meta } = response ?? {};

      commit('setCollectionsData', [...state.collections.data, ...data]);
      commit('setCollectionsState', '');
      commit('setCollectionsMeta', meta);
    } catch (error) {
      if ((error as Error).name !== 'AbortError') {
        commit('setCollectionsState', 'error');
      }
      console.error(error);
    }
  }
};

export default {
  namespaced: true,
  getters,
  state: anomalyDetectionState,
  mutations,
  actions
} as VuexModule<AnomalyDetectionState, RootState>;
