import { RouteConfig } from 'vue-router';
import AuthService from '@/common/services/Auth';
import { prefStorage } from '@samknows/utils';
import viewTypes from '@/common/helpers/view-types';
import { GRANT_SECTION } from '../account/constants';

const UnitTestsView = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "unit-tests-view"*/ '@/features/unit-tests-view/pages/UnitTestsView.vue'
  );
const UnitTests = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "unit-tests-view"*/ '@/features/unit-tests-view/pages/UnitTests.vue'
  );
const RealSpeedTests = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "unit-tests-view"*/ '@/features/unit-tests-view/pages/RealSpeedTests.vue'
  );

const unitTestViewPermissionCheck = (): Promise<unknown> => {
  return AuthService.hasPermissions(GRANT_SECTION.UNIT, 'test_view');
};

const enterpriseRoutes: RouteConfig = {
  path: '/units/:unitId',
  component: UnitTestsView,
  props: true,
  redirect: '/units/:unitId/test-results',
  meta: {
    type: 'single',
    guard: () => unitTestViewPermissionCheck(),
    userArea: 'constant-care'
  },
  children: [
    {
      path: 'test-results',
      name: 'ConstantCareUnitTests',
      component: UnitTests,
      props: true,
      meta: {
        hasBackground: true,
        guard: () => unitTestViewPermissionCheck(),
        userArea: 'constant-care'
      }
    },
    {
      path: 'customer-results',
      name: 'ConstantCareUnitRealSpeedResults',
      component: RealSpeedTests,
      props: true,
      meta: {
        hasBackground: true,
        guard: () => unitTestViewPermissionCheck(),
        userArea: 'constant-care'
      }
    }
  ]
};

const consumerRoute: RouteConfig = {
  path: '/dashboard',
  component: UnitTestsView,
  children: [
    {
      path: '',
      redirect: 'whitebox-tests'
    },
    {
      path: '/instant-test',
      name: 'instantTest',
      redirect: { name: 'CustomerDashboardWhiteboxTests' }
    },
    {
      path: 'whitebox-tests',
      name: 'CustomerDashboardWhiteboxTests',
      component: UnitTests,
      meta: {
        hasBackground: true,
        guard: () => AuthService.userAuthed() && viewTypes.isConsumerView
      }
    },
    {
      path: 'realspeed-tests',
      name: 'CustomerDashboardRealSpeedTests',
      component: RealSpeedTests,
      meta: {
        hasBackground: true,
        guard: () => {
          const activeView = viewTypes.activeView;

          return (
            AuthService.userAuthed() &&
            viewTypes.isConsumerView &&
            prefStorage.get<boolean>(
              `instant-testable-${activeView.data.unitId}`,
              false
            )
          );
        }
      }
    }
  ]
};

export default viewTypes.isEnterpriseView
  ? [enterpriseRoutes]
  : [consumerRoute];
