import AuthService from '@/common/services/Auth';
import SignupForm from '@/features/volunteer-signup/pages/SignupForm.vue';
const PageProfile = () =>
  import('@/features/volunteer-signup/pages/ProfileView.vue');

export default [
  {
    path: '/signup/:owner/:country?',
    props: true,
    component: SignupForm
  },
  {
    path: '/profile',
    component: PageProfile,
    meta: {
      guard: (accessibles) =>
        AuthService.userAuthed() && !accessibles?.units?.length
    }
  }
];
