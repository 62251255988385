import AuthService from '@/common/services/Auth';
import { Route } from 'vue-router';

const PageEnterpriseDataSubFeatureSubs = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "xenia"*/ '@/features/data-subscriptions/pages/DataSubFeatureSubs.vue'
  );

const PageEnterpriseDataSubGroups = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "xenia"*/ '@/features/data-subscriptions/pages/DataSubGroups.vue'
  );
const xeniaDefaultRoute = (): string =>
  ['units', 'users', 'signups', 'organisations', 'panels', 'isps'].find(
    (category) => AuthService.hasPermissions('Administration', category)
  );

export default [
  {
    path: '/xenia',
    component: () =>
      import(/* webpackChunkName: "xenia" */ './pages/XeniaView.vue'),
    redirect: () =>
      AuthService.globalAccess()
        ? `/xenia/${xeniaDefaultRoute()}`
        : AuthService.defaultPath(),
    children: [
      {
        path: 'units',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/Units.vue'),
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions('Administration', 'units', 'view')
        }
      },
      {
        path: 'users',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/UsersView.vue'),
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions('Administration', 'users', 'view')
        }
      },
      {
        path: 'signups',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/SignupsView.vue'),
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions('Administration', 'signups', 'view')
        }
      },
      {
        path: 'organisations',
        component: () =>
          import(
            /* webpackChunkName: "xenia" */ './pages/OrganisationsView.vue'
          ),
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions(
              'Administration',
              'organisations',
              'view'
            )
        }
      },
      {
        path: 'organisations/:orgId',
        component: () =>
          import(
            /* webpackChunkName: "xenia" */ './pages/OrganisationView.vue'
          ),
        redirect: 'organisations/:orgId/panels',
        props: true,
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions(
              'Administration',
              'organisations',
              'view'
            )
        },
        children: [
          {
            path: 'panels',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/OrganisationPanels.vue'
              ),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            }
          },
          {
            path: 'security-settings',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/SecuritySettings.vue'
              ),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            }
          },
          {
            path: 'data-subs',
            name: 'XeniaOrganisationDataSubs',
            component: () =>
              import(/* webpackChunkName: "xenia" */ './pages/DataSubs.vue'),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            }
          },
          {
            path: 'users',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/OrganisationUsers.vue'
              ),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            }
          }
        ]
      },
      {
        path: 'organisations/:orgId/data-subs/:dataSubId',
        component: () =>
          import(
            /* webpackChunkName: "xenia" */ '@/features/data-subscriptions/pages/DataSubscription.vue'
          ),
        props: (route: Route) => ({
          orgId: route.params.orgId,
          dataSubId: route.params.dataSubId,
          isXenia: true
        }),
        redirect: 'organisations/:orgId/data-subs/:dataSubId/feature-subs',
        meta: {
          hasBackground: true,
          guard: () => AuthService.globalAccess()
        },
        children: [
          {
            path: 'users',
            name: 'XeniaDataSubUsers',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ '@/features/data-subscriptions/pages/DataSubUsers.vue'
              ),
            props: (route: Route) => ({
              isXenia: true,
              orgId: route.params.orgId,
              dataSubId: route.params.dataSubId
            }),
            meta: {
              hasBackground: true,
              guard: () => AuthService.globalAccess()
            }
          },
          {
            path: 'groups',
            name: 'XeniaDataSubGroups',
            component: PageEnterpriseDataSubGroups,
            props: (route: Route) => ({
              isXenia: true,
              dataSubId: route.params.dataSubId,
              orgId: route.params.orgId
            }),
            meta: {
              hasBackground: true,
              guard: () => AuthService.globalAccess()
            }
          },
          {
            path: 'feature-subs',
            name: 'XeniaDataSubFeatureSubs',
            component: PageEnterpriseDataSubFeatureSubs,
            props: (route: Route) => ({
              isXenia: true,
              dataSubId: route.params.dataSubId
            }),
            meta: {
              hasBackground: true,
              guard: () => AuthService.globalAccess()
            }
          }
        ]
      },
      {
        path: 'panels',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/PanelsView.vue'),
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions('Administration', 'panels', 'view')
        }
      },
      {
        path: 'isps',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/ISPs.vue'),
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions('Administration', 'isps', 'view')
        }
      },
      {
        path: 'users/:userId',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/user/UserView.vue'),
        props: true,
        redirect: 'users/:userId/details',
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions('Administration', 'users', 'view')
        },
        children: [
          {
            path: 'details',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/user/UserDetails.vue'
              ),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            }
          },
          {
            path: 'permissions',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/user/UserPermissions.vue'
              ),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            }
          }
        ]
      },
      {
        path: 'units/:unitId',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/unit/UnitView.vue'),
        props: true,
        redirect: 'units/:unitId/details',
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions('Administration', 'units', 'view')
        },
        children: [
          {
            path: 'details',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/unit/UnitDetails.vue'
              ),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            }
          },
          {
            path: 'location-history',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/unit/UnitLocations.vue'
              ),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            }
          },
          {
            path: 'test-results',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/unit/TestResults.vue'
              ),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            },
            redirect: 'test-results/downstream',
            children: [
              {
                path: 'downstream',
                component: () =>
                  import(
                    /* webpackChunkName: "xenia" */ './pages/unit/TestResultsTable.vue'
                  ),
                props: true,
                meta: {
                  type: 'httpget',
                  guard: () => AuthService.globalAccess()
                }
              },
              {
                path: 'upstream',
                component: () =>
                  import(
                    /* webpackChunkName: "xenia" */ './pages/unit/TestResultsTable.vue'
                  ),
                props: true,
                meta: {
                  type: 'httppost',
                  guard: () => AuthService.globalAccess()
                }
              },
              {
                path: 'latency',
                component: () =>
                  import(
                    /* webpackChunkName: "xenia" */ './pages/unit/TestResultsTable.vue'
                  ),
                props: true,
                meta: {
                  type: 'udplatency',
                  guard: () => AuthService.globalAccess()
                }
              },
              {
                path: 'packet-loss',
                component: () =>
                  import(
                    /* webpackChunkName: "xenia" */ './pages/unit/TestResultsTable.vue'
                  ),
                props: true,
                meta: {
                  type: 'udppacketloss',
                  guard: () => AuthService.globalAccess()
                }
              }
            ]
          },
          {
            path: 'threshold-breaches',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ '@/features/xenia/pages/ThresholdBreaches.vue'
              ),
            props: true,
            meta: {
              guard: () => AuthService.globalAccess()
            }
          }
        ]
      },
      {
        path: 'isps/:id',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/PackagesView.vue'),
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions('Administration', 'isps', 'view')
        }
      },
      {
        path: 'test-schedules',
        component: () =>
          import(
            /* webpackChunkName: "xenia" */ './pages/test-schedules/TestSchedules.vue'
          ),
        redirect: 'test-schedules/test-servers',
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions(
              'Administration',
              'test_schedules',
              'view'
            )
        },
        children: [
          {
            path: 'test-servers',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/test-schedules/TestServers.vue'
              ),
            meta: {
              guard: () =>
                AuthService.globalAccess() &&
                AuthService.hasPermissions(
                  'Administration',
                  'test_schedules',
                  'view'
                )
            }
          },
          {
            path: 'target-sets',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/test-schedules/TargetSets.vue'
              ),
            meta: {
              guard: () =>
                AuthService.globalAccess() &&
                AuthService.hasPermissions(
                  'Administration',
                  'test_schedules',
                  'view'
                )
            }
          },
          {
            path: 'schedules',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/test-schedules/SchedulesView.vue'
              ),
            meta: {
              guard: () =>
                AuthService.globalAccess() &&
                AuthService.hasPermissions(
                  'Administration',
                  'test_schedules',
                  'view'
                )
            }
          }
        ]
      },
      {
        path: 'test-schedules/schedules/:configId',
        redirect: 'test-schedules/schedules/:configId/tests',
        component: () =>
          import(
            /* webpackChunkName: "xenia" */ './pages/test-schedules/schedule/ScheduleView.vue'
          ),
        props: true,
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions(
              'Administration',
              'test_schedules',
              'view'
            )
        },
        children: [
          {
            path: 'tests',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/test-schedules/schedule/TestsView.vue'
              ),
            props: true,
            meta: {
              guard: () =>
                AuthService.globalAccess() &&
                AuthService.hasPermissions(
                  'Administration',
                  'test_schedules',
                  'view'
                )
            }
          },
          {
            path: 'panels',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/test-schedules/schedule/PanelsView.vue'
              ),
            props: true,
            meta: {
              guard: () =>
                AuthService.globalAccess() &&
                AuthService.hasPermissions(
                  'Administration',
                  'test_schedules',
                  'view'
                )
            }
          }
        ]
      },
      {
        path: 'test-schedules/schedules/:configId/panels/:panelId',
        redirect: 'test-schedules/schedules/:configId/panels/:panelId/assigned',
        component: () =>
          import(
            /* webpackChunkName: "xenia" */ './pages/test-schedules/schedule/AgentsView.vue'
          ),
        props: true,
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions(
              'Administration',
              'test_schedules',
              'view'
            )
        },
        children: [
          {
            path: 'assigned',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/test-schedules/schedule/AgentsAssigned.vue'
              ),
            props: true,
            meta: {
              guard: () =>
                AuthService.globalAccess() &&
                AuthService.hasPermissions(
                  'Administration',
                  'test_schedules',
                  'view'
                )
            }
          },
          {
            path: 'assign',
            component: () =>
              import(
                /* webpackChunkName: "xenia" */ './pages/test-schedules/schedule/AgentsAssign.vue'
              ),
            props: true,
            meta: {
              guard: () =>
                AuthService.globalAccess() &&
                AuthService.hasPermissions(
                  'Administration',
                  'test_schedules',
                  'view'
                )
            }
          }
        ]
      },
      {
        path: 'panel-users',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/PanelUsers.vue'),
        meta: {
          guard: () => AuthService.globalAccess()
        }
      },
      {
        path: 'updates*',
        component: () =>
          import(/* webpackChunkName: "xenia" */ './pages/UpdatesView.vue'),
        meta: {
          guard: () => AuthService.globalAccess()
        }
      },
      {
        path: 'feature-flags',
        component: () =>
          import(
            /* webpackChunkName: "xenia" */ './pages/FeatureFlags/FeatureFlags.vue'
          ),
        meta: {
          guard: () =>
            AuthService.globalAccess() &&
            AuthService.hasPermissions('Administration')
        }
      }
    ]
  }
];
