import { Unit, UnitOfflineStatus } from '@/common/global.interfaces';
import ZeusService from '@/common/services/Zeus';
import { getTimeSinceOnline } from '@/features/unit-tests-view/helpers/offline.helpers';
import { ActionTree, MutationTree, Module as VuexModule } from 'vuex';
import { hasRestrictedMetrics } from '../services/ConsumerConfig';
import UnitOfflineService, {
  baseUnitOfflineStatus
} from '../services/UnitOfflineStatusService';
import { UnitTestSchedulesTasks } from '@samknows/instant-test';
import {
  CallerType,
  fetchAvailableTests
} from '../services/fetchAvailableTests';

interface UnitTestsViewState {
  unit: Unit;
  unitOfflineStatus: UnitOfflineStatus;
  areaOutage: boolean;
  hasRestrictedMetrics: boolean;
  availableTests: UnitTestSchedulesTasks[];
  isTestsFetchFinished: boolean;
}

const state: UnitTestsViewState = {
  unit: {
    id: undefined,
    isp: undefined,
    package: undefined,
    lmap_config: undefined,
    base: undefined,
    panel: undefined
  },
  unitOfflineStatus: baseUnitOfflineStatus,
  areaOutage: false,
  hasRestrictedMetrics: false,
  availableTests: [],
  isTestsFetchFinished: false
};

export const VIRGIN_MEDIA_UK_PANEL_ID = 3;

const mutations: MutationTree<UnitTestsViewState> = {
  setUnit(state: UnitTestsViewState, unit: Unit) {
    state.unit = unit;
  },
  setAvailableTests(
    state: UnitTestsViewState,
    availableTests: UnitTestSchedulesTasks[]
  ) {
    state.availableTests = availableTests;
    state.isTestsFetchFinished = true;
  },
  setUnitOfflineStatus(state: UnitTestsViewState, status: UnitOfflineStatus) {
    state.unitOfflineStatus = status;
  },
  setAreaOutage(state: UnitTestsViewState, outage: boolean) {
    state.areaOutage = outage;
  },
  setHasRestrictedMetrics(state: UnitTestsViewState, restriction: boolean) {
    state.hasRestrictedMetrics = restriction;
  }
};

const actions: ActionTree<UnitTestsViewState, unknown> = {
  async fetchUnit({ commit, dispatch }, unitId) {
    const getRestrictionsConfig = hasRestrictedMetrics(unitId);
    const getUnitData = ZeusService.get(`units/${unitId}`, {
      include:
        'panel,location,location.timezone,isp,package,environment,lmap_config'
    });
    /**
     * Important assumption here that restrictions call is faster than unit call
     * Restrictions call made for all users but created for CST consumers specifically
     */
    Promise.all([getRestrictionsConfig, getUnitData])
      .then(([restrictionsConfig, { data }]) => {
        commit('setHasRestrictedMetrics', restrictionsConfig ?? false);
        commit('setUnit', data);
        dispatch('checkOfflineStatus', data);
        dispatch('checkAreaOutage', data);
      })
      .then(async () => {
        let tests = [];

        if (state.unit.id) {
          try {
            tests = await fetchAvailableTests(
              state.unit,
              CallerType.SCHEDULED_TESTS
            );
          } catch (error) {
            console.error(error);
          }
        }
        commit('setAvailableTests', tests);
      })
      .catch(() => {
        commit('setUnit', { error: true });
      });
  },

  resetUnit({ commit, dispatch }) {
    commit('setUnit', {});
    dispatch('resetUnitOfflineStatus');
  },

  async checkOfflineStatus({ commit }, unit: Pick<Unit, 'id' | 'environment'>) {
    if (!unit) {
      return;
    }

    const unitOfflineStatus = await UnitOfflineService.getUnitOfflineStatus(
      unit
    );

    commit('setUnitOfflineStatus', unitOfflineStatus);
  },

  setOfflineStatus({ commit }, data) {
    const { unitId, lastSeenDate } = data || {};
    if (!unitId) {
      return;
    }

    const storedUnitOfflineStatus: UnitOfflineStatus = {
      ...state.unitOfflineStatus
    };
    let unitOfflineStatus: UnitOfflineStatus = { ...baseUnitOfflineStatus };

    if (storedUnitOfflineStatus.id === unitId) {
      // if unit already declared offline stop here
      if (storedUnitOfflineStatus.offline) {
        return;
      }

      unitOfflineStatus = { ...storedUnitOfflineStatus };
    } else {
      unitOfflineStatus.id = unitId;
    }

    const offlineMessage = getTimeSinceOnline(lastSeenDate);
    unitOfflineStatus.offlineMessage = offlineMessage;
    unitOfflineStatus.offline = true;

    commit('setUnitOfflineStatus', unitOfflineStatus);
  },

  resetUnitOfflineStatus({ commit }) {
    commit('setUnitOfflineStatus', baseUnitOfflineStatus);
  },

  async checkAreaOutage({ commit }, unitData) {
    const outageResponse = await UnitOfflineService.getAreaOutage(unitData);
    if (!outageResponse) {
      return;
    }
    const outageData = outageResponse.data.anomaliesInProgress;
    if (outageData === null) {
      return;
    }
    commit('setAreaOutage', outageData.macDomainOutage);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
} as VuexModule<UnitTestsViewState, unknown>;
