import { prefStorage } from '@samknows/utils';

export enum VIEW_TYPE {
  ENTERPRISE = 'apollo',
  CONSUMER = 'units',
  REDIRECT = 'redirect',
  UNKNOWN = ''
}

export interface ActiveViewData {
  unitId?: number;
  panel?: number; // Panel id
}

export interface ActiveView {
  data: ActiveViewData;
  type: VIEW_TYPE;
}

export default {
  get activeView(): ActiveView {
    return prefStorage.get('active-view') || ({} as ActiveView);
  },

  get viewType(): VIEW_TYPE {
    return this.activeView.type || VIEW_TYPE.UNKNOWN;
  },

  get isEnterpriseView(): boolean {
    return this.viewType === VIEW_TYPE.ENTERPRISE;
  },

  get isConsumerView(): boolean {
    return this.viewType === VIEW_TYPE.CONSUMER;
  },

  get isUserRedirected(): boolean {
    return this.viewType === VIEW_TYPE.REDIRECT;
  }
};
