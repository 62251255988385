import Vue from 'vue';
import VueRouter, { Route, NavigationGuardNext } from 'vue-router';
import VueMeta from 'vue-meta';

import XeniaPages from '@/features/xenia/routes';
import AccountPages from '@/features/account/routes';
import HelpCenterPages from '@/features/help-center/routes';
import SettingsPages from '@/features/settings/routes';
import VolunteerSignupPages from '@/features/volunteer-signup/routes';
import AnalyticsPages from '@/features/analytics/routes';
import DashboardPages from '@/features/dashboard/routes';
import ManagementSuitePages from '@/features/management-suite/routes';
import UnitTestsViewPages from '@/features/unit-tests-view/routes';
import AnomalyDetectionPages from '@/features/anomaly-detection/routes';
import HarViewerRoutes from '@/features/har-viewer/routes';
import UnitSearchPages from '@/features/unit-search/routes';
import ApisPages from '@/features/apis/routes';

const TestManager = (): Promise<unknown> =>
  import('@/common/pages/TestManager.vue');

const PageNotFound = (): Promise<unknown> =>
  import('@/common/pages/NotFound.vue');
const PageNoAccess = (): Promise<unknown> =>
  import('@/common/pages/NoAccess.vue');

import AuthService, { Accessibles } from '@/common/services/Auth';

import { prefStorage } from '@samknows/utils';
import viewTypes from '@/common/helpers/view-types';
import { hasSamlEnabled } from '@/common/helpers/single-sign-on';
import { GRANT_SECTION } from '@/features/account/constants';
import { setGlobalEventProperties } from './helpers/tracking-utils';
import { Grants } from './global.interfaces';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  ...XeniaPages,
  ...VolunteerSignupPages,
  ...AccountPages,
  ...HelpCenterPages,
  ...SettingsPages,
  ...AnalyticsPages,
  ...ManagementSuitePages,
  ...UnitSearchPages,
  ...UnitTestsViewPages,
  ...DashboardPages,
  ...AnomalyDetectionPages,
  ...HarViewerRoutes,
  ...ApisPages,
  {
    path: '/',
    component: { template: '' },
    meta: {
      // We want / to always redirect to default view
      guard: () => false
    }
  },
  {
    path: '/no-access',
    component: PageNoAccess
  },

  {
    path: '*',
    component: PageNotFound,
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
      if (viewTypes.isUserRedirected) {
        next(`/redirect?continue=${to.path}`);
      }
      next();
    }
  },
  {
    path: '/test-manager',
    component: TestManager,
    meta: {
      hasBackground: true,
      guardFailure: 'notfound',
      guard: () => {
        return (
          viewTypes.isEnterpriseView &&
          AuthService.hasPermissions(
            GRANT_SECTION.TEST_MEASUREMENTS,
            'test_schedules_manage'
          )
        );
      }
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // On every route
  const userAuthed = AuthService.userAuthed();
  const grants: Grants = prefStorage.get('grants');
  const accessibles: Accessibles = prefStorage.get('accessibles');

  // Track changes in global event properties on each route change
  setGlobalEventProperties();

  if (hasSamlEnabled()) {
    if (
      !userAuthed &&
      to.name !== 'samlRedirect' &&
      to.name !== 'samlVerifyError'
    ) {
      // Check if user is on a subdomain for a client that supports SAML SSO
      next({ name: 'samlRedirect' });
      return;
    }
    if (userAuthed && (!grants || !accessibles) && to.name !== 'samlLogin') {
      // Redirect user to requested page after SSO login
      const requestedContinueTo = to.query.continue;
      const defaultContinueTo = to.path;
      let continueTo = requestedContinueTo || defaultContinueTo;
      //  Do not redirect user to logout on login
      continueTo = continueTo.includes('/logout') ? '' : continueTo;
      next({
        name: 'samlLogin',
        query: {
          // Send query parameter to next page to parse
          ...to.query,
          continue: continueTo
        }
      });
      return;
    }
  }

  // On routes with a guard
  if (to.meta.guard) {
    if (userAuthed && !hasSamlEnabled() && (!grants || !accessibles)) {
      next('/logout');
      return;
    }

    if (!to.meta.guard(accessibles)) {
      next(to.meta.guardFailure || AuthService.defaultPath());
      return;
    }
  }

  next();
});

export default router;
