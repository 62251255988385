<script>
import MainLayout from '@/common/components/MainLayout.vue';

export default {
  components: {
    MainLayout
  },

  props: {
    hasSidebar: {
      type: Boolean,
      default: false
    }
  },

  render(h) {
    if (this.hasSidebar) {
      return this.$slots.default;
    }

    return h(
      MainLayout,
      {
        props: {
          hasSidebar: this.hasSidebar,
          useContainer: this.hasSidebar
        }
      },
      this.$slots.default
    );
  }
};
</script>
