import SkConfig from '@/common/config';
import {
  AuthTokenPayload,
  Grants,
  Organisation,
  PromiseResponse
} from '@/common/global.interfaces';
import { logError } from '@/common/helpers/error-handling';
import generateId from '@/common/helpers/generate-id';
import { hasSamlEnabled } from '@/common/helpers/single-sign-on';
import { setUserIdentity } from '@/common/helpers/tracking-utils';
import viewTypes, {
  ActiveViewData,
  VIEW_TYPE
} from '@/common/helpers/view-types';
import { heapTrack, resetHeapIdentity } from '@/common/services/heapTracking';
import { resetSentryUserIdentity } from '@/common/services/sentryTracking';
import store from '@/common/store';
import { GRANT_SECTION } from '@/features/account/constants';
import { FeatureFlag, Http, prefStorage } from '@samknows/utils';
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import cookies from 'js-cookie';
import type { Route } from 'vue-router';
import ZeusService from './Zeus';

dayjs.extend(utc);
// This extension is also done in main.ts but some edge cases seem to be calling this file before main.ts has loaded.
// TODO [WEBPLAT-125] investigate why this is happening and eventually remove this call.

export interface Accessibles {
  units: {
    unitId: number;
    panel: number;
  }[];
  volunteer: boolean;
  administration: boolean;
  apollo: AccessiblePanel[];
  organisations: Organisation[];
}

// TODO this is the same as Panel, but the `type` has an extra legacy_mobile field. Check if they can be consolidated.
export interface AccessiblePanel {
  pid: number;
  front_name: string;
  type: 'unit' | 'mobile' | 'web' | 'legacy_mobile';
}

interface AuthCookie {
  data: AuthData;
}

interface AuthData {
  accessToken: string;
  refreshToken: {
    token: string;
    expiresAt: string;
  };
}

const REDIRECT_TO_URI_COOKIE_NAME = 'POST_AUTH_URI';
const SK_STAFF_COOKIE_KEY = 'sk-staff';

export default {
  cookies,

  /**
   * Configuration
   */
  config: {
    cookieName: 'skauth', // Name of the cookie
    renewTimeoutMarginSeconds: 15 // Time in seconds before timeout to renew the refresh token
  },

  get userId(): number {
    return this.getTokenPayload()?.sub;
  },

  /**
   * Setup HTTP request
   * - handle renewing token when it fails
   */
  setupHttpCallbacks(): void {
    Http.registerCallbacks({
      handleResponseError: () => {
        return Promise.resolve();
      },
      handleTokenExpired: async () => {
        await this.renewAccessToken();
      }
    });
  },

  handleGetAccessToken(response: PromiseResponse<AuthData>) {
    const { data, code } = response;
    if (!data || !data.accessToken) {
      let error = new Error('Auth failed');
      error = { ...error, ...data, ...{ code } };
      throw error;
    }
    return data;
  },

  /**
   * Authentication related logic setup. Happens when app is first loaded or on hard page refresh
   *
   * Set a timeout to renew the JWT automatically when it expires instead of on
   * the next page load - should speed things up a bit.
   *
   * Check user is logged in and pref storage is setup before doing any user related setup
   */
  setup() {
    if (!this.userAuthed()) {
      return;
    }

    if (!prefStorage.available) {
      if (!hasSamlEnabled()) {
        this.deauthenticate();
      }
      return;
    }

    // Set hook to save user preferences server-side
    const payload = this.getTokenPayload();
    prefStorage.setServerHook((serverStorage) => {
      return ZeusService.update(`users/${payload.user_id}/preferences`, {
        samknows_one_preferences: serverStorage
      });
    });

    // Clean up redirect cookie
    if (hasSamlEnabled) {
      cookies.remove(REDIRECT_TO_URI_COOKIE_NAME);
    }

    if (
      viewTypes.isEnterpriseView &&
      !prefStorage.get<AccessiblePanel>('panel')
    ) {
      store.commit('setPanel', viewTypes.activeView.data);
      location.reload();
    }

    // Track user profile properties once user is logged in and setup
    setUserIdentity();

    if (this.loadDefaultPanel()) {
      location.reload();
    }

    const tokenExpiry = this.getTokenExpiry();
    const refreshExpiry = this.getRefreshExpiry();

    if (refreshExpiry?.isAfter(tokenExpiry)) {
      const timeUntilExpiry = tokenExpiry.diff(dayjs());
      const buffer = this.config.renewTimeoutMarginSeconds * 1000;
      const renewTime = timeUntilExpiry - buffer;

      if (renewTime <= 0) {
        this.renewAccessToken();
      }
      setTimeout(() => {
        this.renewAccessToken();
      }, renewTime);
    }
  },

  /**
   * Sets a new access token.
   *
   */
  setAccessToken(accessToken: string) {
    Http.accessToken = accessToken;
    const cookie = this.getAuthCookie();

    cookie.data.accessToken = accessToken;

    const options = { expires: 365 };
    cookies.set(this.config.cookieName, cookie, options);
  },

  async getOrRenewAccessToken(): Promise<string> {
    if (this.getTokenExpiry().isBefore(dayjs())) {
      await this.renewAccessToken();
    }
    return this.getAccessToken();
  },

  /**
   * Retrieves the current access token.
   *
   */
  getAccessToken(): string {
    const cookie = this.getAuthCookie();
    if (!cookie) {
      return;
    }
    const { accessToken } = cookie.data;
    // Set token in memory as well
    Http.accessToken = accessToken;
    return accessToken;
  },

  /**
   * Retrieves the current refresh token.
   *
   */
  getRefreshToken(): string {
    const cookie = this.getAuthCookie();
    return cookie?.data?.refreshToken?.token;
  },

  /**
   * Retrieves the expiration timestamp of current refresh token.
   *
   */
  getRefreshExpiry(): dayjs.Dayjs | null {
    const cookie = this.getAuthCookie();

    if (!!cookie?.data?.refreshToken?.expiresAt) {
      // Expiry time is in UTC without time zone, so parse it as UTC
      const refreshExpiryWithUtcFlagOn = dayjs.utc(
        cookie?.data?.refreshToken?.expiresAt
      );

      return refreshExpiryWithUtcFlagOn;
    } else {
      return null;
    }
  },

  /**
   * Sets the refresh token and expiration.
   *
   */
  setRefreshToken(token: string, expiresAt: string) {
    const cookie = this.getAuthCookie();

    cookie.data.refreshToken = {
      token,
      expiresAt
    };

    const options = { expires: 365 };

    cookies.set(this.config.cookieName, JSON.stringify(cookie), options);
  },

  loadDefaultPanel(): boolean {
    // Workaround for a bug where panel would be set to array.
    // No idea what causes it :(
    const accessibles: Accessibles = prefStorage.get('accessibles');
    const panel: AccessiblePanel = prefStorage.get('panel');

    if ((!panel || Array.isArray(panel)) && viewTypes.isEnterpriseView) {
      prefStorage.set('panel', accessibles.apollo[0], true);

      return true;
    }

    return false;
  },

  /**
   * Returns either a base cookie to modify or the currently active authentication details.
   *
   * @returns {object}
   * @private
   */
  getAuthCookie(): AuthCookie {
    const cookie = cookies.getJSON(this.config.cookieName);

    if (!cookie) {
      // Send default object structure so it can be overwritten
      return {
        data: {
          accessToken: undefined,
          refreshToken: {
            token: undefined,
            expiresAt: undefined
          }
        }
      };
    }

    return cookie;
  },

  /**
   * Check if user email contains samknows in domain
   * @param  {string} email
   * @returns boolean
   */
  isSamKnowsEmail(email: string): boolean {
    return /.*@.*samknows..*/.test(email);
  },

  authenticate(email: string, password: string) {
    let browserId = localStorage.getItem('sk-browser-id');
    const uId = localStorage.getItem('sk-uid');

    if (!browserId) {
      browserId = generateId();
      localStorage.setItem('sk-browser-id', browserId);
      Sentry.setExtra('sk_browser_id', browserId);
    }

    if (uId) {
      Sentry.setUser({ id: uId });
    }

    const body = { email, password, device: browserId };

    return Http.request<PromiseResponse<AuthData>>(
      `${SkConfig.api.auth}/login`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        useAuth: false
      }
    )
      .then((response) => this.handleGetAccessToken(response))
      .then((data) => {
        // Update cookie details
        this.setAccessToken(data.accessToken);
        this.setRefreshToken(
          data.refreshToken.token,
          data.refreshToken.expiresAt
        );

        // Check user is sk staff after successful login
        if (this.isSamKnowsEmail(email)) {
          cookies.set(SK_STAFF_COOKIE_KEY, 'true', { expires: 365 });
        }
        return this.getUserData();
      });
  },

  getUserData() {
    return Promise.all([
      this.getGrants(),
      this.getAccessibles(),
      // Save user organisation for later use (e.g product analytics)
      this.storeUserOrganisationName()
    ])
      .then(() => this.getUserPreferences())
      .then(() => this.setupPreferences())
      .then(() => this.loadDefaultPanel());
  },

  getUserPreferences() {
    const payload = this.getTokenPayload();

    return ZeusService.get(`users/${payload.user_id}/preferences`)
      .then((data) => {
        if (data && data.data) {
          prefStorage._setObject(data.data);
        }
      })
      .catch(logError);
  },

  setupPreferences() {
    // Handle cookie and pref storage for header warning
    if (this.globalAccess()) {
      cookies.set(SK_STAFF_COOKIE_KEY, 'true', { expires: 365 });
    } else if (cookies.get(SK_STAFF_COOKIE_KEY) === 'true') {
      prefStorage.set('staff-warning', true);
    }

    // Setup locale preferences
    const payload = this.getTokenPayload();
    const preferredLocale = payload.preferred_locale.replace('_', '-');
    const isValidLocale = !!SkConfig.locales.find(
      ({ code }) => code === preferredLocale
    );

    if (isValidLocale) {
      prefStorage.set('locale', preferredLocale);
      Sentry.setTag('locale', preferredLocale);
    }
    // Store uID
    const userId = localStorage.getItem('sk-uid');
    if (!userId) {
      localStorage.setItem('sk-uid', `${payload.sub}`);
      Sentry.setUser({ id: userId });
    }

    const accessibles: Accessibles = prefStorage.get('accessibles');
    const count =
      accessibles.units.length + (accessibles.apollo.length ? 1 : 0);

    let type = 'signup';
    let activeViewData: ActiveViewData = {};

    if (accessibles.apollo.length) {
      type = VIEW_TYPE.ENTERPRISE;
      activeViewData = accessibles.apollo[0] as unknown; // TODO [eslint-fixes] this looks like a bug - I've not changed this but the types don't line up
    } else if (accessibles.units.length) {
      type = VIEW_TYPE.CONSUMER;
      activeViewData = accessibles.units[0];
    }
    // TODO [eslint-fixes]: check if this is used - I don't think administration exist on accessibles
    else if (accessibles.administration) {
      type = 'admin';
      activeViewData = {};
    }

    const remember: { type: VIEW_TYPE; data: ActiveViewData } =
      prefStorage.get('remember');

    if (count > 1) {
      if (remember) {
        type = remember.type;
        activeViewData = remember.data;
      } else {
        type = VIEW_TYPE.REDIRECT;
        activeViewData = {};
      }
    }

    prefStorage.set('active-view', { type, data: activeViewData });

    if (
      type === 'units' &&
      prefStorage.get<boolean>(`instant-testable-${activeViewData.unitId}`) ===
        undefined
    ) {
      return this.testInstantTestable(activeViewData.unitId);
    } else {
      return Promise.resolve();
    }
  },
  isRefreshTokenExpired(): boolean {
    const refreshExpiry = this.getRefreshExpiry();
    if (!refreshExpiry) {
      return true;
    } else {
      return dayjs().isAfter(refreshExpiry);
    }
  },
  handleLogout() {
    if (hasSamlEnabled()) {
      // Firstly, logout users with SAML auth from 'our' side
      this.deauthenticate();
      // Secondly, handle logout by redirecting users with SAML auth to IDP provider to check if they are actually logged in and redirect to the appropriate page
      window.location.href = '/saml-redirect';
    } else {
      // We redirect other users with SK1 auth to /logout page which handle logging out programmatically and redirect to /login
      window.location.href = '/logout';
    }
  },
  renewAccessToken(): Promise<void> {
    if (this.isRefreshTokenExpired()) {
      heapTrack('refreshTokenInfo', {
        expiresAt: this.getRefreshExpiry(),
        expiresAtType: typeof this.getRefreshExpiry()
      });

      this.handleLogout();
      return;
    }
    const refreshToken = this.getRefreshToken();

    const body = { refreshToken };
    // Using fetch directly to prevent loop and full control: Http.request contains logic related to JWT expiry which should not be applied for this call
    return fetch(`${SkConfig.api.auth}/renew`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        Authorization: `Bearer ${Http.accessToken}`,
        'Content-Type': 'application/json'
      })
    })
      .then((response) => response.json())
      .then((bodyParsed) => this.handleGetAccessToken(bodyParsed))
      .then((data) => {
        // Update access token in cookie
        this.setAccessToken(data.accessToken);
        this.getGrants();
        this.getAccessibles();
      })
      .catch((error) => {
        const isAccountLocked = error?.code === 'LOCKED_STATE_ACCOUNT';
        const isNotLogin = window.location.pathname !== '/login';

        // Not supported for SSO at the moment
        if (isAccountLocked && isNotLogin) {
          this.deauthenticate();
          const duration = error.LOCKOUT_DURATION_SECONDS || 0;
          window.location.href = `/locked?duration=${duration}`;
          return;
        }
        if (error instanceof Error) {
          console.error(error);
        }

        this.handleLogout();
      });
  },

  getGrants(): Promise<void> {
    return Http.request<PromiseResponse<Grants>>(
      `${SkConfig.api.auth}/myGrants`
    ).then((res) => prefStorage.set('grants', res.data));
  },

  getAccessibles(): Promise<void> {
    return Http.request<PromiseResponse<Accessibles>>(
      `${SkConfig.api.auth}/myAccessibles`
    ).then((res) => prefStorage.set('accessibles', res.data));
  },

  /**
   * Get decoded payload of JWT.
   *
   */
  getTokenPayload(): AuthTokenPayload {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return {};
    }

    // [header, payload, signature]
    const payload = accessToken.split('.')[1];

    const jwtPayloadDecoded = atob(payload);

    return JSON.parse(jwtPayloadDecoded);
  },

  getTokenExpiry(): dayjs.Dayjs {
    // We receive tokenExpiry from BE as timestamp, so it needs to be transformed into UTC
    const tokenExpiryInUtc = dayjs.utc(this.getTokenPayload().exp * 1000);

    return tokenExpiryInUtc;
  },

  /**
   * Return whether the user is authenticated or not.
   *
   * @returns {boolean} True if authenticated.
   */
  userAuthed(): boolean {
    if (!cookies.get(this.config.cookieName)) {
      return false;
    }

    if (this.isRefreshTokenExpired()) {
      this.deauthenticate();
      return false;
    }

    return !!this.getTokenPayload().user_id;
  },

  /**
   * Remove the cookie, logging the user out.
   */
  deauthenticate() {
    if (hasSamlEnabled()) {
      cookies.set(REDIRECT_TO_URI_COOKIE_NAME, window.location.pathname);
    }
    if (this.isSkStaff()) {
      // Reset identity for SK staff in case they impersonate other accounts
      resetHeapIdentity();
    }
    store.commit('personalDetails/RESET_STATE');
    prefStorage._purge();
    FeatureFlag.cleanUp();
    cookies.remove(this.config.cookieName);
    resetSentryUserIdentity();

    // Clear cookie on .samknows.one too
    const old = 'Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = `${this.config.cookieName}=;domain=.samknows.one;expires=${old}`;
  },

  defaultPath(): string {
    if (!this.userAuthed()) {
      const pathname = window.location.pathname;
      if (pathname !== '/') {
        return `/login?continue=${pathname}`;
      }
      return '/login';
    }

    const accessibles: Accessibles = prefStorage.get('accessibles');

    if (!accessibles) {
      heapTrack('AfterLoginNavigationNoAccessibles');
      return '/logout';
    }

    if (this.isVolunteer()) {
      return '/profile';
    }

    if (!Object.keys(viewTypes.activeView).length) {
      this.deauthenticate();
      window.location.href = '/';
    }

    if (viewTypes.isUserRedirected) {
      return '/redirect';
    }

    if (viewTypes.isConsumerView) {
      return '/dashboard';
    }

    if (this.analyticsAccess()) {
      return '/analytics';
    }

    const unitPermissions =
      this.hasPermissions(GRANT_SECTION.UNIT, 'search') &&
      this.hasPermissions(GRANT_SECTION.UNIT, 'test_view');

    const adminPlusUnitPermissions =
      unitPermissions &&
      this.hasPermissions(GRANT_SECTION.TEST_MEASUREMENTS, 'agents_view');

    if (unitPermissions || adminPlusUnitPermissions) {
      return '/units';
    }

    if (this.adminAccess()) {
      return '/admin';
    }

    if (this.globalAccess()) {
      return '/xenia';
    }

    if (this.hasPermissions(GRANT_SECTION.TEST_MEASUREMENTS, 'data_api')) {
      return '/data-api';
    }

    heapTrack('AfterLoginNavigationNoDefaultPath');
    return '/logout';
  },

  testInstantTestable(unitId: number) {
    return new Promise((resolve) => {
      if (!this.hasPermissions('Agent', 'trigger_testing')) {
        prefStorage.set(`instant-testable-${unitId}`, false).then(resolve);
        return;
      }

      const payload = this.getTokenPayload();
      return ZeusService.get(
        `users/${payload.user_id}/accessible-units/${unitId}`
      )
        .then((data) => {
          const testable = data.data.is_tt_compatible;
          prefStorage.set(`instant-testable-${unitId}`, testable).then(resolve);
        })
        .catch(() => {
          prefStorage.set(`instant-testable-${unitId}`, false).then(resolve);
        });
    });
  },

  goToView(
    type: VIEW_TYPE,
    data: ActiveViewData,
    remember = false,
    continueTo: string = undefined
  ) {
    let promise: Promise<unknown> = Promise.resolve();

    if (remember) {
      promise = prefStorage.set('remember', { type, data }, true);
    }

    if (
      type === VIEW_TYPE.CONSUMER &&
      prefStorage.get<boolean>(`instant-testable-${data.unitId}`) === undefined
    ) {
      const instantTestablePromise = this.testInstantTestable(data.unitId);
      promise = Promise.all([promise, instantTestablePromise]);
    }

    prefStorage.set('active-view', { type, data });

    // If setting remember, wait until it's stored before continuing
    promise.then(() => {
      if (window.location.pathname === '/redirect') {
        window.location.pathname = continueTo || this.defaultPath();
      } else {
        window.location.reload();
      }
    });
  },

  hasPermissions(
    type: string | GRANT_SECTION,
    permission?: string,
    query?: Record<string, unknown> | string
  ) {
    const grantTree: Grants = prefStorage.get('grants');

    if (!grantTree) {
      return false;
    }

    const global = grantTree.Global;
    if (global && (global.senior_developer || global.developer)) {
      return true;
    }

    if (!grantTree[type]) {
      return false;
    }

    const grants = grantTree[type][permission];

    if (!grants) {
      return false;
    }

    if (!query) {
      return true;
    }

    // Support the old syntax still
    const queryObject = typeof query === 'string' ? { [query]: true } : query;

    return grants.some((restriction: Record<string, unknown>) =>
      Object.keys(query).every((key) => queryObject[key] === restriction[key])
    );
  },

  hasPanelPermissions(
    type: string | GRANT_SECTION,
    permissions: string,
    query?: Record<string, unknown>
  ) {
    // store doesn't exist in the unit tests, so uses prefStorage instead
    const panel = store
      ? store.state.panel.pid
      : prefStorage.get<AccessiblePanel>('panel', {} as AccessiblePanel).pid;

    if (query) {
      query = Object.assign({}, query, { panel });
    } else {
      query = { panel };
    }
    return this.hasPermissions(type, permissions, query);
  },

  // tests if the user is a super admin
  globalAccess(): boolean {
    if (!this.userAuthed()) {
      return false;
    }

    const grants: Grants = prefStorage.get('grants');

    if (!grants) {
      return false;
    }

    return !!(grants.Global || grants.Administration);
  },

  analyticsAccess(): boolean {
    if (viewTypes.isConsumerView) {
      return true;
    }

    if (viewTypes.isEnterpriseView) {
      return this.hasPermissions(
        GRANT_SECTION.TEST_MEASUREMENTS,
        'analytics_ui'
      );
    }

    return false;
  },

  adminAccess(): boolean {
    if (!this.userAuthed()) {
      return false;
    }

    const panel: AccessiblePanel = prefStorage.get('panel');

    if (
      !Object.keys(viewTypes.activeView).length ||
      viewTypes.isConsumerView ||
      !panel
    ) {
      return false;
    }

    return prefStorage.get<Accessibles>('accessibles').apollo.some(
      ({ pid }: { pid: number }) =>
        this.hasPermissions(
          GRANT_SECTION.TEST_MEASUREMENTS,
          'metadata_manage',
          {
            panel: pid
          }
        ) ||
        this.hasPermissions(GRANT_SECTION.TEST_MEASUREMENTS, 'agents_manage', {
          panel: pid
        }) ||
        this.hasPermissions(GRANT_SECTION.TEST_MEASUREMENTS, 'agents_view', {
          panel: pid
        }) ||
        this.hasPermissions(
          GRANT_SECTION.TEST_MEASUREMENTS,
          'admin_users_manage',
          {
            panel: pid
          }
        ) ||
        this.hasPermissions(
          GRANT_SECTION.TEST_MEASUREMENTS,
          'agent_owners_manage',
          {
            panel: pid
          }
        ) ||
        this.hasPermissions('Owner', 'org_super_admin') ||
        this.globalAccess()
    );
  },

  isVolunteer(): boolean {
    const accessibles: Accessibles = prefStorage.get('accessibles');

    if (!accessibles || !accessibles.volunteer) {
      return false;
    }

    if (accessibles.apollo && accessibles.apollo.length) {
      return false;
    }

    if (accessibles.units && accessibles.units.length) {
      return false;
    }

    return true;
  },

  goToDefaultPath(route: Route): void {
    const defaultPath = this.defaultPath();
    const continueTo = route.query.continue as string;

    heapTrack('AfterLoginNavigation', {
      defaultPath,
      continueTo
    });

    if (defaultPath === '/redirect' && continueTo) {
      let location = `${defaultPath}?continue=${continueTo}`;

      if (route.query.host) {
        location += `&host=${route.query.host}`;
      }

      window.location.href = location;
    } else if (continueTo) {
      window.location.pathname = continueTo;
    } else {
      window.location.pathname = defaultPath;
    }
  },

  getUserOrganisationId(): number {
    return this.getTokenPayload()?.organisation_id;
  },

  /**
   * Fetch and save user organisation name in local storage for later use
   *
   * @returns Promise
   */
  async storeUserOrganisationName(): Promise<void> {
    const organisationId = this.getUserOrganisationId();

    // Consumers don't have an organisation
    if (!organisationId) {
      return Promise.resolve();
    }

    try {
      const { data } = await ZeusService.get(`organisations/${organisationId}`);
      prefStorage.set('organisationName', data.name);
    } catch {
      return Promise.resolve();
    }
  },

  getUserOrganisationName(): string {
    return prefStorage.get('organisationName') ?? '';
  },

  isSkStaff(): boolean {
    const userOrganisation = this.getUserOrganisationId();
    const SAMKNOWS_ORG_ID = 123;

    // Cookie set when user had global access - but not all Sk employee do so used as secondary condition
    // Set to true when sk users impersonate real users too
    const hasSkStaffCookie = !!cookies.get(SK_STAFF_COOKIE_KEY);

    return userOrganisation === SAMKNOWS_ORG_ID || hasSkStaffCookie;
  },

  hasEnterpriseViewAccess(): boolean {
    const accessibles: Accessibles = prefStorage.get('accessibles');
    return !!accessibles?.apollo?.length;
  },

  hasHomeViewAccess(): boolean {
    const accessibles: Accessibles = prefStorage.get('accessibles');
    return !!accessibles?.units?.length;
  },

  getViewTypeAccess(): string {
    if (this.hasEnterpriseViewAccess() && this.hasHomeViewAccess()) {
      return 'Data subscription + Whiteboxes access';
    }

    if (this.hasEnterpriseViewAccess()) {
      return 'Data subscription';
    }
    if (this.hasHomeViewAccess()) {
      return 'Whiteboxes access';
    }
    return '';
  },

  getAccessibleUnitsList(): string[] {
    const accessibles: Accessibles = prefStorage.get('accessibles');
    return accessibles?.units?.map((item) => item.unitId as unknown as string); // TODO [eslint-fixes] this conversion looks like a bug
  }
};
