import Sortable from 'sortablejs';
import Vue from 'vue';

Vue.directive('sortable', {
  inserted(el, binding) {
    const sorting = binding.value;

    const options: Sortable.Options = {
      handle: '.sortable-handle'
    };

    if (sorting) {
      options.onUpdate = (e: Sortable.SortableEvent) => {
        const deleted = sorting.splice(e.oldIndex, 1);
        sorting.splice(e.newIndex, 0, deleted[0]);
      };
    }

    Sortable.create(el, options);
  }
});
