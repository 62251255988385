import { getDashboards } from '@/features/dashboard/services/dashboards';

export const namespaced = true;

export const state = {
  dashboards: []
};

/* eslint-disable no-shadow */
export const mutations = {
  setDashboards(state, dashboards) {
    state.dashboards = dashboards;
  }
};

export const actions = {
  getDashboards({ commit, state }, force = false) {
    if (state.dashboards.length && !force) {
      return Promise.resolve();
    }

    return getDashboards().then(({ data }) => {
      commit('setDashboards', data);
    });
  }
};
