
import { BaseButton, SvgIcon } from '@samknows/design-system';
import { defineComponent, ref } from 'vue';
import { prefStorage } from '@samknows/utils';
import { useStore } from '../composables/vuex';

export default defineComponent({
  components: { SvgIcon, BaseButton },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    dismissable: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore();

    const hasDeprecationBanner = prefStorage.get('deprecation-banner');
    const isDeprecationBannerShown = ref(hasDeprecationBanner);
    store.commit(
      'menus/isDeprecationBannerShown',
      isDeprecationBannerShown.value
    );

    const isFirstSession = typeof hasDeprecationBanner === 'undefined';

    if (isFirstSession) {
      isDeprecationBannerShown.value = true;
      store.commit(
        'menus/isDeprecationBannerShown',
        isDeprecationBannerShown.value
      );
      prefStorage.set('deprecation-banner', isDeprecationBannerShown.value);
    }

    function hideBanner() {
      isDeprecationBannerShown.value = false;
      store.commit(
        'menus/isDeprecationBannerShown',
        isDeprecationBannerShown.value
      );
      prefStorage.set('deprecation-banner', isDeprecationBannerShown.value);
    }

    return { hideBanner, isDeprecationBannerShown };
  }
});
