const isNumeric = (str: string): boolean => /^\d+$/.test(str);

function splitDataName(item: string): string[] {
  const splitName = [''];

  item = item.replace('1Gbps', '1000');

  let numerical = isNumeric(item[0]);

  for (let i = 0; i < item.length; i++) {
    if (
      (isNumeric(item[i]) && !numerical) ||
      (!isNumeric(item[i]) && numerical)
    ) {
      numerical = !numerical;
      splitName.push('');
    }

    splitName[splitName.length - 1] += item[i];
  }

  return splitName;
}

export function splitSort(data: string[]): string[];
export function splitSort<
  T extends { [pickBy in Key]: string },
  Key extends keyof T
>(data: T[], pickBy: Key): T[];
export default function splitSort<T>(data: T[], pickBy?: keyof T): T[] {
  return data
    .map((item) => ({
      original: item,
      split: splitDataName((pickBy ? item[pickBy] : item) as string)
    }))
    .sort((a, b) => {
      const compareIndex = a.split.findIndex(
        (_, i) => a.split[i] !== b.split[i]
      );

      if (compareIndex === -1) {
        return 0;
      }

      const aPart = a.split[compareIndex];
      const bPart = b.split[compareIndex];

      if (isNumeric(aPart) || isNumeric(bPart)) {
        if (!isNumeric(aPart)) {
          return 1;
        }

        if (!isNumeric(bPart)) {
          return -1;
        }

        return Number(aPart) < Number(bPart) ? -1 : 1;
      }

      return aPart < bPart ? -1 : 1;
    })
    .map(({ original }) => original);
}
