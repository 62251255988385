import { DEFAULT_EULA_LINK, DEFAULT_FAQS_LINK } from '../constants';
import { Package, Isp, Country } from '@/features/volunteer-signup/types';
import { formConfig } from '@/features/volunteer-signup/volunteer-form.config';

export function createEulaLink(ownerId): string {
  return formConfig[ownerId]?.eulaLink || DEFAULT_EULA_LINK;
}

export function createFaqsLink(ownerId): string {
  return formConfig[ownerId]?.faqsLink || DEFAULT_FAQS_LINK;
}

export function ispsForCountry(
  countryId: number,
  countries?: Country[]
): Isp[] {
  const country = countries.find(({ id }) => id === countryId);

  if (!country || !country.isps) {
    return [];
  }
  const ispsToSort = [...country.isps];
  return ispsToSort.sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
  );
}

export function packagesForIsp(
  selectedIsp: string | number,
  isps: Isp[]
): Package[] {
  if (!isps) {
    return [];
  }
  const ispId = isps.find(({ id }: Isp) => id === selectedIsp);
  const packages = ispId ? ispId.packages : [];
  return packages;
}

export function citiesList(locale: string, ownerId: string): string[] {
  const language = locale.split('_')[0];
  const listOfCities = formConfig[ownerId].cities?.[language];

  return listOfCities?.map((city: string) => {
    return {
      name: city
    };
  });
}
