import { Http } from '@samknows/utils';
import { Preset } from '@/common/services/presets/types';
import { getPresets } from '@/common/services/presets';
import AuthService from '@/common/services/Auth';
import config from '@/common/config';
import { AuthTokenPayload, PromiseResponse } from '@/common/global.interfaces';
import { execOnChildren } from '../helpers/utils';
import {
  DASHBOARD_TYPE,
  Dashboard,
  DashboardChildren,
  DASHBOARD_COMPONENT_TYPE
} from '../types';

export function getDashboardOwner(isOrganisation = false): string {
  const tokenPayload: AuthTokenPayload = AuthService.getTokenPayload();
  return isOrganisation
    ? tokenPayload.organisation_owner_id
    : tokenPayload.owner_id;
}

export async function createDashboard(
  dashboard: Dashboard,
  isDashboardPublic = false
): Promise<unknown> {
  const dashboardWithOwner: Dashboard = {
    ...dashboard,
    owner: getDashboardOwner(isDashboardPublic)
  };

  const res = await Http.request(`${config.api.analytics}/dashboards`, {
    method: 'POST',
    body: JSON.stringify(dashboardWithOwner)
  });

  return res;
}

function getUserDashboard(): Promise<PromiseResponse<Dashboard[]>> {
  const userOwnerId = getDashboardOwner(false);

  return Http.request(
    `${config.api.analytics}/owners/${userOwnerId}/dashboards`
  );
}

function getOrganisationDashboards(): Promise<PromiseResponse<Dashboard[]>> {
  const organisationOwnerId = getDashboardOwner(true);
  return Http.request(
    `${config.api.analytics}/owners/${organisationOwnerId}/dashboards`
  );
}

function setPreset(child: DashboardChildren, presets: Preset[]): void {
  const preset = presets.find(({ id }: Preset) => id === child.meta.id);
  child.meta = preset || { error: 'NOT_FOUND' };
}

function assignPresetToDashboard(
  dashboard: Dashboard,
  presets: Preset[]
): void {
  if (dashboard.type === DASHBOARD_TYPE.WAR_BOARD) {
    execOnChildren(dashboard.contents.children, (child: DashboardChildren) => {
      if (!child.meta) {
        return;
      }
      setPreset(child, presets);
    });
    return;
  }

  dashboard.contents.forEach((child: DashboardChildren) => {
    if (child.component !== 'graph') {
      return;
    }

    setPreset(child, presets);
  });
}

export async function getDashboards(
  type?: string
): Promise<PromiseResponse<Dashboard[]>> {
  const presetsPromise = getPresets(true);
  const userDashboardsPromise = getUserDashboard();
  const organisationDashboardsPromise = getOrganisationDashboards();

  return Promise.all([
    presetsPromise,
    userDashboardsPromise,
    organisationDashboardsPromise
  ]).then(([presets, userDashboards, organisationDashboards]) => {
    let dashboards: Dashboard[] = userDashboards.data.concat(
      organisationDashboards.data
    );

    if (type) {
      dashboards = dashboards.filter(
        (dashboard: Dashboard) => dashboard.type === type
      );
    }

    dashboards.forEach((dashboard: Dashboard) => {
      assignPresetToDashboard(dashboard, presets.data);
    });

    return { data: dashboards };
  });
}

export async function updateDashboard(
  data: Dashboard,
  isDashboardPublic = false
): Promise<unknown> {
  data = JSON.parse(JSON.stringify(data));

  if (data.type === DASHBOARD_TYPE.WAR_BOARD) {
    execOnChildren(data.contents.children, (child: DashboardChildren) => {
      if (child.meta) {
        child.meta = { id: child.meta.id, panel: child.meta.panel };
      }
    });
  } else {
    data.contents.forEach((child: DashboardChildren) => {
      if (child.component !== DASHBOARD_COMPONENT_TYPE.GRAPH) {
        return;
      }

      child.meta = { id: child.meta.id };
    });
  }

  data.owner = getDashboardOwner(isDashboardPublic);

  return Http.request(`${config.api.analytics}/dashboards/${data.id}`, {
    method: 'PUT',
    body: JSON.stringify(data)
  });
}

export async function deleteDashboard(id: string): Promise<unknown> {
  return Http.request(`${config.api.analytics}/dashboards/${id}`, {
    method: 'DELETE'
  });
}
