
import { defineComponent, onBeforeMount, Ref, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { BaseStateIndicator } from '@samknows/design-system';

import BasicForm from '@/features/volunteer-signup/components/signup/BasicForm.vue';
import MeqyasForm from '@/features/volunteer-signup/components/signup/MeqyasForm.vue';
import SignupConfirmation from '@/features/volunteer-signup/components/SignupConfirmation.vue';
import BrandedSignupSection from '../components/signup/BrandedSignupSection.vue';
import { getMetaData } from '@/features/volunteer-signup/services/signup';
import { ASYNC_STATE } from '@/common/global.interfaces';
import { Country } from '@/features/volunteer-signup/types';
import { formConfig } from '@/features/volunteer-signup/volunteer-form.config';
import { createFaqsLink } from '@/features/volunteer-signup/helpers/volunteer.helper';
import { ORGANISATIONS } from '../constants';

export default defineComponent({
  name: 'SignupForm',
  metaInfo() {
    return {
      title: 'Signup'
    };
  },
  components: {
    BasicForm,
    MeqyasForm,
    SignupConfirmation,
    BrandedSignupSection,
    BaseStateIndicator
  },
  props: {
    owner: {
      type: String,
      required: true
    },
    country: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const countries: Ref<Country[]> = ref([]);
    const meqyasData: Ref<Country> = ref(null);
    const isp = ref(undefined);
    const product = ref(undefined);
    const showSuccessState = ref(false);
    const route = useRoute();
    const router = useRouter();
    const pageState = ref(ASYNC_STATE.LOADING);
    const isMobile = computed((): boolean => {
      return window.innerWidth < 576;
    });

    const formConfigForOwner = ref(formConfig[props.owner] || {});
    const studyLogo = computed(() => {
      return formConfigForOwner.value?.branding?.logo;
    });
    const studyName = computed(() => {
      return formConfigForOwner.value?.studyName;
    });

    const countryId = parseInt(props.country, 10);

    const isMeqyas = props.owner === ORGANISATIONS.CST;
    const fixedWireless = ref(undefined);
    function handleFixedWirelessAnswer(input: boolean): void {
      fixedWireless.value = input;
    }

    const faqsLink = computed((): string => {
      return createFaqsLink(props.owner);
    });

    async function getFormMetadata() {
      try {
        const data = await getMetaData();
        pageState.value = ASYNC_STATE.RESOLVED;

        countries.value = data.data.countryData;
        meqyasData.value = countries.value.find(
          ({ id }: Country) => id === 195
        );

        if (!route) {
          return;
        }
        const query = route.query;
        if (query.isp) {
          isp.value = Number(query.isp);
        }
        if (query.product) {
          product.value = Number(query.product);
        }
      } catch (err) {
        pageState.value = ASYNC_STATE.ERROR;
        console.error(err);
      }
    }

    onBeforeMount(async () => {
      const formSettingsForOwner = formConfig[props.owner];
      const isFormConfigMissing = !formSettingsForOwner;

      // Do not show the form for missing configuration
      if (isFormConfigMissing) {
        router.push('/404');
        return;
      }
      await getFormMetadata();
      changeColour();
    });

    function changeColour() {
      document
        .getElementById('signup-form')
        .style.setProperty(
          '--branded-border',
          formConfig[props.owner].branding.colour
        );
    }

    return {
      showSuccessState,
      countryId,
      countries,
      meqyasData,
      isp,
      product,
      pageState,
      studyLogo,
      studyName,
      isMobile,
      isMeqyas,
      fixedWireless,
      handleFixedWirelessAnswer,
      faqsLink
    };
  }
});
