import { DashboardContents, DashboardChildren } from './../types';

export function execOnChildren(
  children:
    | (DashboardContents & DashboardChildren)[]
    | (DashboardContents & DashboardChildren[]),
  cb: (child: DashboardChildren) => void
): void {
  children.forEach((child) => {
    cb(child);

    const dashboardContent = child as unknown as DashboardContents;

    if (dashboardContent.children) {
      execOnChildren(dashboardContent.children, cb);
    }
  });
}
