export default function searchType(value: string): 'mac' | 'email' | 'id' {
  if (/^([0-9A-Fa-f]{2}[.:-]*){5}([0-9A-Fa-f]{2})$/.test(value)) {
    return 'mac';
  }
  if (/^[^@ ]+@[^@ ]+\.[^@ ]+$/.test(value)) {
    return 'email';
  }
  if (/^\d+$/.test(value)) {
    return 'id';
  }
}
