import config from '@/common/config';
import {
  FEATURE_FLAGS,
  Unit,
  UnitOfflineStatus
} from '@/common/global.interfaces';
import { getDateTimeDifference } from '@/common/helpers/date-utils';
import viewTypes from '@/common/helpers/view-types';
import AuthService from '@/common/services/Auth';
import { GRANT_SECTION } from '@/features/account/constants';
import { FeatureFlag, Http } from '@samknows/utils';
import dayjs from 'dayjs';
import { getTimeSinceOnline } from '../helpers/offline.helpers';

export const baseUnitOfflineStatus: UnitOfflineStatus = {
  offline: false,
  lastSeenOnline: null,
  offlineMessage: '',
  id: null,
  isUnreliable: false
};

export interface DisconnectionInProgress {
  dtime: string;
  endDtime: string;
  source: string;
  metricValue: unknown;
  isUnreliable: boolean;
}

interface DisconnectionsInProgressResponse {
  data: {
    results: DisconnectionInProgress[];
  };
}

interface AnomaliesInProgess {
  data: {
    anomaliesInProgress: null | {
      macDomainOutage: boolean;
    };
  };
}

export const MAX_UNIT_LAST_SEEN_DURATION_DAYS = 30;
export const VIRGIN_MEDIA_UK_PANEL_ID = 3;

export const UnitOfflineService = {
  async getUnitOfflineStatus(
    unit: Pick<Unit, 'id' | 'environment'>
  ): Promise<UnitOfflineStatus> {
    const unitId = unit.id;
    let lastSeenOnline = null;
    let isOffline = false;
    const lastSeen = unit.environment?.data?.last_seen || null;

    if (lastSeen) {
      const lastSeenDate = dayjs.unix(lastSeen);
      const now = dayjs();
      const diffInDays = getDateTimeDifference(now, lastSeenDate);
      const lastSeenBeyondThreshold =
        diffInDays > MAX_UNIT_LAST_SEEN_DURATION_DAYS;

      if (lastSeenBeyondThreshold) {
        lastSeenOnline = lastSeenDate;
        isOffline = true;
      }
    }

    const disconnectionInProgress = await this._getDisconnectionInProgress(
      unitId
    );

    let isUnreliable = false;
    if (disconnectionInProgress) {
      const disconnectionTime = disconnectionInProgress.dtime
        ? dayjs(disconnectionInProgress.dtime)
        : null;

      if (disconnectionTime) {
        lastSeenOnline = disconnectionTime;
      }

      isOffline = true;
      isUnreliable = !!disconnectionInProgress.isUnreliable;
    }

    if (isOffline) {
      const offlineMessage = getTimeSinceOnline(lastSeenOnline);
      return {
        ...baseUnitOfflineStatus,
        lastSeenOnline,
        offline: isOffline,
        offlineMessage,
        id: unitId,
        isUnreliable
      };
    } else {
      return {
        ...baseUnitOfflineStatus,
        lastSeenOnline,
        id: unitId
      };
    }
  },

  async _getDisconnectionInProgress(
    unitId: number | undefined
  ): Promise<DisconnectionInProgress | null> {
    if (!unitId) {
      return null;
    }

    const disconnectionInProgressRes: DisconnectionsInProgressResponse =
      await Http.get(
        config.api.unitAnalytics,
        `/${unitId}/disconnection_in_progress`
      );

    return disconnectionInProgressRes?.data?.results?.[0] ?? null;
  },

  async getAreaOutage(unit: Unit): Promise<AnomaliesInProgess> | null {
    const isOutageFlagActive = FeatureFlag.isEnabled(
      FEATURE_FLAGS.CC_AREA_OUTAGE_ALERT
    );
    if (!isOutageFlagActive || !unit) {
      return;
    }
    const { id, panel } = unit;
    const outagesPermission = AuthService.hasPermissions(
      GRANT_SECTION.UNIT,
      'outages_view'
    );
    if (
      !viewTypes.isConsumerView &&
      outagesPermission &&
      panel.data.id === VIRGIN_MEDIA_UK_PANEL_ID
    ) {
      try {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] Add type for Http.get if possible
        const getAnomaliesInProgress = await Http.get<any>(
          config.api.unitAnalytics,
          `/${id}/anomalies_in_progress`
        );
        return getAnomaliesInProgress;
      } catch (error) {
        console.error(error);
      }
    } else {
      return;
    }
  }
};

export default UnitOfflineService;
