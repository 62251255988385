import { CHART_TYPE } from '@/common/global.interfaces';
import { Preset } from './types';

// Used in the getPresets function to change shape of preset
export const processPreset = (preset: Preset): Preset => {
  const presetData = Array.isArray(preset.data) ? preset.data : [preset.data];

  const transformedPresetData = presetData.map((presetObj) => {
    const chartType =
      presetObj.chartType === CHART_TYPE.TIME_SERIES
        ? CHART_TYPE.AGGREGATE
        : presetObj.chartType;

    const plotValue =
      presetObj.plotValue === undefined
        ? 'metricValue'
        : presetObj.plotValue === 'unitCount'
        ? 'agentCount'
        : presetObj.plotValue;

    let presetTableColumns = presetObj.tableColumns || ['metricValue'];

    if (!Array.isArray(presetTableColumns)) {
      presetTableColumns = [presetTableColumns];
    }

    const tableColumns = presetTableColumns.map((columnName: string) => {
      if (columnName === 'unitCount') {
        return 'agentCount';
      }
      return columnName;
    });

    // Overwrite each presetObj with default values, if no values provided
    // or if values must be transformed
    return {
      ...presetObj,
      chartType,
      plotValue,
      tableColumns,
      ci: presetObj.ci ?? false,
      heatmapPointRadius: presetObj.heatmapPointRadius ?? 15,
      showRegression: presetObj.showRegression ?? true
    };
  });

  return {
    ...preset,
    data: transformedPresetData
  };
};
