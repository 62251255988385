
import { defineComponent } from 'vue';
import ResetPasswordService from '@/common/services/ResetPassword';
import { whitelabel } from '@samknows/utils';

import { BaseAlert, BaseButton } from '@samknows/design-system';

export default defineComponent({
  metaInfo() {
    return {
      title: this.$t('pages.requestPasswordReset.title')
    };
  },
  components: {
    BaseAlert,
    BaseButton
  },
  data() {
    return {
      email: '',
      alert: {
        type: 'success',
        title: '',
        text: ''
      },
      showAlert: false,
      isLoading: false,
      hideImage: whitelabel('hideLoginImage')
    };
  },
  methods: {
    async requestPasswordReset(email: string) {
      this.isLoading = true;
      try {
        await ResetPasswordService.requestPasswordReset(email);
        this.alert = {
          type: 'success',
          title: this.$t('common.success'),
          text: this.$t('pages.requestPasswordReset.alertResetEmailSentSuccess')
        };
      } catch {
        this.alert = {
          type: 'error',
          title: this.$t('common.error'),
          text: this.$t('pages.requestPasswordReset.alertResetEmailSentFailure')
        };
      }
      this.showAlert = true;
      this.isLoading = false;
    }
  }
});
