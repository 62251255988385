import { ActionTree, MutationTree, Module as VuexModule } from 'vuex';
import ZeusService from '@/common/services/Zeus';
import type { RootState } from '@/common/store';
import { Unit } from '@samknows/instant-test';
import { PanelMetadata } from '@/common/global.interfaces';

interface ClientAdminState {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  agentsFormData: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  usersFormData: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  packagesFormData: any;
  adminTab: string;
  formTouched: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add type for this property
  panelData: any;
  panelMetadata: PanelMetadata[];
  unit: Unit;
}

const moduleState = (): ClientAdminState => ({
  agentsFormData: {},
  usersFormData: {},
  packagesFormData: {},
  adminTab: '',
  formTouched: false,
  panelData: {},
  panelMetadata: [],
  unit: {
    id: undefined,
    isp: undefined,
    package: undefined,
    lmap_config: undefined,
    base: undefined,
    panel: undefined
  }
});

export const mutations: MutationTree<ClientAdminState> = {
  setAgentsFormData(state, formData) {
    state.agentsFormData = JSON.parse(JSON.stringify(formData));
  },
  setUsersFormData(state, formData) {
    state.usersFormData = JSON.parse(JSON.stringify(formData));
  },
  setPackagesFormData(state, formData) {
    state.packagesFormData = JSON.parse(JSON.stringify(formData));
  },
  setAdminTab(state, adminTab) {
    state.adminTab = adminTab;
  },
  setFormTouched(state, formTouched) {
    state.formTouched = formTouched;
  },
  setPanelData(state, panelData) {
    state.panelData = panelData;
  },
  setPanelMetadata(state, panelMetadata: PanelMetadata[]) {
    state.panelMetadata = panelMetadata;
  },
  setUnit(state, unit) {
    state.unit = unit;
  }
};

const actions: ActionTree<ClientAdminState, RootState> = {
  async getPanelData({ commit, rootState }) {
    const res = await ZeusService.get(`panels/${rootState.panel.pid}`);

    commit('setPanelData', res.data);
  },
  async getPanelMetadata({ commit, rootState }) {
    const res = await ZeusService.get(`panels/${rootState.panel.pid}/metadata`);

    commit('setPanelMetadata', res.data);
  },
  async fetchUnit({ commit }, unitId) {
    try {
      const { data } = await ZeusService.get(`units/${unitId}`, {
        include:
          'panel,location,location.timezone,isp,package,environment,lmap_config'
      });
      commit('setUnit', data);
    } catch (error) {
      commit('setUnit', { error: true });
    }
  },
  resetUnit({ commit }) {
    commit('setUnit', {});
  }
};

export default {
  namespaced: true,
  state: moduleState,
  mutations,
  actions
} as VuexModule<ClientAdminState, unknown>;
