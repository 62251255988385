import { AuthTokenPayload } from '@/common/global.interfaces';
import AuthService from '@/common/services/Auth';
import ZeusService from '@/common/services/Zeus';

export function persistLocale(
  locale: string,
  currentRoutePath: string
): Promise<unknown> {
  if (AuthService.userAuthed() && currentRoutePath !== '/no-access') {
    const tokenPayload: AuthTokenPayload = AuthService.getTokenPayload();
    return ZeusService.update(
      `users/${tokenPayload.sub}/personal_information`,
      {
        default_locale: locale.replace('-', '_')
      }
    );
  }
  return Promise.resolve();
}
