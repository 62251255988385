
import { defineComponent } from 'vue';
import { BaseButton } from '@samknows/design-system';
import { Period, minutesToText } from '@/common/helpers/format';

export default defineComponent({
  components: {
    BaseButton
  },
  metaInfo() {
    return {
      title: this.$t('pages.locked.title')
    };
  },
  computed: {
    time(): Period {
      const { duration } = this.$route.query;
      const durationInMinutes = +duration / 60;

      return minutesToText(durationInMinutes);
    }
  }
});
