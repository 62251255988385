
import { defineComponent } from 'vue';
import SearchForm from './SearchForm.vue';
import { getMetadataNames } from '@/features/unit-search/helpers/search.helpers';

export default defineComponent({
  name: 'NavbarSearchForm',
  components: {
    SearchForm
  },
  data() {
    return {
      metadataNames: []
    };
  },
  computed: {
    panel() {
      return this.$store.state.panel;
    },
    metaSearchResults() {
      return this.$store.state.unitSearch.metaSearchResults;
    },
    unitId(): string {
      return this.$store.state?.unitTestsView?.unit?.id || '';
    }
  },
  watch: {
    metaSearchResults(newResults) {
      if (!this.metadataNames || this.metadataNames.length < 1) {
        this.fetchMetadataNames();
      }

      if (newResults.length === 1) {
        const result = newResults[0];
        const unitId = result.unitId;
        const firstMatchedField = result.fieldsMatching[0];
        const typeFriendlyName = this.accessMetadataName(
          firstMatchedField?.field
        );
        const selectedData = {
          unitId,
          selectedType: firstMatchedField?.field,
          selectedTypeName: typeFriendlyName,
          selectedTypeValue: firstMatchedField?.value
        };

        this.$store.commit('unitSearch/setSelectedResult', selectedData);

        // if the unit we have found is the same unit we're looking at we don't want to reload navigation for no reason
        const isSameUnit = unitId === this.unitId;
        if (!isSameUnit) {
          this.$router.push({ path: `/units/${unitId}` });
        }

        return;
      }

      this.$router.push({
        path: '/units/search',
        query: { showResults: 'true' }
      });
    }
  },
  mounted() {
    this.fetchMetadataNames();
  },
  methods: {
    accessMetadataName(metadataId) {
      const metadataForId = this.metadataNames.find((item) => {
        return item.id === metadataId;
      });
      return metadataForId?.identifier ?? metadataId;
    },
    async fetchMetadataNames() {
      const metadataNames = await getMetadataNames(this.panel.pid);
      this.metadataNames = metadataNames;
    }
  }
});
