import Vue from 'vue';
import { prefStorage } from '@samknows/utils';
import scssColors from '@/scss/export.scss';
import viewTypes, { ActiveView } from '@/common/helpers/view-types';

const activeView: ActiveView = prefStorage.get('active-view');

export const colorsObj = scssColors;

export const colors = Object.keys(scssColors)
  .filter((color) => color.split('-')[0] === 'charts')
  .reduce((arr, key) => {
    arr.push(scssColors[key]);
    return arr;
  }, []);

export function isVideoQuality(metric: string): boolean {
  if (activeView && viewTypes.isEnterpriseView) {
    return false;
  }
  return [
    'netflix_bitrate_reliably_streamed',
    'youtube_bitrate_reliably_streamed',
    'iplayer_bitrate_reliably_streamed'
  ].includes(metric);
}

export function videoQualityCategories(): string[] {
  return [
    Vue.t('HighchartsChart.videoQualities.sd'),
    Vue.t('HighchartsChart.videoQualities.hd'),
    Vue.t('HighchartsChart.videoQualities.uhd')
  ];
}

interface FormDataWithPanel {
  panel: number;
}

export function getUniquePanels(formDataArray: FormDataWithPanel[]): number[] {
  return formDataArray.reduce((innerPanels: number[], { panel }) => {
    if (!innerPanels.includes(panel)) {
      innerPanels.push(panel);
    }

    return innerPanels;
  }, []);
}

export function convertToVideoQuality(
  metricValue: number,
  metric:
    | 'netflix_bitrate_reliably_streamed'
    | 'youtube_bitrate_reliably_streamed'
    | 'iplayer_bitrate_reliably_streamed'
): number {
  const videoQualities = {
    netflix_bitrate_reliably_streamed: {
      hd: 3 / 8e-6,
      uhd: 15.6 / 8e-6
    },
    youtube_bitrate_reliably_streamed: {
      hd: 2.5 / 8e-6,
      uhd: 6.8 / 8e-6
    },
    iplayer_bitrate_reliably_streamed: {
      hd: 2.8 / 8e-6,
      uhd: 8 / 8e-6
    }
  }[metric];

  if (metricValue < videoQualities.hd) {
    return 0;
  }

  if (metricValue < videoQualities.uhd) {
    return 1;
  }

  return 2;
}
