import '@/scss/app.scss';
// Added for locales we support contractually
import 'dayjs/locale/en-gb';
import 'dayjs/locale/ar-sa';
import 'dayjs/locale/fr-ca';
// Directives
import '@/common/directives/autogrow';
import '@/common/directives/sortable';

import * as Sentry from '@sentry/vue';

import { FeatureFlag, prefStorage, setupStyling } from '@samknows/utils';

import App from '@/App.vue';
// Services
import AuthService from '@/common/services/Auth';
import { BrowserTracing } from '@sentry/vue';
import VTooltipPlugin from 'v-tooltip';
import Vue from 'vue';
import GlobalEvents from 'vue-global-events';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import config from '@/common/config';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { handleGlobalErrors } from '@/common/helpers/error-handling';
import { initI18n } from '@samknows/design-system';
import { initiateHeap } from '@/common/services/heapTracking';
import { initiateHotJar } from '@/common/services/hotJarTracking';
import isBetween from 'dayjs/plugin/isBetween';
import localeDataDaysJs from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import router from '@/common/router';
import store from '@/common/store';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(localeDataDaysJs);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

Vue.use(VTooltipPlugin, {
  defaultTrigger: 'hover focus click'
});
// Load global vue components
Vue.component('GlobalEvents', GlobalEvents);

// Import Filters
require('@/common/helpers/filters');

handleGlobalErrors();

// This should ideally be inside createApp - if the user locale is ever passed
// to the backend for SSR, it'll cause issues
const i18n = initI18n(config, prefStorage.get<string>('locale'), (locale) => {
  Sentry.setTag('locale', locale);
  dayjs.locale(locale.toLowerCase());
});

Sentry.init({
  Vue: Vue,
  dsn: 'https://1538bbf84b9a40c6822e706b7275a64f@sentry.io/144326',
  logErrors: true,
  environment: process.env.VUE_APP_DEPLOY_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracingorigins
      // Setting to undefined so extra header to not added to any BE call and doesn't result in CORS errors (default is ['localhost', /^\//])
      tracingOrigins: [undefined]
    })
  ],
  // Set enableTracing to false to deactivate
  // capturing of transactions for performance monitoring.
  enableTracing: false
});

initiateHeap();

// Setup callbacks for Http calls
AuthService.setupHttpCallbacks();

// Set up feature flags to go to the right URL on page load for logged in users
FeatureFlag.init({
  url: config.api.featureFlag
});

// Initiate App Vue instance
new Vue({
  components: {
    App
  },
  data: () => ({ AuthService }),
  render: (h) => h('app'),
  store,
  router,
  i18n
}).$mount('#app');

AuthService.setup();

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
}

setupStyling();

window.jsBundleLoaded = true;

// in case the bundle took a long time to download
document.getElementById('bundle-fail-warn').style.display = 'none';

const isProduction = process.env.VUE_APP_DEPLOY_ENV === 'production';
const urlParams = new URLSearchParams(window.location.search);
const hotjarParam = urlParams.get('hotjar');
const isHotjarTestMode = hotjarParam === 'true';

if (isHotjarTestMode || (isProduction && !AuthService.isSkStaff())) {
  initiateHotJar();
}
