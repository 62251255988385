<template>
  <header
    v-if="showNavbar"
    :class="{ 'mobile-search-height': showNavbarSearch && isMobileSearch }"
  >
    <!--
      Fixed navbar breaks auto upward scrolling when dragging.
      Use position absolute where possible.
    -->
    <nav
      :class="{
        'nav-bar--deprecation-banner':
          isDeprecationBannerEnabled && isDeprecationBannerShown
      }"
      class="nav-bar"
      :style="
        (!hasSidebar && { position: 'absolute' },
        { '--banner-height': deprecationBannerHeight + 'px' })
      "
    >
      <div
        v-if="hasSidebar"
        class="nav-bar__sidebar"
        :class="{ 'nav-bar__sidebar--hide': hideSidebar }"
      >
        <button
          v-tooltip:bottom-center="
            hideSidebar
              ? $t('components.ecosystems.Navbar.showSidebar')
              : $t('components.ecosystems.Navbar.hideSidebar')
          "
          class="unstyled-button nav-bar__sidebar-toggle"
          @click="$store.commit('menus/toggleSidebar')"
        >
          <SvgIcon
            :name="hideSidebar ? 'chevron-right' : 'chevron-left'"
            size="lg"
            aria-hidden="true"
          />
        </button>
      </div>

      <div class="nav-bar__main" :class="{ 'is-white-label': customLogo }">
        <div class="nav-bar__header">
          <router-link
            data-cy="home-link"
            class="nav-bar__brand"
            :class="{ 'hidden-xs hidden-sm': hasSidebar }"
            to="/"
          >
            <div v-if="customLogo" class="logo custom-logo">
              <img class="logo__img" :src="customLogo" />
            </div>
            <div v-else class="logo logo--samknows">
              <img
                class="logo__img"
                :src="'/img/logo.svg'"
                :alt="$t('common.altSkLogo')"
              />
            </div>
          </router-link>

          <button
            v-show="hasSidebar"
            type="button"
            class="nav-bar__menu-toggle"
            @click="toggleMenu()"
          >
            <SvgIcon name="menu" />
          </button>
        </div>

        <div class="nav-bar__nav u-flex">
          <NavbarSearchForm
            v-if="showNavbarSearch && !isMobileSearch"
            class="nav-bar__search u-margin-right-4x u-flex u-align-center u-color-primary-text"
          />

          <ul v-if="authed" key="authed" class="nav-bar__menus">
            <li v-if="!volunteer">
              <ul
                v-if="pinnedApps.length"
                v-sortable-navbar="{
                  els: pinnedApps,
                  group: 'nav-apps',
                  name: 'pinned'
                }"
                data-tracking="navbar-pinned"
                class="nav-bar__pinned use-hover"
              >
                <li
                  v-for="app in pinnedApps"
                  :key="app.id"
                  class="nav__link-wrapper"
                >
                  <a
                    v-if="app.link.includes('//')"
                    class="nav__link"
                    :data-cy="`nav-link-${app.id}`"
                    :href="app.link"
                    target="_blank"
                  >
                    <span
                      class="link__icon"
                      :class="app.icon"
                      aria-hidden="true"
                    ></span>
                    <div class="link__text">
                      <span class="link__text-head">{{ app.name }}</span>
                      <span class="link__text-sub">{{ app.description }}</span>
                    </div>
                  </a>

                  <router-link
                    v-else
                    :to="app.link"
                    class="nav__link"
                    :class="{
                      'router-link-active': $route.path.startsWith(
                        app.activeLink
                      )
                    }"
                    :data-cy="`nav-link-${app.id}`"
                  >
                    <span
                      class="link__icon"
                      :class="app.icon"
                      aria-hidden="true"
                    ></span>
                    <div class="link__text">
                      <span class="link__text-head">{{ app.name }}</span>
                      <span class="link__text-sub">{{ app.description }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </li>

            <li
              v-if="!volunteer && notPinnedApps.length"
              class="drop-link-wrapper"
            >
              <button
                v-tooltip:bottom-center="$t('navigation.appMenuIconTooltip')"
                class="unstyled-button nav-bar__drop-link"
                data-cy="navbar-app-menu-btn"
                @click.stop="toggleAppMenu()"
                @dragover="toggleAppMenu(true)"
              >
                <span class="sk-icon-grid sk-icon-lg" aria-hidden="true"></span>
              </button>

              <DropdownBubble
                :is-visible="appMenuOpen"
                :vertical-offset="{ direction: 'top', value: '75px' }"
                px-wrapper-right="-19px"
                pointer-orientation="top"
                px-pointer-position="32px"
                @close="appMenuOpen = false"
              >
                <ul
                  v-sortable-navbar="{
                    els: notPinnedApps,
                    group: 'nav-apps',
                    name: 'not-pinned'
                  }"
                  class="nav-bar__not-pinned use-hover"
                  :class="{
                    'nav-bar__not-pinned--grid': notPinnedApps.length > 8
                  }"
                  data-cy="app-menu-list"
                >
                  <li
                    v-for="app in notPinnedApps"
                    :key="app.id"
                    class="nav__link-wrapper"
                  >
                    <NavLink :link="app" />
                  </li>
                </ul>

                <div
                  v-if="activeView.type !== 'units'"
                  class="dnd-instructions"
                >
                  {{ $t('navigation.dndInstructions') }}
                </div>
              </DropdownBubble>
            </li>

            <li v-if="!volunteer" class="drop-link-wrapper">
              <button
                v-tooltip:bottom-center="$t('navigation.accountIconTooltip')"
                class="unstyled-button nav-bar__drop-link"
                data-cy="sk-account-switcher"
                @click.stop="toggleAccountSwitcher"
              >
                <SvgIcon
                  class="nav-bar__icon"
                  name="user"
                  aria-hidden="true"
                  size="md"
                />
              </button>
              <div
                v-if="unreadUpdates && notify"
                class="updates-circle"
                data-percy="hidden"
              >
                {{ unreadUpdates }}
              </div>
              <AccountSwitcher
                :display="showAccountSwitcher"
                pointer-orientation="top"
                :vertical-offset="{ direction: 'top', value: '75px' }"
                px-wrapper-right="0px"
                px-pointer-position="18px"
                @close="showAccountSwitcher = false"
              />
            </li>

            <li v-if="volunteer">
              <ul class="nav-bar__pinned">
                <li class="nav__link-wrapper">
                  <router-link class="nav__link" :to="{ name: 'FaqHome' }">
                    {{ $t('navigation.faqs') }}
                  </router-link>
                </li>
                <li class="nav__link-wrapper">
                  <router-link
                    class="nav__link"
                    data-cy="nav-link-logout"
                    to="/logout"
                  >
                    {{ $t('common.linkLogout') }}
                  </router-link>
                </li>
                <li class="nav__link-wrapper">
                  <a class="nav__link" @click="showLanguageSwitcher">
                    <SvgIcon
                      class="u-margin-right-2x"
                      name="chevron-down"
                      aria-hidden="true"
                    />
                    {{ activeLocale.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <ul
            v-else-if="showNotAuthedNav"
            key="not-authed"
            class="nav-bar__menus nav-bar__pinned"
            data-cy="not-authed-nav"
          >
            <li class="nav__link-wrapper">
              <NavLink :link="faqLink" />
            </li>
            <li data-cy="sk-language-switcher">
              <a class="nav-bar__language-link" @click="showLanguageSwitcher">
                <SvgIcon
                  class="u-margin-right-2x"
                  name="chevron-down"
                  aria-hidden="true"
                />
                {{ activeLocale.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <NavbarSearchForm
        v-if="showNavbarSearch && isMobileSearch"
        class="nav-bar__mobile-search u-flex u-color-primary-text"
      />
    </nav>
    <GlobalEvents target="window" @resize="handleResize" />
  </header>
</template>

<script>
import Vue from 'vue';
import Sortable from 'sortablejs';
import AuthService from '@/common/services/Auth';
import AccountSwitcher from '@/common/components/AccountSwitcher.vue';
import NavbarSearchForm from '@/features/unit-search/components/searchForm/NavbarSearchForm.vue';
import DropdownBubble from '@/common/components/DropdownBubble.vue';
import NavLink from '@/common/components/NavLink.vue';
import config from '@/common/config';
import { prefStorage, whitelabel, FeatureFlag } from '@samknows/utils';
import { SvgIcon } from '@samknows/design-system';
import viewTypes from '@/common/helpers/view-types';
import { GRANT_SECTION } from '@/features/account/constants';
import { heapTrack } from '../services/heapTracking';
import { FEATURE_FLAGS } from '@/common/global.interfaces';

// This is used to store an item being moved between navigation lists
let movingEl;

export default {
  directives: {
    'sortable-navbar': {
      inserted(el, binding) {
        const sorting = binding.value.els;

        function saveOrder(listName, els) {
          prefStorage.set(
            `${viewTypes.viewType}-${listName}-order`,
            els.map((app) => app.id),
            true,
            true
          );
        }

        const options = {
          group: binding.value.group,
          animation: 200,
          fallbackTolerance: 5,
          // Event when you move an item in the list or between lists
          onMove() {
            return sorting.length > 1;
          },
          // Element dragging started
          onStart(e) {
            this.el.classList.remove('use-hover');
            document.body.classList.add('navbar-dragging');
            movingEl = sorting[e.oldIndex];
          },
          // Element dragging ended
          onEnd() {
            this.el.classList.add('use-hover');
            document.body.classList.remove('navbar-dragging');
            movingEl = undefined;
          },
          // Element is dropped into the list from another list
          onAdd(e) {
            sorting.splice(e.newIndex, 0, movingEl);
            heapTrack('NavbarDraggedItem', {
              draggedLinkItem: movingEl.name,
              addedToPinnedLinks: e.to.dataset.tracking === 'navbar-pinned'
            });
          },
          // Element is removed from the list into another list
          onRemove(e) {
            sorting.splice(e.oldIndex, 1);
          },
          // Changed sorting within list
          onUpdate(e) {
            const movedEl = sorting.splice(e.oldIndex, 1);
            sorting.splice(e.newIndex, 0, movedEl[0]);
          },
          // Called by any change to the list (add / update / remove)
          onSort() {
            saveOrder(binding.value.name, sorting);
          }
        };

        Sortable.create(el, options);
      }
    }
  },
  components: {
    AccountSwitcher,
    DropdownBubble,
    NavLink,
    SvgIcon,
    NavbarSearchForm
  },
  props: {
    showNavbarSearch: {
      type: Boolean,
      default: false
    },
    authed: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showAccountSwitcher: false,
    volunteer: AuthService.isVolunteer(),
    activeView: viewTypes.activeView,
    appMenuOpen: false,
    notify: prefStorage.get('notify-updates', true),
    isMobile: window.innerWidth < 992,
    pinnedApps: [],
    notPinnedApps: [],
    isMobileSearch: window.innerWidth < 768
  }),
  computed: {
    deprecationBannerHeight() {
      return this.$store.state.menus.deprecationBannerHeight;
    },
    activeLocale() {
      return config.locales.find((locale) => locale.code === Vue.config.lang);
    },
    menuOpen() {
      return this.$store.state.menus.menuOpen;
    },
    hasSidebar() {
      return this.$store.state.menus.hasSidebar;
    },
    hideSidebar() {
      return this.$store.state.menus.hideSidebar;
    },
    customLogo() {
      return whitelabel('samknows_one_logo');
    },
    unreadUpdates() {
      return this.$store.state.featureUpdates.newUpdates.length;
    },
    showNotAuthedNav() {
      return !this.authed && !this.$route.meta.hideNotAuthedNav;
    },
    faqLink() {
      return {
        id: 'faqs', // used by sortable - do not change
        name: this.$t('navigation.faqs'),
        icon: 'sk-icon-faqs1',
        link: this.faqsUrl(),
        description: this.$t('navigation.faqsSubText'),
        isDefaultPinned: false,
        hasPermission: true
      };
    },
    showNavbar() {
      return this.$route.query.navbar !== 'false';
    },
    isDeprecationBannerEnabled() {
      return FeatureFlag.isEnabled(FEATURE_FLAGS.SHOW_DEPRECATION_BANNER);
    },
    isDeprecationBannerShown() {
      return this.$store.state.menus.isDeprecationBannerShown;
    }
  },
  watch: {
    $route() {
      this.init();
    }
  },
  mounted() {
    this.init();
    // this has to happen after init()
    if (this.authed) {
      this.$store.dispatch('featureUpdates/getUpdatesUnread');
    }

    const pinnedLinks = this.pinnedApps.map((item) => item.name);
    const notPinnedLinks = this.notPinnedApps.map((item) => item.name);
    heapTrack('NavbarLoadLinks', {
      pinnedLinksTotal: pinnedLinks.length,
      notPinnedLinksTotal: notPinnedLinks.length,
      pinnedLinks: pinnedLinks.join(', '),
      notPinnedLinks: notPinnedLinks.join(', ')
    });
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 992;
      this.isMobileSearch = window.innerWidth < 768;

      this.init();
    },
    unitsSearchPermission() {
      const hasRequiredPermissions = AuthService.hasPermissions(
        GRANT_SECTION.UNIT,
        'search'
      );
      const isEnterpriseUser = viewTypes.isEnterpriseView;
      return hasRequiredPermissions && isEnterpriseUser;
    },
    init() {
      if (!this.authed) {
        return;
      }

      this.pinnedApps = [];
      this.notPinnedApps = [];

      this.volunteer = AuthService.isVolunteer();
      const isHomeUser = viewTypes.isConsumerView;
      const isEnterpriseUser = viewTypes.isEnterpriseView;

      const appList = [
        {
          id: 'network_performance', // used by sortable - do not change
          name: this.$t('common.linkNetworkPerformance'),
          icon: 'sk-icon-Network-Performance',
          link: '/network-performance',
          activeLink: '/network-performance',
          description: this.$t(
            'components.pages.enterprise.LandingDashboard.labelPrivate'
          ),
          isDefaultPinned: false,
          hasPermission: false
        },
        {
          id: 'dashboard', // used by sortable - do not change
          name: this.$t(
            isHomeUser ? 'common.linkHealth' : 'common.linkDashboards'
          ),
          icon: 'sk-icon-Dashboard-Icon',
          link: isHomeUser ? '/dashboard' : '/dashboards',
          activeLink: '/dashboard',
          description: this.$t(
            'components.pages.enterprise.Dashboard.labelPrivate'
          ),
          isDefaultPinned: true,
          hasPermission:
            isHomeUser ||
            AuthService.hasPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'reports'
            )
        },
        {
          id: 'analytics', // used by sortable - do not change
          name: this.$t('common.linkAnalytics'),
          icon: 'sk-icon-Analytics-Icon',
          link: isHomeUser ? '/analytics' : '/analytics/presets',
          activeLink: '/analytics',
          description: this.$t('navigation.analyticsSubText'),
          isDefaultPinned: true,
          hasPermission: AuthService.analyticsAccess()
        },
        {
          id: 'mapping', // used by sortable - do not change
          name: this.$t('common.linkMapping'),
          icon: 'sk-icon-Mapping-Icon',
          link: '/mapping/presets',
          activeLink: '/mapping',
          description: this.$t('navigation.mappingSubText'),
          isDefaultPinned: true,
          hasPermission:
            AuthService.hasPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'maps'
            ) && isEnterpriseUser
        },
        {
          id: 'anomaly-detection', // used by sortable - do not change
          name: this.$t('common.linkAnomalyDetection'),
          icon: 'sk-icon-Anomaly-Detection-Icon',
          link: '/fault-finder',
          description: this.$t('navigation.anomalyDetectionSubtext'),
          isDefaultPinned: false,
          hasPermission:
            AuthService.hasPermissions('AnomalyDetection', 'view') &&
            isEnterpriseUser
        },
        {
          id: 'data-api', // used by sortable - do not change
          name: this.$t('components.pages.enterprise.Admin.DataExport.title'),
          icon: 'sk-icon-data-export',
          link: '/data-export',
          description: this.$t('navigation.dataExportSubText'),
          isDefaultPinned: false,
          hasPermission:
            AuthService.hasPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'data_api'
            ) && isEnterpriseUser
        },
        {
          id: 'reports', // used by sortable - do not change
          name: this.$t('common.linkReports'),
          icon: 'sk-icon-reports',
          link: '/reports',
          description: this.$t('navigation.reportsSubText'),
          isDefaultPinned: false,
          hasPermission:
            AuthService.hasPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'reports'
            ) && isEnterpriseUser
        },
        {
          id: 'admin', // used by sortable - do not change
          name: this.$t('common.linkAdmin'),
          icon: 'sk-icon-filters',
          link: '/admin',
          description: this.$t('navigation.adminSubText'),
          isDefaultPinned: false,
          hasPermission: AuthService.adminAccess() && isEnterpriseUser
        },
        {
          id: 'test-manager', // used by sortable - do not change
          name: this.$t('common.linkTestManager'),
          icon: 'sk-icon-TestServerMonitoring',
          link: '/test-manager',
          description: this.$t('navigation.testManagerSubText'),
          isDefaultPinned: false,
          hasPermission:
            AuthService.hasPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'test_schedules_manage'
            ) && isEnterpriseUser
        },
        this.faqLink,
        {
          id: 'xenia', // used by sortable - do not change
          name: 'Xenia',
          icon: 'sk-icon-filters',
          link: '/xenia',
          description: 'SamKnows internal tools',
          isDefaultPinned: false,
          hasPermission: AuthService.globalAccess()
        },
        {
          id: 'apis', // used by sortable - do not change
          name: this.$t('pages.apis.header'),
          icon: 'sk-icon-DataAPI-Icon',
          link: '/apis',
          description: this.$t('navigation.apisSubText'),
          isDefaultPinned: false,
          hasPermission:
            (AuthService.hasPermissions(
              GRANT_SECTION.TEST_MEASUREMENTS,
              'data_api'
            ) ||
              AuthService.hasPermissions(
                GRANT_SECTION.TEST_MEASUREMENTS,
                'metadata_api_manage'
              ) ||
              AuthService.hasPermissions(
                GRANT_SECTION.TEST_MEASUREMENTS,
                'cpe_api_manage'
              )) &&
            isEnterpriseUser
        },
        {
          id: 'constant-care', // used by sortable - do not change
          name: this.$t('common.linkConstantCare'),
          icon: 'sk-icon-TriggerTesting',
          link: '/units/search',
          activeLink: '/units',
          description: this.$t('navigation.constantCareSubText'),
          isDefaultPinned: false,
          hasPermission: this.unitsSearchPermission()
        }
      ].filter((app) => app.hasPermission);

      if (this.isMobile) {
        this.notPinnedApps = appList;
        return;
      }

      if (appList.length < 4) {
        this.pinnedApps = appList;
        return;
      }

      function sortApps(orderArray) {
        const sortedApps = [];

        orderArray.forEach((id) => {
          const appIndex = appList.findIndex((app) => app.id === id);
          if (appIndex >= 0) {
            sortedApps.push(appList.splice(appIndex, 1)[0]);
          }
        });

        return sortedApps;
      }

      // Deal with legacy preferences
      const legacyPinnedOrder = prefStorage.get('pinned-order');
      if (legacyPinnedOrder) {
        prefStorage.set('apollo-pinned-order', legacyPinnedOrder, true, true);
        prefStorage.set('pinned-order', undefined, true, true);

        const legacyNotPinnedOrder = prefStorage.get('not-pinned-order');
        prefStorage.set(
          'apollo-not-pinned-order',
          legacyNotPinnedOrder,
          true,
          true
        );
        prefStorage.set('not-pinned-order', undefined, true, true);
      }

      const pinnedOrder = prefStorage.get(`${viewTypes.viewType}-pinned-order`);
      const notPinnedOrder = prefStorage.get(
        `${viewTypes.viewType}-not-pinned-order`
      );

      const newPinnedApps = pinnedOrder
        ? sortApps(pinnedOrder)
        : appList.filter((app) => app.isDefaultPinned);

      // Use splice
      this.pinnedApps.splice(0, this.pinnedApps.length, ...newPinnedApps);

      const newNotPinnedApps = notPinnedOrder
        ? sortApps(notPinnedOrder).concat(appList)
        : appList.filter((app) => !app.isDefaultPinned);

      this.notPinnedApps.splice(
        0,
        this.notPinnedApps.length,
        ...newNotPinnedApps
      );
    },
    showLanguageSwitcher() {
      this.$store.commit('languageSwitcher/SET_VISIBILITY', true);
    },
    toggleAccountSwitcher() {
      this.showAccountSwitcher = !this.showAccountSwitcher;

      if (this.showAccountSwitcher) {
        this.appMenuOpen = false;
      }
    },
    toggleAppMenu(open) {
      if (open && this.appMenuOpen) {
        return;
      }

      this.appMenuOpen = !this.appMenuOpen;

      if (this.appMenuOpen) {
        this.showAccountSwitcher = false;
      }
    },
    toggleMenu() {
      this.$store.commit('menus/toggleMenu');
    },
    faqsUrl() {
      return whitelabel('faqs') || '/hc';
    }
  }
};
</script>

<style lang="scss" scoped>
$height: rem-calc(60px);
$search-height: rem-calc(120px);
$banner-height: var(--banner-height);

header {
  height: $height;
}

.mobile-search-height {
  height: $search-height;
}

.nav-bar {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  &--deprecation-banner {
    top: $banner-height;
  }

  color: color(primary-text);
  background: color(base-background);
  border-bottom: $border-normal;

  @include breakpoint(S) {
    display: flex;
  }
}

.nav-bar__header {
  display: flex;
}

.nav-bar__search {
  width: rem-calc(350px);
}

.nav-bar__mobile-search {
  height: $height;
}

.nav-bar__brand {
  .logo {
    height: $height;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .logo__img {
    max-height: 100%;
  }

  .logo--samknows {
    height: $height;
    margin-top: 0;
    padding: 0 spacing('4x');

    @include breakpoint(extra-small) {
      padding: 0 rem-calc(5px);
    }

    .logo__img {
      width: rem-calc(104px);

      @include breakpoint(extra-small) {
        width: rem-calc(70px);
      }

      @include breakpoint(large) {
        width: rem-calc(80px);
      }
    }
  }

  [dir='rtl'] & {
    border-right: none;
    border-left: rem-calc(1px) solid color(lines);
  }
}

.nav-bar__menu-toggle {
  display: block;

  height: $height;
  width: $height;
  padding: 0;

  border: 0;
  background: transparent;

  @include breakpoint(large) {
    display: none;
  }

  &:active {
    top: rem-calc(1px);
  }
}

.nav-bar__menus {
  display: flex;
  // Needed so the navigation opens over the search form on mobile
  z-index: 20;

  margin: 0;
  margin-bottom: rem-calc(-1px);
  padding: 0;
  white-space: nowrap;

  list-style: none;

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
  }
}

.dnd-instructions {
  padding: rem-calc(15px) rem-calc(20px);

  white-space: normal;
  text-align: center;
  color: color(primary-text);

  background: color('neutral-background');
  border-bottom-left-radius: rem-calc(5px);
  border-bottom-right-radius: rem-calc(5px);
}

.nav-bar__main {
  &.is-white-label {
    padding-left: 0;
  }

  display: flex;

  justify-content: space-between;
  flex: 1;

  padding: 0 rem-calc(10px);

  [dir='rtl'] & {
    padding: 0 0 0 rem-calc(10px);
  }
}

.nav-bar__drop-link {
  padding: 1em;

  height: $height;

  display: flex;
  align-items: center;
  color: color(primary-text);

  &:hover {
    color: inherit;
  }
}

.nav-bar__language-link {
  padding: 1em;

  height: $height;

  display: flex;
  align-items: center;

  &:hover {
    color: inherit;
  }
}

.nav-bar__pinned {
  display: flex;

  :deep(.nav__link) {
    padding: 1em;

    height: $height;

    display: flex;
    align-items: center;
    border-bottom: rem-calc(3px) solid transparent;
    border-top: rem-calc(3px) solid transparent;
    color: color(navigation-default-text);

    &.router-link-active {
      font-weight: 600;
      color: color(navigation-selected-text);
    }

    &:hover {
      color: inherit;
    }
  }

  :deep(.link__icon),
  :deep(.link__text-sub) {
    display: none;
  }
}

.nav-bar__not-pinned {
  padding: rem-calc(15px);

  :deep(.nav__link) {
    display: flex;
    padding: rem-calc(8px);

    align-items: center;
    background: white;
    border: rem-calc(1px) solid transparent;

    color: color(navigation-default-text);

    transition: all 200ms ease-in-out;

    &.router-link-active {
      color: color(navigation-selected-text);
      background: color('navigation-selected-background');
    }
  }

  &.use-hover :deep(.nav__link:hover) {
    background: color(navigation-hover-background);
    color: color(navigation-selecting-text);
  }

  :deep(.link__icon) {
    margin: rem-calc(5px) 0;

    font-size: 2em;
  }

  :deep(.link__text) {
    width: 100%;
    margin: 0 rem-calc(15px);
  }

  :deep(.link__text-head) {
    display: block;

    margin: 0;

    font-size: 1.3em;
  }

  :deep(.link__text-sub) {
    display: block;

    margin-top: rem-calc(-3px);
    margin-bottom: 0;

    color: inherit;
  }
}

.nav-bar__not-pinned--grid {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  max-width: rem-calc(280px);

  .nav__link-wrapper {
    width: 33.3%;
  }

  :deep(.nav__link) {
    flex-direction: column;
  }

  :deep(.link__icon) {
    font-size: 2.5em;
  }

  :deep(.link__text) {
    margin-top: rem-calc(5px);
  }

  :deep(.link__text-head) {
    font-size: 1em;
    text-overflow: ellipsis;
    text-align: center;

    overflow: hidden;
  }

  :deep(.link__text-sub) {
    display: none;
  }
}

.navbar-dragging .nav__link-wrapper {
  :deep(.link__text),
  :deep(.link__icon) {
    animation-name: wiggle;
    animation-duration: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform-origin: 50% 10%;
  }

  :deep(.link__text) {
    --rotate-from: -0.5deg;
    --rotate-to: 0.5deg;
  }

  :deep(.link__icon) {
    --rotate-from: 2deg;
    --rotate-to: -2deg;
  }

  &:nth-child(odd) {
    :deep(.link__text),
    :deep(.link__icon) {
      animation-delay: 0.1s;
    }
  }
}

.navbar-dragging .nav-bar__pinned :deep(.link__text) {
  --rotate-from: -1.5deg;
  --rotate-to: 1.5deg;
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(var(--rotate-from));
  }

  50% {
    transform: rotate(var(--rotate-to));
  }
}

$sidebar-width: 22rem;

.nav-bar__sidebar {
  width: $sidebar-width;
  background: color('neutral-background');
  border-right: rem-calc(1px) color(lines) solid;
  transition: margin 0.2s ease-in-out;
  display: none;

  @include breakpoint(large) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  [dir='rtl'] & {
    border-right: none;
    border-left: rem-calc(1px) color(lines) solid;
  }

  &--hide {
    margin-left: calc(#{$height} + 1px - #{$sidebar-width});

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: calc(#{$height} + 1px - #{$sidebar-width});
    }
  }
}

.nav-bar__sidebar-toggle {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 3.75rem;

  color: color(primary-text);
}

// Be careful when updating this style as it is responsible for drag and drop feature
.drop-link-wrapper {
  position: relative;
}

.sortable-ghost {
  opacity: 0.5;
}

.updates-circle {
  position: absolute;
  right: 0.6em;
  top: 0.8em;

  padding: 0 0.4em;
  min-width: 1.4em;

  background: color(error);
  border-radius: rounding('full');

  line-height: 1.4em;
  font-weight: bold;
  font-size: 0.9em;
  color: white;
  text-align: center;
}
</style>
