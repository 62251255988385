import config from '@/common/config';
import viewTypes from '@/common/helpers/view-types';

const MainSupport = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "support" */ '@/features/help-center/pages/SupportView.vue'
  );
const FaqHome = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "support" */ '@/features/help-center/pages/Homepage.vue'
  );
const FaqCategory = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "support" */ '@/features/help-center/pages/Category.vue'
  );
const FaqSection = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "support" */ '@/features/help-center/pages/Section.vue'
  );
const FaqArticle = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "support" */ '@/features/help-center/pages/Article.vue'
  );
const FaqSearchResults = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "support" */ '@/features/help-center/pages/SearchResults.vue'
  );
const FaqRequestForm = (): Promise<unknown> =>
  import(
    /* webpackChunkName: "support" */ '@/features/help-center/pages/RequestForm.vue'
  );

const supportMainRoute = config.locales
  .filter((locale) => !!locale.zdCode)
  .map((locale) => locale.zdCode)
  .join('|');

export default [
  {
    path: `/hc/(|${supportMainRoute})?`,
    component: MainSupport,
    children: [
      {
        path: '',
        name: 'FaqHome',
        component: FaqHome,
        meta: {
          isHome: true,
          hideNotAuthedNav: true
        }
      },
      {
        path: 'categories/:id',
        name: 'FaqCategory',
        component: FaqCategory,
        meta: {
          hideNotAuthedNav: true
        }
      },
      {
        path: 'sections/:id',
        name: 'FaqSection',
        component: FaqSection,
        meta: {
          hideNotAuthedNav: true
        }
      },
      {
        path: 'articles/:id',
        name: 'FaqArticle',
        component: FaqArticle,
        meta: {
          hideNotAuthedNav: true
        }
      },
      {
        path: 'search',
        name: 'FaqSearchResults',
        component: FaqSearchResults,
        meta: {
          hideNotAuthedNav: true
        }
      },
      {
        path: 'requests/new',
        name: 'FaqRequestForm',
        component: FaqRequestForm,
        meta: {
          hideNotAuthedNav: true,
          guard: (): boolean => !viewTypes.isEnterpriseView
        }
      }
    ]
  }
];
