import Vue from 'vue';

export interface Period {
  duration: number;
  unit: string;
}

export function idToText<
  T extends { id: number } & { [key in Key]: string },
  Key extends string
>(data: T[], input: { data?: { id: number } } | number, key: Key): string {
  if (!input) {
    return '';
  }
  const idToFind = (typeof input === 'object' && input.data?.id) ?? input;
  return data.find(({ id }) => id === Number(idToFind))?.[key];
}

export function minutesToText(durationInMinutes: number): Period {
  let unitDuration = durationInMinutes;
  let unit = 'minutes';

  if (unitDuration === 60) {
    unitDuration = 1;
    unit = 'hour';
  }
  if (durationInMinutes > 60) {
    unitDuration = durationInMinutes / 60;
    unit = 'hours';
  }

  return {
    duration: unitDuration,
    unit: Vue.t(`common.${unit}`)
  };
}

export function capitalise(word: string): string {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function snakeCaseToSentenceCase(text: string): string {
  return text.replace(/_/g, ' ').replace(text[0], text[0].toUpperCase());
}
