import { en, fr, ar } from 'vuejs-datepicker/dist/locale';
import { AppConfig, Locale } from '@samknows/design-system';

interface SkOneLocale extends Locale {
  zdCode?: string;
  dateLocale: unknown;
}

interface SkOneConfig extends AppConfig {
  api: Record<string, string>;
  locales: SkOneLocale[];
}

const config: SkOneConfig = {
  api: {
    anomalyDetection: process.env.VUE_APP_ANOMALY_DETECTION_API,
    auth: process.env.VUE_APP_AUTH_API,
    analytics: process.env.VUE_APP_ANALYTICS_API,
    unitAnalytics: process.env.VUE_APP_UNIT_ANALYTICS_API,
    zeus: process.env.VUE_APP_ZEUS_API,
    data_api: process.env.VUE_APP_DATA_API,
    triggerTesting: process.env.VUE_APP_TRIGGER_TESTING_API,
    ingestion: process.env.VUE_APP_INGESTION_API,
    featureFlag: process.env.VUE_APP_FEATURE_FLAG_API,
    caf: process.env.VUE_APP_CAF_API
  },
  locales: [
    {
      code: 'en-GB',
      name: 'English (UK)',
      englishName: 'English (UK)',
      zdCode: 'en-gb',
      dateLocale: en,
      translations: require('../i18n/en-GB.json')
    },
    {
      code: 'ar-SA',
      name: 'العربية',
      englishName: 'Arabic',
      rtl: true,
      dateLocale: ar,
      translations: require('../i18n/ar-SA.json')
    },
    {
      code: 'fr-CA',
      name: 'Français Canadien',
      englishName: 'French (Canada)',
      dateLocale: fr,
      zdCode: 'fr',
      translations: require('../i18n/fr-CA.json')
    }
  ]
};

export default config;
