import AuthService from '@/common/services/Auth';
import { GRANT_SECTION } from '../account/constants';
import viewTypes from '@/common/helpers/view-types';

const PageEnterpriseLandingDashboard = (): Promise<unknown> =>
  import('@/features/dashboard/pages/enterprise/LandingDashboardView.vue');
const PageEnterpriseDashboards = (): Promise<unknown> =>
  import('@/features/dashboard/pages/enterprise/DashboardsView.vue');
const PageEnterpriseDashboard = (): Promise<unknown> =>
  import('@/features/dashboard/pages/enterprise/DashboardView.vue');
const PageEnterpriseReport = (): Promise<unknown> =>
  import('@/features/dashboard/pages/enterprise/ReportView.vue');
const CAFPerformanceReport = (): Promise<unknown> =>
  import('@/features/dashboard/pages/enterprise/CAFPerformanceReport.vue');
const routes = [
  {
    path: '/network-performance',
    component: PageEnterpriseLandingDashboard,
    meta: {
      hasSideBar: true,
      guard: () =>
        viewTypes.isEnterpriseView &&
        AuthService.hasPermissions(
          GRANT_SECTION.TEST_MEASUREMENTS,
          'network_performance'
        )
    }
  },
  // Show enterprise dashboard with id
  {
    path: '/dashboard/:dashboardId',
    component: PageEnterpriseDashboard,
    meta: {
      hasSideBar: true,
      guard: () =>
        viewTypes.isEnterpriseView &&
        AuthService.hasPermissions(GRANT_SECTION.TEST_MEASUREMENTS, 'reports')
    }
  },
  {
    path: '/reports',
    component: PageEnterpriseDashboards,
    meta: {
      hasBackground: true,
      guard() {
        return (
          viewTypes.isEnterpriseView &&
          AuthService.hasPermissions(GRANT_SECTION.TEST_MEASUREMENTS, 'reports')
        );
      }
    }
  },
  {
    path: '/report/caf-performance-report',
    component: CAFPerformanceReport,
    name: 'CAFPerformanceReport',
    meta: {
      hasBackground: true,
      guard() {
        return viewTypes.isEnterpriseView;
      }
    }
  },
  {
    path: '/report/:dashboardId',
    component: PageEnterpriseReport,
    meta: {
      hasSideBar: true,
      guard() {
        return (
          viewTypes.isEnterpriseView &&
          AuthService.hasPermissions(GRANT_SECTION.TEST_MEASUREMENTS, 'reports')
        );
      }
    }
  },
  // Overrides the home user dashboard URIs if they navigate to enterprise

  {
    path: '/health',
    redirect: '/dashboard'
  }
];

if (viewTypes.isEnterpriseView) {
  // Overrides the home user dashboard URIs if they navigate to enterprise
  const dashboardRedirects = [
    {
      path: '/dashboard',
      redirect: '/dashboards'
    },
    {
      path: '/dashboard/whitebox-tests',
      redirect: '/dashboards'
    },
    {
      path: '/dashboard/realspeed-tests',
      redirect: '/dashboards'
    },
    {
      path: '/dashboard/agent-details',
      redirect: '/dashboards'
    }
  ];
  // add redirect for dashboard before dashboard routes are defined
  routes.unshift(...dashboardRedirects);

  const enterpriseDashBoardsRoute = {
    path: '/dashboards',
    component: PageEnterpriseDashboards,
    meta: {
      hasBackground: true,
      guard: () =>
        viewTypes.isEnterpriseView &&
        AuthService.hasPermissions(GRANT_SECTION.TEST_MEASUREMENTS, 'reports')
    }
  };
  routes.unshift(enterpriseDashBoardsRoute);
} else {
  routes.unshift({
    // This will only affect home users
    path: '/dashboards',
    redirect: '/dashboard'
  });
}

export default routes;
