<template>
  <form
    class="search-form"
    :class="{ 'search-form--nav-bar': isNavbar }"
    @submit.prevent="handleSubmit"
    @change="handleFormChange"
  >
    <SearchField
      v-model="query"
      :placeholder="
        $t('components.pages.UnitSearch.Header.MetadataSearchPlaceholder')
      "
      :variant="iconOnly ? 'icon-only' : 'descriptive'"
      :autofocus="autofocus"
      trim
    />
    <BarcodeScanner
      :is-black="isBlack"
      :class="{ 'u-margin-right-4x': isNavbar }"
      @scan="handleScan"
    />
  </form>
</template>

<script>
import BarcodeScanner from '@/features/unit-search/components/barcodeScanner/BarcodeScanner.vue';
import SearchField from '@/common/components/search/SearchField.vue';
import searchType from '@/common/helpers/search-type';
import { getQueryStringSearch } from '../../helpers/search.helpers';
import { heapTrack } from '@/common/services/heapTracking';

export default {
  name: 'SearchForm',
  components: {
    BarcodeScanner,
    SearchField
  },
  props: {
    iconOnly: {
      type: Boolean,
      default: false
    },
    isBlack: {
      type: Boolean,
      default: true
    },
    isNavbar: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    query: ''
  }),
  computed: {
    formData() {
      return this.$store.state.unitSearch.agentsFormData;
    },
    panel() {
      return this.$store.state.panel;
    },
    hasResults() {
      return this.$store.state.unitSearch.resultData.length;
    }
  },
  watch: {
    $route() {
      this.$store.commit('unitSearch/setFormTouched', false);
      this.triggerSearch();
    },
    formData(data) {
      this.query = data.id || data.mac || data.email || data.undefined;
    }
  },
  async created() {
    const urlQueries = this.$route.query;
    const showResultsQS = urlQueries && urlQueries['showResults'];
    const showResults =
      showResultsQS && typeof showResultsQS !== 'undefined' ? true : false;

    this.query =
      this.formData.id ||
      this.formData.mac ||
      this.formData.email ||
      this.formData.undefined;

    if (!showResults) {
      this.$store.commit('unitSearch/setFormTouched', false);
      this.triggerSearch();
    }
  },
  methods: {
    triggerSearch() {
      // without this check the navbar query gets overwritten by the dateFilter in CC and breaks
      if (this.isNavbar) {
        return;
      }

      const urlQueries = this.$route.query;
      const searchQS = getQueryStringSearch(urlQueries);
      const { searchValue } = searchQS || {};

      if (searchValue) {
        this.$store.commit('unitSearch/setFormTouched', true);
        this.query = searchValue;
        this.handleSubmit();
      }
    },
    handleSubmit() {
      const queryLength = this.query?.length || 0;
      if (queryLength < 1) {
        return;
      }
      const value = this.query;
      const key = searchType(value);

      if (!value) {
        this.$store.commit('unitSearch/setAgentsFormData', {});
      }

      this.$store.commit('unitSearch/setAgentsFormData', { [key]: value });
      this.$store.commit('unitSearch/setSearchState', 'loading');
      this.$store.commit('unitSearch/setPage', 1);
      this.$store.commit('unitSearch/resetSort');

      this.$store.dispatch('unitSearch/metaSearch', {
        panelId: this.panel.pid,
        searchTerm: value
      });
    },
    handleFormChange() {
      this.$store.commit('unitSearch/setFormTouched', true);
    },
    handleScan(text) {
      this.query = text;
      heapTrack('barcodeScanned', {
        scanResult: text
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.search-form {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: spacing('4x');

  .search-field {
    flex: 1;
  }

  &.search-form--nav-bar {
    margin-top: 0;

    .search-field {
      @include breakpoint-manual('max-width', map-get($breakpoints, 'md')) {
        padding: 0 spacing('2x') 0 spacing('4x');
        max-width: none;
      }
    }
  }

  .barcode-scanner {
    display: flex;
    border: solid 1px color(lines);
    border-radius: rounding('1x');
    min-height: rem-calc(40px);
    min-width: rem-calc(40px);
    margin-left: spacing('4x');

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: spacing('2x');
    }
  }
}
</style>
