import * as dashboards from '@/features/dashboard/store/dashboards';
import * as reports from '@/features/dashboard/store/reports';
import * as analytics from './modules/analytics';
import * as dataAPI from './modules/dataAPI';
import * as featureUpdates from './modules/feature-updates';
import * as languageSwitcher from './modules/language-switcher';
import * as menus from './modules/menus';
import * as personalDetails from './modules/personal-details';
import * as presenter from './modules/presenter';
import * as support from './modules/support';
import * as xenia from './modules/xenia';

import type { Grants, Panel } from '@/common/global.interfaces';
import AuthService, { Accessibles } from '@/common/services/Auth';
import { GRANT_SECTION } from '@/features/account/constants';
import dataBuilder from '@/features/analytics/components/DataBuilder/store';
import anomalyDetection from '@/features/anomaly-detection/store';
import dataSubscriptions from '@/features/data-subscriptions/store';
import clientAdmin from '@/features/management-suite/store';
import unitSearch from '@/features/unit-search/store';
import unitTestsView from '@/features/unit-tests-view/store';
import apis from '@/features/apis/store';
import { prefStorage } from '@samknows/utils';
import Vue from 'vue';
import Vuex from 'vuex';

// Make vue aware of Vuex
Vue.use(Vuex);

export interface RootState {
  panel: Panel;
}

// Combine the initial state and the mutations to create a Vuex store.
const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  state(): RootState {
    return {
      panel: prefStorage.get<Panel>('panel', {} as Panel)
    };
  },

  mutations: {
    setPanel(state: RootState, panel: Panel) {
      state.panel = panel;
      prefStorage.set('panel', panel, true);
    }
  },

  actions: {
    /**
     * It's possible for some users to have access to multiple panels (e.g. VM plus access to UK Industry data) in different areas / sections
     * Reset the panel to the main panel for sections where they have access to only one panel
     *
     * @param  {} {commit}
     * @param  {} payload
     */
    resetPanelForSection(
      { commit, state },
      payload: {
        grantSection: GRANT_SECTION;
        grantPermission: string;
        setDefault?: boolean;
      }
    ) {
      /* Global panel should not reset for user with global access (e.g. SK Staff)
       as they always have access to more than one panel */
      if (AuthService.globalAccess()) {
        return;
      }
      const { grantSection, grantPermission, setDefault } = payload;
      const grants: Grants = prefStorage.get('grants');
      const accessibles: Accessibles = prefStorage.get('accessibles');
      const panelIds: Set<number> = new Set();
      const sectionGrants = grants?.[grantSection] || {};
      const permissionKeys = Object.keys(sectionGrants);
      const goodPanels: number[] = [];

      // Get just the panel keys from the Unit Grants
      permissionKeys.forEach((key) => {
        sectionGrants[key].forEach(({ panel }: { panel: number }) =>
          panelIds.add(panel)
        );
      });

      // Check the permissions for the panel
      for (const panelId of panelIds) {
        const panelHasPermission = AuthService.hasPermissions(
          grantSection,
          grantPermission,
          { panel: panelId }
        );

        if (panelHasPermission) {
          goodPanels.push(panelId);
        }
      }

      if (goodPanels.length === 1) {
        const panel = accessibles.apollo.find(
          ({ pid }: { pid: number }) => pid === goodPanels[0]
        );

        //  Update panel if different to current panel
        if (state.panel.pid !== panel.pid) {
          commit('setPanel', panel);
        }
      }

      if (goodPanels.length >= 1 && setDefault) {
        const panel = accessibles.apollo.find(
          ({ pid }: { pid: number }) => pid === goodPanels[0]
        );

        //  Update panel if different to current panel
        if (state.panel.pid !== panel.pid) {
          commit('setPanel', panel);
        }
      }
    }
  },

  modules: {
    analytics,
    anomalyDetection,
    dataBuilder,
    clientAdmin,
    dashboards,
    dataAPI,
    dataSubscriptions,
    featureUpdates,
    languageSwitcher,
    menus,
    personalDetails,
    presenter,
    reports,
    support,
    unitSearch,
    unitTestsView,
    xenia,
    apis
  }
});

export default store;
