/**
 * Helper class to ensure that switch cases cover all errors.
 * This will check at compile time that the switch has narrowed the type of the input to 'never'
 * by covering all cases.
 * If a runtime value is passed that doesn't match the expected values it throws a helpful error.
 * When a new value is added to the input enum, this will fail the compile check as a warning to update the switch.
 *
 * @example
 * enum PETS {
 *   DOG = 'dog',
 *   CAT = 'cat',
 *   GERBIL = 'gerbil'
 * }
 * switch (pet) {
 *   case DOG: // ...
 *   case CAT: // ...
 *   case GERBIL: // ...
 *   default:
 *     throw new UnreachableCaseError(pet);
 *   // pet is 'never' here so the compiler is happy
 * }
 * switch (pet) {
 *   case DOG: // ...
 *   case CAT: // ...
 *   default:
 *     throw new UnreachableCaseError(pet);
 *   // fails at compile time because `pet` is narrowed to 'PETS.GERBIL' which is not assignable to 'never'
 * }
 */
export class UnreachableCaseError extends Error {
  constructor(value: never) {
    super(
      `Unreachable case ${JSON.stringify(value)} should not have been reached`
    );
  }
}
